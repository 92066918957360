import ErrorContainer from '@components/error';
import FormInput from '@components/FormGroup/FormInput/FormInput';
import useJwt from '@hooks/useJwt';
import * as LoginAction from '@redux/auth/actions/auth.actions';
import * as GlobalAction from '@redux/global/actions/global.action';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styles from './index.module.scss';

const ApiKeyLanding = function ApiKeyLanding() {
  const [cookies] = useCookies();
  const { isExpired } = useJwt(cookies?.jwt);
  const { t } = useTranslation();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.global);

  useEffect(() => {
    if (!isExpired) window.location.replace('/');
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username && password) {
      dispatch(LoginAction.loginRequest({ username, password }));
    } else {
      dispatch(GlobalAction.createError(t('USER_AND_PASSWORD')));
    }
  };

  return (
    <div className={styles.container}>
      <ErrorContainer message={message} />
      <form autoComplete='on' className={styles.login}>
        <p>{t('LOGIN_TITLE')}</p>
        <FormInput
          onChange={setUsername}
          placeholder={t('LOGIN_USERNAME')}
          value={username}
        />
        <FormInput
          onChange={setPassword}
          type='password'
          placeholder={t('PASSWORD')}
          value={password}
        />
        <button
          type='submit'
          className='button is-primary'
          onClick={handleSubmit}
        >
          {t('ENTER')}
        </button>
      </form>
      <a href={`${process.env.API_PHP}resetting/check-email`}>
        {t('FORGOT_PASSWORD')}
      </a>
    </div>
  );
};

export default ApiKeyLanding;
