import * as C from './incentives.constants';

export const getIncentivesCustomRequest = (payload) => ({
  type: C.INCENTIVES_LIST_REQUEST,
  payload,
});
export const getIncentivesCustomSuccess = (payload) => ({
  type: C.INCENTIVES_LIST_SUCCESS,
  payload,
});
export const getIncentivesCustomFailure = (payload) => ({
  type: C.INCENTIVES_LIST_FAILURE,
  payload,
});
export const getIncentivesDetailRequest = (payload) => ({
  type: C.INCENTIVES_DETAIL_REQUEST,
  payload,
});
export const getIncentivesDetailSuccess = (payload) => ({
  type: C.INCENTIVES_DETAIL_SUCCESS,
  payload,
});
export const getIncentivesDetailFailure = (payload) => ({
  type: C.INCENTIVES_DETAIL_FAILURE,
  payload,
});
export const closeModalRequest = (payload) => ({
  type: C.CLOSE_MODAL,
  payload,
});
export const setUser = (payload) => ({
  type: C.SET_USER,
  payload,
});
