import Api from '../../../api';

class ReportsRepository extends Api {
  constructor() {
    super();
    this.api.defaults.baseURL += '';
  }

  getLackAndSurplus = (data) =>
    this.api.get(`/money/cash/report${this.cleanParams(data)}`);
}
export default new ReportsRepository();
