import * as C from './reports.constants';

export const initialState = {
  loading: true,
  lackAndSurplus: [],
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.GET_LACK_AND_SURPLUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case C.GET_LACK_AND_SURPLUS_SUCCESS:
      return {
        ...state,
        lackAndSurplus: payload,
        loading: false,
      };
    case C.GET_LACK_AND_SURPLUS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
