export const TASKS_REQUEST = '[TASKS] TASKS_REQUEST';
export const TASKS_SUCCESS = '[TASKS] TASKS_SUCCESS';
export const TASKS_FAILURE = '[TASKS] TASKS_FAILURE';

export const GET_TASK_REQUEST = '[TASKS] GET_TASK_REQUEST';
export const GET_TASK_SUCCESS = '[TASKS] GET_TASK_SUCCESS';
export const GET_TASK_FAILURE = '[TASKS] GET_TASK_FAILURE';

export const EDIT_TASK_REQUEST = '[TASKS] EDIT_TASK_REQUEST';
export const EDIT_TASK_SUCCESS = '[TASKS] EDIT_TASK_SUCCESS';
export const EDIT_TASK_FAILURE = '[TASKS] EDIT_TASK_FAILURE';
