import { useTranslation } from 'react-i18next';

const FormInput = function FormInput({
  onChange = () => {},
  onBlur = () => {},
  onKeyPress = () => {},
  onKeyDown = () => {},
  isExpanded = false,
  disabled = false,
  maxLength = 60,
  max = undefined,
  inputClass = '',
  name = undefined,
  min = undefined,
  id,
  placeholder = '',
  className = '',
  label = false,
  type = 'text',
  value = '',
  horizontal = false,
  inputRef,
}) {
  const { t } = useTranslation();
  return (
    <div className={`field ${horizontal ? 'is-horizontal' : ''} ${className}`}>
      {label && <label className='label'>{t(label)}</label>}
      <div className={`control ${isExpanded ? 'is-expanded' : ''}`}>
        <input
          ref={inputRef}
          className={`input ${inputClass}`}
          maxLength={maxLength}
          max={max}
          name={name}
          min={min}
          id={id}
          disabled={disabled}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onChange={(e) => onChange(e.target.value)}
          onBlur={(e) => onBlur(e.target.value)}
          placeholder={t(placeholder)}
          type={type}
          value={value}
        />
      </div>
    </div>
  );
};

export default FormInput;
