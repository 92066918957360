import { call, put, takeLatest } from 'redux-saga/effects';

import * as GlobalAction from '../../../redux/global/actions/global.action';
import ArticlesRepository from '../../articles/api/api.articles';
import ClientRepository from '../../clients/api/api.clients';
import MachinesRepository from '../api/api.machines';
import * as A from './machineList.action';
import * as C from './machineList.constants';

function* machineListEffect({ payload }) {
  try {
    const response = yield call(MachinesRepository.listMachines, payload);
    yield put(A.machineListSuccess(response));
  } catch (e) {
    yield put(A.machineListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineTypeFilterEffect({ payload }) {
  try {
    const response = yield call(
      MachinesRepository.getMachineTypesToFilter,
      payload
    );
    yield put(A.machineTypeFilterSuccess(response));
  } catch (e) {
    yield put(A.machineTypeFilterFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineClientFilterEffect({ payload }) {
  try {
    const response = yield call(ClientRepository.listClients, payload);
    yield put(A.machineClientFilterSuccess(response));
  } catch (e) {
    yield put(A.machineClientFilterFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineModelFilterEffect({ payload }) {
  try {
    const response = yield call(
      MachinesRepository.getMachineModelsToFilter,
      payload
    );
    yield put(A.machineModelFilterSuccess(response));
  } catch (e) {
    yield put(A.machineModelFilterFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineDetailEffect({ payload }) {
  try {
    const response = yield call(MachinesRepository.getMachine, payload);
    yield put(A.machineDetailsSuccess(response));
  } catch (e) {
    yield put(A.machineDetailsFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineDetailByNumberEffect({ payload }) {
  try {
    const response = yield call(
      MachinesRepository.getMachineDataByNumber,
      payload
    );
    yield put(A.getMachineByNumberSuccess(response));
  } catch (e) {
    yield put(A.getMachineByNumberFailure());
    // yield put(GlobalAction.createError(e.message));
  }
}

function* machineEditEffect({ payload }) {
  try {
    const response = yield call(MachinesRepository.editMachine, payload);
    yield put(A.machineEditSuccess(response));
    yield put(GlobalAction.removeError());
    yield put(GlobalAction.createRedirect('/machines/'));
  } catch (e) {
    yield put(A.machineEditFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineNewEffect({ payload }) {
  try {
    const response = yield call(MachinesRepository.createMachine, payload);
    yield put(A.machineNewSuccess(response));
    yield put(GlobalAction.createRedirect('/machines'));
  } catch (e) {
    yield put(A.machineNewFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineClientDetailsEffect({ payload }) {
  try {
    const response = yield call(ClientRepository.getClientById, {
      id: payload,
    });
    yield put(A.machineClientDetailsSuccess(response));
  } catch (e) {
    yield put(A.machineClientDetailsFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineArticlesListEffect({ payload }) {
  try {
    const response = yield call(
      MachinesRepository.getArticlesByMachineId,
      payload
    );
    yield put(A.machineArticlesListSuccess(response));
  } catch (e) {
    yield put(A.machineArticlesListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineArticlesRemoveEffect({ payload }) {
  try {
    yield call(
      MachinesRepository.removeArticleOnMachineBySelectionNumber,
      payload
    );
    const { selectionNumber } = payload;
    yield put(A.machineArticlesRemoveSuccess(selectionNumber));
  } catch (e) {
    yield put(A.machineArticlesRemoveFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* articlesListEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.listArticles, {
      ...payload,
      pageLimit: 10,
    });
    yield put(A.articlesListSuccess(response));
  } catch (e) {
    yield put(A.articlesListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* articlesAddEffect({ payload }) {
  try {
    const { newArticle } = payload;
    yield call(MachinesRepository.addArticleOnMachineByArticleId, payload);
    yield put(A.articlesAddSuccess(newArticle));
    yield put(GlobalAction.removeError());
  } catch (e) {
    yield put(A.articlesAddFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineBrandsListEffect({ payload }) {
  try {
    const response = yield call(MachinesRepository.filterMachineBrand, payload);
    yield put(A.machineBrandsListSuccess(response));
  } catch (e) {
    yield put(A.machineBrandsListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineBrandDataEffect({ payload }) {
  try {
    const response = yield call(MachinesRepository.getMachineBrand, payload);
    yield put(A.machineBrandDataSuccess(response));
  } catch (e) {
    yield put(A.machineBrandDataFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineBrandEditEffect({ payload }) {
  try {
    const response = yield call(MachinesRepository.editMachineBrand, payload);
    yield put(A.machineBrandEditSuccess(response));
    yield put(GlobalAction.createRedirect('/machines/brands'));
  } catch (e) {
    yield put(A.machineBrandEditFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineBrandNewEffect({ payload }) {
  try {
    const response = yield call(MachinesRepository.createMachineBrand, payload);
    yield put(A.machineBrandNewSuccess(response));
    yield put(GlobalAction.createRedirect('/machines/brands'));
  } catch (e) {
    yield put(A.machineBrandNewFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineModelsListEffect({ payload }) {
  try {
    const response = yield call(
      MachinesRepository.filterMachineModels,
      payload
    );
    yield put(A.machineModelsListSuccess(response));
  } catch (e) {
    yield put(A.machineModelsListFailure(e.message));
  }
}

function* machineModelDataEffect({ payload }) {
  try {
    const response = yield call(MachinesRepository.getMachineModel, payload);
    yield put(A.machineModelDataSuccess(response));
  } catch (e) {
    yield put(A.machineModelDataFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineModelEditEffect({ payload }) {
  try {
    const response = yield call(MachinesRepository.editMachineModel, payload);
    yield put(A.machineModelEditSuccess(response));
    yield put(GlobalAction.createRedirect('/machines/models'));
  } catch (e) {
    yield put(A.machineModelEditFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineModelNewEffect({ payload }) {
  try {
    const response = yield call(MachinesRepository.createMachineModel, payload);
    yield put(A.machineModelNewSuccess(response));
    yield put(GlobalAction.createRedirect('/machines/models'));
  } catch (e) {
    yield put(A.machineModelNewFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineReplaceEffect({ payload }) {
  try {
    const response = yield call(MachinesRepository.machineReplace, payload);
    yield put(A.machineReplaceSuccess(response));
    // yield put(GlobalAction.createRedirect('/machines'));
  } catch (e) {
    yield put(A.machineReplaceFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* machineDeleteClientEffect({ payload }) {
  try {
    yield call(MachinesRepository.machineClientRemove, payload);
    yield put(A.machineDeleteClientSuccess(payload));
  } catch (e) {
    yield put(A.machineDeleteClientFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineGetServicesEffect({ payload }) {
  try {
    const response = yield call(MachinesRepository.getMachineServices, payload);
    yield put(
      A.getMachineServicesSuccess({
        response,
        from: payload.from,
        to: payload.to,
      })
    );
  } catch (e) {
    yield put(A.getMachineServicesFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineEditScheduleEffect({ payload }) {
  try {
    yield call(MachinesRepository.editMachineRoute, payload);
    yield put(A.editMachineScheduleSuccess(payload));
  } catch (e) {
    yield put(A.editMachineScheduleFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineRemoveAllArticlesEffect({ payload }) {
  try {
    yield call(MachinesRepository.removeAllMachinesArticles, payload);
    yield put(A.machineRemoveAllArticlesSuccess());
  } catch (e) {
    yield put(A.machineRemoveAllArticlesFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineReplaceArticlesEffect({ payload }) {
  try {
    yield call(
      MachinesRepository.removeArticleOnMachineBySelectionNumber,
      payload
    );
    yield call(MachinesRepository.addArticleOnMachineByArticleId, payload);
    yield put(A.machineReplaceArticlesSuccess(payload));
  } catch (e) {
    yield put(A.machineReplaceArticlesFailure(e.message));
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineRemoveRouteEffect({ payload }) {
  try {
    yield call(MachinesRepository.removeRoute, payload);
    yield put(A.machineRemoveRouteSuccess());
  } catch (e) {
    yield put(A.machineRemoveRouteFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* machineChangeStatusEffect({ payload }) {
  try {
    yield call(MachinesRepository.machineChangeStatus, payload);
    yield put(A.machineChangeStatusSuccess(payload));
  } catch (e) {
    yield put(A.machineChangeStatusFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

export default function* machinesSagas() {
  yield takeLatest(C.MACHINE_LIST_REQUEST, machineListEffect);
  yield takeLatest(C.MACHINE_TYPE_FILTER_REQUEST, machineTypeFilterEffect);
  yield takeLatest(C.MACHINE_CLIENT_FILTER_REQUEST, machineClientFilterEffect);
  yield takeLatest(C.MACHINE_MODEL_FILTER_REQUEST, machineModelFilterEffect);
  yield takeLatest(C.MACHINE_DETAIL_REQUEST, machineDetailEffect);
  yield takeLatest(
    C.GET_MACHINE_BY_NUMBER_REQUEST,
    machineDetailByNumberEffect
  );
  yield takeLatest(C.MACHINE_NEW_REQUEST, machineNewEffect);
  yield takeLatest(C.MACHINE_EDIT_REQUEST, machineEditEffect);
  yield takeLatest(
    C.MACHINE_CLIENT_DETAILS_REQUEST,
    machineClientDetailsEffect
  );
  yield takeLatest(C.MACHINE_BRANDS_LIST_REQUEST, machineBrandsListEffect);
  yield takeLatest(C.MACHINE_BRAND_DATA_REQUEST, machineBrandDataEffect);
  yield takeLatest(C.MACHINE_BRAND_NEW_REQUEST, machineBrandNewEffect);
  yield takeLatest(C.MACHINE_BRAND_EDIT_REQUEST, machineBrandEditEffect);
  yield takeLatest(C.MACHINE_MODELS_LIST_REQUEST, machineModelsListEffect);
  yield takeLatest(C.MACHINE_MODEL_DATA_REQUEST, machineModelDataEffect);
  yield takeLatest(C.MACHINE_MODEL_NEW_REQUEST, machineModelNewEffect);
  yield takeLatest(C.MACHINE_MODEL_EDIT_REQUEST, machineModelEditEffect);
  yield takeLatest(C.MACHINE_ARTICLES_LIST_REQUEST, machineArticlesListEffect);
  yield takeLatest(
    C.MACHINE_ARTICLES_REMOVE_REQUEST,
    machineArticlesRemoveEffect
  );
  yield takeLatest(C.ARTICLES_LIST_REQUEST, articlesListEffect);
  yield takeLatest(C.ARTICLES_ADD_REQUEST, articlesAddEffect);
  yield takeLatest(C.MACHINE_REPLACE_REQUEST, machineReplaceEffect);
  yield takeLatest(C.MACHINE_DELETE_CLIENT_REQUEST, machineDeleteClientEffect);
  yield takeLatest(C.GET_MACHINE_SERVICES_REQUEST, machineGetServicesEffect);
  yield takeLatest(C.MACHINE_EDIT_SCHEDULE_REQUEST, machineEditScheduleEffect);
  yield takeLatest(
    C.MACHINE_REMOVE_ALL_ARTICLES_REQUEST,
    machineRemoveAllArticlesEffect
  );
  yield takeLatest(
    C.MACHINE_REPLACE_ARTICLES_REQUEST,
    machineReplaceArticlesEffect
  );
  yield takeLatest(C.MACHINE_REMOVE_ROUTE_REQUEST, machineRemoveRouteEffect);
  yield takeLatest(C.MACHINE_CHANGE_STATUS_REQUEST, machineChangeStatusEffect);
}
