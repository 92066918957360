export const TASKS_MODAL_REQUEST = '[TASKS] TASKS_MODAL_REQUEST';
export const TASKS_MODAL_SUCCESS = '[TASKS] TASKS_MODAL_SUCCESS';
export const TASKS_MODAL_FAILURE = '[TASKS] TASKS_MODAL_FAILURE';

export const TASKS_SHOW_MODAL = 'TASKS_OPEN_MODAL';
export const INCIDENTS_SHOW_MODAL = 'INCIDENTS_SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const INCIDENTS_MODAL_REQUEST = '[INCIDENTS] INCIDENTS_MODAL_REQUEST';
export const INCIDENTS_MODAL_SUCCESS = '[INCIDENTS] INCIDENTS_MODAL_SUCCESS';
export const INCIDENTS_MODAL_FAILURE = '[INCIDENTS] INCIDENTS_MODAL_FAILURE';
