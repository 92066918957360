import { lazy } from 'react';

const InventoryList = lazy(() =>
  import('../containers/InventoryList/InventoryList')
);
const Inventory = lazy(() => import('../containers/Inventory/Inventory'));
const OrderList = lazy(() => import('../containers/OrderList/OrderList'));
const Order = lazy(() => import('../containers/Order/Order'));
const SupplierList = lazy(() =>
  import('../containers/SupplierList/SupplierList')
);
const Supplier = lazy(() => import('../containers/Supplier/Supplier'));
const WarehouseList = lazy(() =>
  import('../containers/WarehouseList/WarehouseList')
);
const WarehouseMoveProductList = lazy(() =>
  import('../containers/WarehouseMoveProductList/WarehouseMoveProductList')
);
const WarehouseMoveProduct = lazy(() =>
  import('../containers/WarehouseMoveProduct/WarehouseMoveProduct')
);
const WarehouseMoveProductAdd = lazy(() =>
  import('../containers/WarehouseMoveProductAdd/WarehouseMoveProductAdd')
);
const Warehouse = lazy(() => import('../containers/Warehouse/Warehouse'));

export default [
  {
    path: '/warehouse/inventory',
    component: InventoryList,
  },
  {
    path: '/warehouse/inventory/new',
    component: Inventory,
  },
  {
    path: '/warehouse/inventory/:id',
    component: Inventory,
  },
  {
    path: '/warehouse/order',
    component: OrderList,
  },
  {
    path: '/warehouse/order/new',
    component: Order,
  },
  {
    path: '/warehouse/order/:id',
    component: Order,
  },
  {
    path: '/warehouse/supplier',
    component: SupplierList,
  },
  {
    path: '/warehouse/supplier/new',
    component: Supplier,
  },
  {
    path: '/warehouse/supplier/:id',
    component: Supplier,
  },
  {
    path: '/warehouse',
    component: WarehouseList,
  },
  {
    path: '/warehouse/pending_moves',
    component: WarehouseMoveProductList,
  },
  {
    path: '/warehouse/move-products/:id',
    component: WarehouseMoveProduct,
  },
  {
    path: '/warehouse/move-products-add',
    component: WarehouseMoveProductAdd,
  },
  {
    path: '/warehouse/new',
    component: Warehouse,
  },
  {
    path: '/warehouse/:id',
    component: Warehouse,
  },
];
