import * as C from '../constants/auth.constants';

export const initialState = {
  roles: [],
  userId: null,
  username: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.LOGIN_SUCCESS: {
      if (window.location.origin.includes('localhost')) {
        const token = payload.split('.')[1];
        const base64 = token.replace('-', '+').replace('_', '/');
        const decoded = JSON.parse(atob(base64));
        const expires = `expires=${new Date(decoded.exp * 1000).toUTCString()}`;
        document.cookie = `jwt=${payload}; ${expires};domain=localhost; path=/`;
      }
      document.location.replace('/');

      return {
        ...state,
      };
    }
    case C.SET_USER_DATA:
      return {
        ...state,
        roles: payload.roles,
        username: payload.username,
        userId: payload.user_id,
      };
    default:
      return state;
  }
};
