/* eslint-disable no-case-declarations */
import cloneDeep from 'lodash/cloneDeep';

import {
  getTemplateVisits,
  getVisits,
  mockAvailabletypes,
} from '../helpers/schedules';
import * as H from '../helpers/schedules/routesHelpers';
import * as C from './routesList.constants';

export const initialState = {
  today: [],
  filterTotalItems: 0,
  filterMessage: '',
  quantityOfArticles: [],
  route: {},
  routes: [],
  routesStats: [],
  workerStats: [],
  schedules: [],
  schedulesModal: [],
  schedule: {},
  scheduleStatsTable: [],
  templates: [],
  routesList: undefined,
  routeId: undefined,
  loading: true,
  loadingModal: true,
  articleMachineModal: [],
  machineDetails: {},
  machineTypes: [],
  currentPage: 1,
  scheduleName: undefined,
  scheduleNumber: undefined,
  pageLimit: 20,
  totalItems: 0,
  visitTypes: {},
  number: undefined,
  name: undefined,
  reload: 0,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.ROUTES_FINISH_REQUEST:
    case C.ROUTES_SCHEDULES_PDF_REQUEST:
    case C.ROUTE_DETAIL_REQUEST:
    case C.ROUTES_REQUEST:
    case C.ROUTES_LIST_REQUEST:
    case C.ROUTES_SCHEDULES_LIST_REQUEST:
    case C.ROUTES_SCHEDULES_AVAILABLE_TYPES_REQUEST:
    case C.ROUTES_STATS_REQUEST:
    case C.MACHINE_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case C.SCHEDULE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        schedule: {},
      };
    case C.ROUTES_SCHEDULES_MODAL_REQUEST:
    case C.ROUTES_SCHEDULES_MACHINE_DETAILS_MODAL_REQUEST:
      return {
        ...state,
        loadingModal: true,
      };
    case C.ROUTES_SCHEDULES_TEMPLATES_LIST_REQUEST:
    case C.ROUTES_SCHEDULES_LIST_SELECTABLE_REQUEST:
    case C.ROUTES_SCHEDULES_TEMPLATES_REMOVE_REQUEST:
    case C.ROUTES_SCHEDULES_TEMPLATES_EDIT_REQUEST:
    case C.ROUTES_SCHEDULES_EDIT_REQUEST:
      return {
        ...state,
      };
    case C.ROUTES_SCHEDULES_STATS_TABLE:
      return {
        ...state,
        scheduleStatsTable: payload,
      };
    case C.ROUTES_STATS_SUCCESS:
      const newRoutes = H.formatStatsRoute(payload.results);
      const newWorkerRoutes = H.formatStatsWorker(
        payload.results,
        state.machineTypes
      );
      return {
        ...state,
        routesStats: newRoutes,
        workerStats: newWorkerRoutes,
        totalItems: payload.totalItems,
        loading: false,
      };
    case C.ROUTES_SCHEDULES_LIST_SUCCESS:
    case C.ROUTES_SCHEDULES_LIST_SELECTABLE_SUCCESS:
      let newVisitTypes;
      if (state.visitTypes.length === 0) {
        newVisitTypes = mockAvailabletypes;
      } else {
        newVisitTypes = state.visitTypes;
      }
      const newSchedules = [...payload.results];
      try {
        payload.results?.forEach((schedule, scheduleIndex) => {
          schedule.machineVisits.forEach((machine, machineIndex) => {
            const formatedVisit = getVisits(machine, payload.weeks);
            newSchedules[scheduleIndex].machineVisits[machineIndex] =
              formatedVisit;
          });
        });
      } catch (e) {
        throw new Error(e);
      }
      const orderedSchedules = newSchedules.sort((a, b) => a.number - b.number);
      return {
        ...state,
        visitTypes: newVisitTypes,
        pageNumber: payload.currentPage,
        totalItems: payload.totalItems,
        schedules: orderedSchedules,
        scheduleStatsTable: [],
        loading: false,
      };
    case C.ROUTES_SCHEDULES_TEMPLATES_REMOVE_SUCCESS:
      const removeTemplate = state.templates.filter(
        (template) => template.number !== payload.number
      );
      return {
        ...state,
        templates: removeTemplate,
        loading: false,
      };
    case C.ROUTES_SCHEDULES_MODAL_SUCCESS:
      const sortByNumber = (a, b) => {
        if (a.number < b.number) {
          return -1;
        }
        if (a.number > b.number) {
          return 1;
        }
        return 0;
      };
      const schedulesModal = payload.results
        .filter((schedule) => schedule.id !== payload.scheduleId)
        .map((schedule) => ({
          id: schedule.id,
          name: schedule.name,
          number: schedule.number,
        }));
      return {
        ...state,
        schedulesModal: schedulesModal.sort(sortByNumber),
        loadingModal: false,
      };
    case C.ROUTES_SCHEDULES_MACHINE_DETAILS_MODAL_SUCCESS:
      return {
        ...state,
        machineDetails: payload.machineDetails,
        loadingModal: false,
      };
    case C.MACHINE_TYPES_SUCCESS:
      return {
        ...state,
        machineTypes: payload.map((machineType) => machineType.text),
      };
    case C.ROUTES_SCHEDULES_TEMPLATES_LIST_SUCCESS:
      const newTemplates = cloneDeep(payload);
      try {
        payload.forEach((template, templateIndex) => {
          const formatedVisit = getTemplateVisits(template, 4);
          newTemplates[templateIndex].value = newTemplates[templateIndex].name;
          newTemplates[templateIndex].text = newTemplates[templateIndex].name;
          newTemplates[templateIndex].visits = formatedVisit;
        });
      } catch (e) {
        throw new Error(e);
      }
      return {
        ...state,
        templates: newTemplates,
        loading: false,
      };
    case C.ROUTES_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case C.SCHEDULE_DETAIL_SUCCESS:
      payload.machineVisits.sort((a, b) => {
        if (a.clientName > b.clientName) {
          return 1;
        }
        if (b.clientName > a.clientName) {
          return -1;
        }
        if (b.clientName === a.clientName) {
          if (a.location > b.location) {
            return 1;
          }
        }
        return -1;
      });
      const machineVisits = payload.machineVisits.map((machine) =>
        getVisits(machine)
      );
      return {
        ...state,
        schedule: { ...payload, machineVisits },
        loading: false,
      };
    case C.ROUTES_SCHEDULES_AVAILABLE_TYPES_SUCCESS: {
      const visitTypes = {};
      // eslint-disable-next-line no-shadow
      payload.forEach(({ type, minutesPerVisit }) => {
        visitTypes[type] = minutesPerVisit;
      });
      return {
        ...state,
        visitTypes,
      };
    }
    case C.ROUTES_SCHEDULE_NAME_FILTER:
      return {
        ...state,
        scheduleName: payload,
        loading: false,
      };
    case C.ROUTES_SCHEDULE_NUMBER_FILTER:
      return {
        ...state,
        scheduleNumber: payload,
        loading: false,
      };
    case C.ROUTES_SCHEDULES_MOVE_MACHINE_SUCCESS:
      const schedule = {
        ...state.schedule,
        machineVisits: state.schedule.machineVisits.filter(({ machineId }) =>
          payload.machineIds.every(
            (selectedMachineId) => selectedMachineId !== machineId
          )
        ),
      };
      return {
        ...state,
        schedule,
      };
    case C.ROUTE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        route: payload,
      };
    case C.ROUTES_SUCCESS:
      const flatRoutes = payload.results.map((route) => ({
        ...route,
        machineVisits: route.machineVisits.map((machine) => {
          if (machine.visits) {
            return H.arrayToObject(machine.visits, 'day', 'type');
          }
          return machine;
        }),
      }));
      return {
        ...state,
        loading: false,
        pageNumber: payload.currentPage,
        totalItems: payload.totalItems,
        routes: flatRoutes,
      };
    case C.ROUTES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pageNumber: payload.currentPage,
        totalItems: payload.totalItems,
        routes: H.formatRouteToToday(payload.results),
      };
    case C.GET_TODAY_ROUTES_SUCCESS:
      return {
        ...state,
        today: H.formatRouteToToday(payload),
        routes: payload,
        loading: false,
      };
    case C.ROUTES_SCHEDULES_PDF_SUCCESS:
      return {
        ...state,
      };
    case C.ROUTES_FINISH_SUCCESS:
      const reload = state.reload + 1;
      return {
        ...state,
        reload,
      };
    case C.MACHINE_TYPES_FAILURE:
    case C.ROUTES_FINISH_FAILURE:
    case C.ROUTES_SCHEDULES_PDF_FAILURE:
    case C.GET_TODAY_ROUTES_FAILURE:
    case C.ROUTE_DETAIL_FAILURE:
    case C.ROUTES_SCHEDULES_LIST_FAILURE:
    case C.ROUTES_SCHEDULES_AVAILABLE_TYPES_FAILURE:
    case C.ROUTES_SCHEDULES_TEMPLATES_EDIT_FAILURE:
    case C.ROUTES_SCHEDULES_EDIT_FAILURE:
    case C.ROUTES_SCHEDULES_TEMPLATES_LIST_FAILURE:
    case C.ROUTES_SCHEDULES_TEMPLATES_REMOVE_FAILURE:
    case C.ROUTES_SCHEDULES_MODAL_FAILURE:
    case C.ROUTES_SCHEDULES_MOVE_MACHINE_FAILURE:
    case C.ROUTES_SCHEDULES_MACHINE_DETAILS_MODAL_FAILURE:
    case C.ROUTES_STATS_FAILURE:
    case C.ROUTES_SCHEDULES_LIST_SELECTABLE_FAILURE:
    case C.SCHEDULE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        loadingModal: false,
      };
    default:
      return state;
  }
};
