import ArticlesRepository from '@articles/api/api.articles';
import { push } from 'connected-react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as GlobalAction from '../global/actions/global.action';
import * as A from './articles.action';
import * as C from './articles.constants';

function* articleListEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.listArticles, payload);
    yield put(A.articleListSuccess(response));
  } catch (e) {
    yield put(A.articleListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* articleEditEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.editArticle, payload);
    yield put(A.articleEditSuccess(response));
    yield put(push('/articles'));
  } catch (e) {
    yield put(A.articleEditFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* articleDetailEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.getArticle, payload);
    yield put(A.articleDetailsSuccess(response));
  } catch (e) {
    yield put(A.articleDetailsFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* articleNewEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.createArticle, payload);
    yield put(A.articleNewSuccess(response));
    yield put(push('/articles'));
  } catch (e) {
    yield put(A.articleNewFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* articleFilterEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.listArticles, payload);
    yield put(A.articleFilterNameSuccess(response));
  } catch (e) {
    yield put(A.articleFilterNameFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* articlePriceListEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.listPrices, payload);
    yield put(A.articlePriceListSuccess(response));
  } catch (e) {
    yield put(A.articlePriceListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* articlePriceDeleteEffect({ payload }) {
  try {
    yield call(ArticlesRepository.deletePrice, payload);
    yield put(A.articlePriceDeleteSuccess());
    yield put(push('/articles/prices'));
  } catch (e) {
    yield put(A.articlePriceDeleteSuccess());
    yield put(GlobalAction.createError(e.message));
  }
}

function* articlePriceCreateEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.createPrice, payload);
    yield put(A.articlePriceCreateSuccess(response));
    yield put(push(`/articles/prices/${response.id}`));
  } catch (e) {
    yield put(A.articlePriceCreateFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* articlePriceEditEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.editPrice, payload);
    yield put(A.articlePriceEditSuccess(response));
  } catch (e) {
    yield put(A.articlePriceEditFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* articlePriceGetEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.getPrice, payload);
    yield put(A.articlePriceGetSuccess(response));
  } catch (e) {
    yield put(A.articlePriceGetFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* articleMachinePriceCreateEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.createMachinePrice, payload);
    yield put(
      A.articleMachinePriceCreateSuccess({
        response,
        removed: payload.articleId,
      })
    );
  } catch (e) {
    yield put(A.articleMachinePriceCreateFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* articleMachinePriceEditEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.editMachinePrice, payload);
    yield put(A.articleMachinePriceEditSuccess(response));
  } catch (e) {
    yield put(A.articleMachinePriceEditFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* articleMachinePriceDeleteEffect({ payload }) {
  try {
    yield call(ArticlesRepository.deleteMachinePrice, payload);
    yield put(A.articleMachinePriceDeleteSuccess(payload));
  } catch (e) {
    yield put(A.articleMachinePriceDeleteFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* articleMachinePriceGetEffect({ payload }) {
  try {
    const [articles, prices, articlesPrices] = yield all([
      call(ArticlesRepository.listArticles, {
        pageNumber: 1,
        pageLimit: 99999,
      }),
      call(ArticlesRepository.listPrices),
      call(ArticlesRepository.getMachinePrice, payload),
    ]);
    yield put(
      A.articleMachinePriceGetSuccess({
        articles: articles.results,
        prices,
        articlesPrices,
      })
    );
  } catch (e) {
    yield put(A.articleMachinePriceGetFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* articleClientPriceCreateEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.createClientPrice, payload);
    yield put(
      A.articleClientPriceCreateSuccess({
        response,
        removed: payload.articleId,
      })
    );
  } catch (e) {
    yield put(A.articleClientPriceCreateFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* articleClientPriceEditEffect({ payload }) {
  try {
    const response = yield call(ArticlesRepository.editClientPrice, payload);
    yield put(A.articleClientPriceEditSuccess(response));
  } catch (e) {
    yield put(A.articleClientPriceEditFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* articleClientPriceGetEffect({ payload }) {
  try {
    const [articles, prices, articlesPrices] = yield all([
      call(ArticlesRepository.listArticles, {
        pageNumber: 1,
        pageLimit: 99999,
      }),
      call(ArticlesRepository.listPrices),
      call(ArticlesRepository.getClientPrice, payload),
    ]);
    yield put(
      A.articleClientPriceGetSuccess({
        articles: articles.results,
        prices,
        articlesPrices,
      })
    );
  } catch (e) {
    yield put(A.articleClientPriceGetFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* articleClientPriceDeleteEffect({ payload }) {
  try {
    yield call(ArticlesRepository.deleteClientPrice, payload);
    yield put(A.articleClientPriceDeleteSuccess(payload));
  } catch (e) {
    yield put(A.articleClientPriceDeleteFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

export default function* articlesSaga() {
  yield takeLatest(C.ARTICLE_LIST_REQUEST, articleListEffect);
  yield takeLatest(C.ARTICLE_DETAIL_REQUEST, articleDetailEffect);
  yield takeLatest(C.ARTICLE_EDIT_REQUEST, articleEditEffect);
  yield takeLatest(C.ARTICLE_NEW_REQUEST, articleNewEffect);
  yield takeLatest(C.ARTICLE_FILTER_NAME_REQUEST, articleFilterEffect);
  yield takeLatest(C.ARTICLE_PRICE_LIST_REQUEST, articlePriceListEffect);
  yield takeLatest(C.ARTICLE_PRICE_DELETE_REQUEST, articlePriceDeleteEffect);
  yield takeLatest(C.ARTICLE_PRICE_CREATE_REQUEST, articlePriceCreateEffect);
  yield takeLatest(C.ARTICLE_PRICE_EDIT_REQUEST, articlePriceEditEffect);
  yield takeLatest(C.ARTICLE_PRICE_GET_REQUEST, articlePriceGetEffect);
  yield takeLatest(
    C.ARTICLE_MACHINE_PRICE_CREATE_REQUEST,
    articleMachinePriceCreateEffect
  );
  yield takeLatest(
    C.ARTICLE_MACHINE_PRICE_EDIT_REQUEST,
    articleMachinePriceEditEffect
  );
  yield takeLatest(
    C.ARTICLE_MACHINE_PRICE_DELETE_REQUEST,
    articleMachinePriceDeleteEffect
  );
  yield takeLatest(
    C.ARTICLE_MACHINE_PRICE_GET_REQUEST,
    articleMachinePriceGetEffect
  );
  yield takeLatest(
    C.ARTICLE_CLIENT_PRICE_CREATE_REQUEST,
    articleClientPriceCreateEffect
  );
  yield takeLatest(
    C.ARTICLE_CLIENT_PRICE_EDIT_REQUEST,
    articleClientPriceEditEffect
  );
  yield takeLatest(
    C.ARTICLE_CLIENT_PRICE_GET_REQUEST,
    articleClientPriceGetEffect
  );
  yield takeLatest(
    C.ARTICLE_CLIENT_PRICE_DELETE_REQUEST,
    articleClientPriceDeleteEffect
  );
}
