import { lazy } from 'react';

const lackAndSurplusLanding = lazy(() =>
  import('../containers/LackAndSurplusLanding/LackAndSurplusLanding')
);
const Tpv = lazy(() => import('../containers/Tpv/Tpv'));

export default [
  {
    path: '/reports/lackAndSurplus',
    component: lackAndSurplusLanding,
  },
  {
    path: '/reports/tpv',
    component: Tpv,
  },
];
