const Utils = {
  doDelete(val) {
    return !val || Utils.isEmptyObj(val) || Utils.isEmptyArray(val);
  },
  isEmptyArray(val) {
    return Array.isArray(val) && val.length === 0;
  },
  isEmptyObj(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  },
  hasKeys(obj) {
    return Object.keys(obj).length > 0;
  },
  clean(object) {
    Object.keys(object).forEach((key) => {
      const val = object[key];
      if (val && typeof val === 'object') {
        Utils.clean(val);
      }
      if (Utils.doDelete(val)) {
        delete object[key];
        return object;
      }
      if (Array.isArray(val)) {
        let i = val.length;
        // eslint-disable-next-line no-plusplus
        while (i--) {
          let entry = val[i];
          if (Utils.doDelete(entry)) {
            val.splice(i, 1);
          } else if (Utils.hasKeys(entry)) {
            entry = Utils.clean(entry);
            if (Utils.doDelete(entry)) {
              val.splice(i, 1);
            }
          }
        }
        if (Utils.doDelete(val)) {
          delete object[key];
        }
      }
    });
    return object;
  },
  formatSelectable(data) {
    let response;
    if (data.length > 0) {
      if (data[0]?.name) {
        response = data.map((element) => {
          return {
            text: `${element?.name || ''} (${element?.number || ''})`,
            value: element?.id,
          };
        });
      } else {
        response = data.map((element) => {
          return {
            text: `${element?.number || ''} (${element?.client?.name || ''})`,
            value: element?.id,
          };
        });
      }
    }
    return response;
  },
  formatSelectableRecurrentActions(data) {
    let response;
    if (data.length > 0) {
      response = data.map((element) => {
        return {
          text: `(${element?.number}) ${element?.name || ''}`,
          value: { id: element?.id, triggerId: element?.trigger?.id },
        };
      });
    }
    return response;
  },
  formatSelectableDefault(data) {
    return data.map((element) => ({
      text: element?.name,
      value: element?.id,
    }));
  },
  formatSelectableNumber(data) {
    let response;
    if (data.length > 0) {
      response = data.map((element) => {
        return {
          text: String(element?.number),
          value: element?.id,
        };
      });
    }
    return response;
  },
  formatSelectableUsers(data) {
    let response;
    if (data.length > 0) {
      response = data.map((element) => {
        return {
          text: element?.text,
          value: element?.id,
        };
      });
    }
    return response;
  },
};

export default Utils;
