import classNames from 'classnames';

import { MenuList } from './menu-list';

const BASE_CLASS = 'vm-Menu';

const Menu = function Menu({ handleToggleMenu = () => {}, isOpen }) {
  const MENU_CLASS = classNames(BASE_CLASS, {
    'vm-Menu--isOpen': isOpen,
  });

  return (
    <div className={`${MENU_CLASS}`}>
      <MenuList handleToggleMenu={handleToggleMenu} />
    </div>
  );
};

export default Menu;
