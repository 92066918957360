export const RESET = '[MACHINELIST] RESET';

export const MACHINE_LIST_REQUEST = '[MACHINELIST] MACHINE_LIST_REQUEST';
export const MACHINE_LIST_SUCCESS = '[MACHINELIST] MACHINE_LIST_SUCCESS';
export const MACHINE_LIST_FAILURE = '[MACHINELIST] MACHINE_LIST_FAILURE';

export const GET_MACHINE_BY_NUMBER_REQUEST =
  '[MACHINELIST] GET_MACHINE_BY_NUMBER_REQUEST';
export const GET_MACHINE_BY_NUMBER_SUCCESS =
  '[MACHINELIST] GET_MACHINE_BY_NUMBER_SUCCESS';
export const GET_MACHINE_BY_NUMBER_FAILURE =
  '[MACHINELIST] GET_MACHINE_BY_NUMBER_FAILURE';

export const MACHINE_NUMBER_FILTER = '[MACHINELIST] MACHINE_NUMBER_FILTER';
export const MACHINE_TYPE_FILTER = '[MACHINELIST] MACHINE_TYPE_FILTER';
export const MACHINE_CLIENT_FILTER = '[MACHINELIST] MACHINE_CLIENT_FILTER';
export const MACHINE_MODEL_FILTER = '[MACHINELIST] MACHINE_MODEL_FILTER';

export const MACHINE_MODEL_FILTER_REQUEST =
  '[MACHINELIST] MACHINE_MODEL_FILTER_REQUEST';
export const MACHINE_MODEL_FILTER_SUCCESS =
  '[MACHINELIST] MACHINE_MODEL_FILTER_SUCCESS';
export const MACHINE_MODEL_FILTER_FAILURE =
  '[MACHINELIST] MACHINE_MODEL_FILTER_FAILURE';

export const MACHINE_TYPE_FILTER_REQUEST =
  '[MACHINELIST] MACHINE_TYPE_FILTER_REQUEST';
export const MACHINE_TYPE_FILTER_SUCCESS =
  '[MACHINELIST] MACHINE_TYPE_FILTER_SUCCESS';
export const MACHINE_TYPE_FILTER_FAILURE =
  '[MACHINELIST] MACHINE_TYPE_FILTER_FAILURE';

export const MACHINE_CLIENT_FILTER_REQUEST =
  '[MACHINELIST] MACHINE_CLIENT_FILTER_REQUEST';
export const MACHINE_CLIENT_FILTER_SUCCESS =
  '[MACHINELIST] MACHINE_CLIENT_FILTER_SUCCESS';
export const MACHINE_CLIENT_FILTER_FAILURE =
  '[MACHINELIST] MACHINE_CLIENT_FILTER_FAILURE';

export const MACHINE_DETAIL_REQUEST = '[MACHINEDETAILS] MACHINE_DETAIL_REQUEST';
export const MACHINE_DETAIL_SUCCESS = '[MACHINEDETAILS] MACHINE_DETAIL_SUCCESS';
export const MACHINE_DETAIL_FAILURE = '[MACHINEDETAILS] MACHINE_DETAIL_FAILURE';

export const MACHINE_EDIT_REQUEST = '[MACHINEDETAILS] MACHINE_EDIT_REQUEST';
export const MACHINE_EDIT_SUCCESS = '[MACHINEDETAILS] MACHINE_EDIT_SUCCESS';
export const MACHINE_EDIT_FAILURE = '[MACHINEDETAILS] MACHINE_EDIT_FAILURE';

export const MACHINE_NEW_REQUEST = '[MACHINEDETAILS] MACHINE_NEW_REQUEST';
export const MACHINE_NEW_SUCCESS = '[MACHINEDETAILS] MACHINE_NEW_SUCCESS';
export const MACHINE_NEW_FAILURE = '[MACHINEDETAILS] MACHINE_NEW_FAILURE';

export const MACHINE_CLIENT_DETAILS_REQUEST =
  '[MACHINEDETAILS] MACHINE_CLIENT_DETAILS_REQUEST';
export const MACHINE_CLIENT_DETAILS_SUCCESS =
  '[MACHINEDETAILS] MACHINE_CLIENT_DETAILS_SUCCESS';
export const MACHINE_CLIENT_DETAILS_FAILURE =
  '[MACHINEDETAILS] MACHINE_CLIENT_DETAILS_FAILURE';

export const MACHINE_ARTICLES_LIST_REQUEST =
  '[MACHINEDETAILS] MACHINE_ARTICLES_LIST_REQUEST';
export const MACHINE_ARTICLES_LIST_SUCCESS =
  '[MACHINEDETAILS] MACHINE_ARTICLES_LIST_SUCCESS';
export const MACHINE_ARTICLES_LIST_FAILURE =
  '[MACHINEDETAILS] MACHINE_ARTICLES_LIST_FAILURE';

export const MACHINE_ARTICLES_REMOVE_REQUEST =
  '[MACHINEDETAILS] MACHINE_ARTICLES_REMOVE_REQUEST';
export const MACHINE_ARTICLES_REMOVE_SUCCESS =
  '[MACHINEDETAILS] MACHINE_ARTICLES_REMOVE_SUCCESS';
export const MACHINE_ARTICLES_REMOVE_FAILURE =
  '[MACHINEDETAILS] MACHINE_ARTICLES_REMOVE_FAILURE';

export const MACHINE_ARTICLES_FILTER =
  '[MACHINEDETAILS] MACHINE_ARTICLES_FILTER';

export const ARTICLES_LIST_REQUEST = '[MACHINEDETAILS] ARTICLES_LIST_REQUEST';
export const ARTICLES_LIST_SUCCESS = '[MACHINEDETAILS] ARTICLES_LIST_SUCCESS';
export const ARTICLES_LIST_FAILURE = '[MACHINEDETAILS] ARTICLES_LIST_FAILURE';

export const ARTICLES_ADD_REQUEST = '[MACHINEDETAILS] ARTICLES_ADD_REQUEST';
export const ARTICLES_ADD_SUCCESS = '[MACHINEDETAILS] ARTICLES_ADD_SUCCESS';
export const ARTICLES_ADD_FAILURE = '[MACHINEDETAILS] ARTICLES_ADD_FAILURE';

export const MACHINE_BRANDS_LIST_REQUEST =
  '[MACHINEDETAILS] MACHINE_BRANDS_LIST_REQUEST';
export const MACHINE_BRANDS_LIST_SUCCESS =
  '[MACHINEDETAILS] MACHINE_BRANDS_LIST_SUCCESS';
export const MACHINE_BRANDS_LIST_FAILURE =
  '[MACHINEDETAILS] MACHINE_BRANDS_LIST_FAILURE';

export const MACHINE_BRAND_EDIT_REQUEST =
  '[MACHINEDETAILS] MACHINE_BRAND_EDIT_REQUEST';
export const MACHINE_BRAND_EDIT_SUCCESS =
  '[MACHINEDETAILS] MACHINE_BRAND_EDIT_SUCCESS';
export const MACHINE_BRAND_EDIT_FAILURE =
  '[MACHINEDETAILS] MACHINE_BRAND_EDIT_FAILURE';

export const MACHINE_BRAND_NEW_REQUEST =
  '[MACHINEDETAILS] MACHINE_BRAND_NEW_REQUEST';
export const MACHINE_BRAND_NEW_SUCCESS =
  '[MACHINEDETAILS] MACHINE_BRAND_NEW_SUCCESS';
export const MACHINE_BRAND_NEW_FAILURE =
  '[MACHINEDETAILS] MACHINE_BRAND_NEW_FAILURE';

export const MACHINE_MODELS_LIST_REQUEST =
  '[MACHINEDETAILS] MACHINE_MODELS_LIST_REQUEST';
export const MACHINE_MODELS_LIST_SUCCESS =
  '[MACHINEDETAILS] MACHINE_MODELS_LIST_SUCCESS';
export const MACHINE_MODELS_LIST_FAILURE =
  '[MACHINEDETAILS] MACHINE_MODELS_LIST_FAILURE';

export const MACHINE_MODEL_EDIT_REQUEST =
  '[MACHINEDETAILS] MACHINE_MODEL_EDIT_REQUEST';
export const MACHINE_MODEL_EDIT_SUCCESS =
  '[MACHINEDETAILS] MACHINE_MODEL_EDIT_SUCCESS';
export const MACHINE_MODEL_EDIT_FAILURE =
  '[MACHINEDETAILS] MACHINE_MODEL_EDIT_FAILURE';

export const MACHINE_MODEL_NEW_REQUEST =
  '[MACHINEDETAILS] MACHINE_MODEL_NEW_REQUEST';
export const MACHINE_MODEL_NEW_SUCCESS =
  '[MACHINEDETAILS] MACHINE_MODEL_NEW_SUCCESS';
export const MACHINE_MODEL_NEW_FAILURE =
  '[MACHINEDETAILS] MACHINE_MODEL_NEW_FAILURE';

export const MACHINE_BRAND_DATA_REQUEST =
  '[MACHINEDETAILS] MACHINE_BRAND_DATA_REQUEST';
export const MACHINE_BRAND_DATA_SUCCESS =
  '[MACHINEDETAILS] MACHINE_BRAND_DATA_SUCCESS';
export const MACHINE_BRAND_DATA_FAILURE =
  '[MACHINEDETAILS] MACHINE_BRAND_DATA_FAILURE';

export const MACHINE_MODEL_DATA_REQUEST =
  '[MACHINEDETAILS] MACHINE_MODEL_DATA_REQUEST';
export const MACHINE_MODEL_DATA_SUCCESS =
  '[MACHINEDETAILS] MACHINE_MODEL_DATA_SUCCESS';
export const MACHINE_MODEL_DATA_FAILURE =
  '[MACHINEDETAILS] MACHINE_MODEL_DATA_FAILURE';

export const MACHINE_REPLACE_REQUEST =
  '[MACHINEDETAILS] MACHINE_REPLACE_REQUEST';
export const MACHINE_REPLACE_SUCCESS =
  '[MACHINEDETAILS] MACHINE_REPLACE_SUCCESS';
export const MACHINE_REPLACE_FAILURE =
  '[MACHINEDETAILS] MACHINE_REPLACE_FAILURE';

export const MACHINE_DELETE_CLIENT_REQUEST =
  '[MACHINEDETAILS] MACHINE_DELETE_CLIENT_REQUEST';
export const MACHINE_DELETE_CLIENT_SUCCESS =
  '[MACHINEDETAILS] MACHINE_DELETE_CLIENT_SUCCESS';
export const MACHINE_DELETE_CLIENT_FAILURE =
  '[MACHINEDETAILS] MACHINE_DELETE_CLIENT_FAILURE';

export const MACHINE_LOCAL_FILTER_REQUEST =
  '[MACHINEDETAILS] MACHINE_LOCAL_FILTER_REQUEST';
export const MACHINE_LOCAL_FILTER_SUCCESS =
  '[MACHINEDETAILS] MACHINE_LOCAL_FILTER_SUCCESS';
export const MACHINE_LOCAL_FILTER_FAILURE =
  '[MACHINEDETAILS] MACHINE_LOCAL_FILTER_FAILURE';

export const MACHINE_TO_REPLACE_FILTER_REQUEST =
  '[MACHINEDETAILS] MACHINE_TO_REPLACE_FILTER_REQUEST';
export const MACHINE_TO_REPLACE_FILTER_SUCCESS =
  '[MACHINEDETAILS] MACHINE_TO_REPLACE_FILTER_SUCCESS';
export const MACHINE_TO_REPLACE_FILTER_FAILURE =
  '[MACHINEDETAILS] MACHINE_TO_REPLACE_FILTER_FAILURE';

export const GET_MACHINE_SERVICES_REQUEST =
  '[MACHINEDETAILS] MACHINE_GET_SERVICES_REQUEST';
export const GET_MACHINE_SERVICES_SUCCESS =
  '[MACHINEDETAILS] MACHINE_GET_SERVICES_SUCCESS';
export const GET_MACHINE_SERVICES_FAILURE =
  '[MACHINEDETAILS] MACHINE_GET_SERVICES_FAILURE';

export const MACHINE_EDIT_SCHEDULE_REQUEST =
  '[MACHINEDETAILS] MACHINE_EDIT_SCHEDULE_REQUEST';
export const MACHINE_EDIT_SCHEDULE_SUCCESS =
  '[MACHINEDETAILS] MACHINE_EDIT_SCHEDULE_SUCCESS';
export const MACHINE_EDIT_SCHEDULE_FAILURE =
  '[MACHINEDETAILS] MACHINE_EDIT_SCHEDULE_FAILURE';

export const MACHINE_REMOVE_ALL_ARTICLES_REQUEST =
  '[MACHINEDETAILS] MACHINE_REMOVE_ALL_ARTICLES_REQUEST';
export const MACHINE_REMOVE_ALL_ARTICLES_SUCCESS =
  '[MACHINEDETAILS] MACHINE_REMOVE_ALL_ARTICLES_SUCCESS';
export const MACHINE_REMOVE_ALL_ARTICLES_FAILURE =
  '[MACHINEDETAILS] MACHINE_REMOVE_ALL_ARTICLES_FAILURE';

export const MACHINE_REPLACE_ARTICLES_REQUEST =
  '[MACHINEDETAILS] MACHINE_REPLACE_ARTICLES_REQUEST';
export const MACHINE_REPLACE_ARTICLES_SUCCESS =
  '[MACHINEDETAILS] MACHINE_REPLACE_ARTICLES_SUCCESS';
export const MACHINE_REPLACE_ARTICLES_FAILURE =
  '[MACHINEDETAILS] MACHINE_REPLACE_ARTICLES_FAILURE';

export const MACHINE_REMOVE_ROUTE_REQUEST =
  '[MACHINEDETAILS] MACHINE_REMOVE_ROUTE_REQUEST';
export const MACHINE_REMOVE_ROUTE_SUCCESS =
  '[MACHINEDETAILS] MACHINE_REMOVE_ROUTE_SUCCESS';
export const MACHINE_REMOVE_ROUTE_FAILURE =
  '[MACHINEDETAILS] MACHINE_REMOVE_ROUTE_FAILURE';

export const MACHINE_CHANGE_STATUS_REQUEST =
  '[MACHINEDETAILS] MACHINE_CHANGE_STATUS_REQUEST';
export const MACHINE_CHANGE_STATUS_SUCCESS =
  '[MACHINEDETAILS] MACHINE_CHANGE_STATUS_SUCCESS';
export const MACHINE_CHANGE_STATUS_FAILURE =
  '[MACHINEDETAILS] MACHINE_CHANGE_STATUS_FAILURE';
