import * as C from './articles.constants';

export const initialState = {
  articles: [],
  article: {},
  prices: [],
  price: {},
  articlesPrices: [],
  missingArticles: [],
  articlesPrice: {},
  pageLimit: 20,
  currentPage: 1,
  totalItems: 0,
  number: undefined,
  name: undefined,
  loading: true,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.ARTICLE_PRICE_LIST_REQUEST:
    case C.ARTICLE_PRICE_DELETE_REQUEST:
    case C.ARTICLE_PRICE_CREATE_REQUEST:
    case C.ARTICLE_PRICE_EDIT_REQUEST:
    case C.ARTICLE_PRICE_GET_REQUEST:
    case C.ARTICLE_MACHINE_PRICE_CREATE_REQUEST:
    case C.ARTICLE_MACHINE_PRICE_EDIT_REQUEST:
    case C.ARTICLE_MACHINE_PRICE_GET_REQUEST:
    case C.ARTICLE_CLIENT_PRICE_CREATE_REQUEST:
    case C.ARTICLE_CLIENT_PRICE_EDIT_REQUEST:
    case C.ARTICLE_CLIENT_PRICE_GET_REQUEST:
    case C.ARTICLE_CLIENT_PRICE_DELETE_REQUEST:
    case C.ARTICLE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case C.ARTICLE_NUMBER_FILTER:
      return {
        ...state,
        number: payload.number,
      };
    case C.ARTICLE_UPDATE_PAGE_REQUEST:
      return {
        ...state,
        currentPage: payload.currentPage,
      };
    case C.ARTICLE_NAME_FILTER:
      return {
        ...state,
        nameId: payload.name,
      };
    case C.ARTICLE_EDIT_REQUEST:
    case C.ARTICLE_NEW_REQUEST:
    case C.ARTICLE_LIST_REQUEST:
    case C.ARTICLE_FILTER_NAME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case C.ARTICLE_FILTER_NAME_SUCCESS:
      return {
        ...state,
        name: payload,
        loading: false,
      };
    case C.ARTICLE_LIST_SUCCESS:
      return {
        ...state,
        pageNumber: payload.currentPage,
        totalItems: payload.totalItems,
        articles: payload.results.sort((a, b) => a.number - b.number),
        loading: false,
      };

    case C.ARTICLE_DETAIL_SUCCESS:
      return {
        ...state,
        article: payload,
        loading: false,
      };
    case C.ARTICLE_EDIT_SUCCESS:
    case C.ARTICLE_NEW_SUCCESS:
      return {
        ...state,
        article: payload,
        loading: false,
      };
    case C.ARTICLE_PRICE_LIST_SUCCESS:
      return {
        ...state,
        prices: payload,
        loading: false,
      };
    case C.ARTICLE_PRICE_CREATE_SUCCESS:
    case C.ARTICLE_PRICE_EDIT_SUCCESS:
    case C.ARTICLE_PRICE_GET_SUCCESS:
      return {
        ...state,
        price: payload,
        loading: false,
      };

    case C.ARTICLE_MACHINE_PRICE_GET_SUCCESS:
    case C.ARTICLE_CLIENT_PRICE_GET_SUCCESS: {
      const missingArticles = payload.articles.filter(
        (article) =>
          !payload.articlesPrices.some(
            (actualArticle) => actualArticle.articleId === article.id
          )
      );
      return {
        ...state,
        prices: payload.prices,
        missingArticles,
        articlesPrices: payload.articlesPrices,
        loading: false,
      };
    }
    case C.ARTICLE_CLIENT_PRICE_CREATE_SUCCESS:
    case C.ARTICLE_MACHINE_PRICE_CREATE_SUCCESS: {
      const missingArticles = state.missingArticles.filter(
        (article) => article.id !== payload.removed
      );
      return {
        ...state,
        missingArticles,
        articlesPrices: [...state.articlesPrices, payload.response],
        loading: false,
      };
    }
    case C.ARTICLE_CLIENT_PRICE_DELETE_SUCCESS:
    case C.ARTICLE_MACHINE_PRICE_DELETE_SUCCESS: {
      const filteredArticlesPrices = state.articlesPrices.filter(
        (articlePrice) => articlePrice.articleId !== payload.articleId
      );
      return {
        ...state,
        articlesPrices: filteredArticlesPrices,
        loading: false,
      };
    }
    case C.ARTICLE_MACHINE_PRICE_EDIT_SUCCESS:
    case C.ARTICLE_CLIENT_PRICE_EDIT_SUCCESS:
      return {
        ...state,
        articlesPrices: payload,
        loading: false,
      };
    case C.ARTICLE_EDIT_FAILURE:
    case C.ARTICLE_NEW_FAILURE:
    case C.ARTICLE_LIST_FAILURE:
    case C.ARTICLE_FILTER_NAME_FAILURE:
    case C.ARTICLE_DETAIL_FAILURE:
    case C.ARTICLE_PRICE_LIST_FAILURE:
    case C.ARTICLE_PRICE_DELETE_FAILURE:
    case C.ARTICLE_PRICE_CREATE_FAILURE:
    case C.ARTICLE_PRICE_EDIT_FAILURE:
    case C.ARTICLE_PRICE_GET_FAILURE:
    case C.ARTICLE_MACHINE_PRICE_CREATE_FAILURE:
    case C.ARTICLE_MACHINE_PRICE_EDIT_FAILURE:
    case C.ARTICLE_MACHINE_PRICE_GET_FAILURE:
    case C.ARTICLE_CLIENT_PRICE_CREATE_FAILURE:
    case C.ARTICLE_CLIENT_PRICE_EDIT_FAILURE:
    case C.ARTICLE_CLIENT_PRICE_GET_FAILURE:
    case C.ARTICLE_CLIENT_PRICE_DELETE_FAILURE:
    case C.ARTICLE_PRICE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
