import Assignment from '@material-ui/icons/Assignment';
import BrokenImage from '@material-ui/icons/BrokenImage';
import Build from '@material-ui/icons/Build';
import Business from '@material-ui/icons/Business';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Extension from '@material-ui/icons/Extension';
import Group from '@material-ui/icons/Group';
import Map from '@material-ui/icons/Map';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import Tab from '@material-ui/icons/Tab';
import Tune from '@material-ui/icons/Tune';
import Warehouse from '@mui/icons-material/Warehouse';

const isPro =
  !process.env.DOMAIN.includes('preproduction') &&
  !window.location.href.includes('localhost');

export const MenuVending = [
  {
    anchor: 'users/',
    id: 'users',
    icon: <Group />,
    literal: 'USERS',
    oldItem: true,
    protect: true,
    role: 'ROLE_SUPER_ADMIN',
  },
  {
    anchor: 'machines/',
    id: 'machines',
    icon: <Tune />,
    literal: 'MACHINES',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'machines/',
    id: 'machines',
    literal: 'LIST',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'machines/brands',
    id: 'machines',
    literal: 'BRANDS',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'machines/models',
    id: 'machines',
    literal: 'MODELS',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'routes/',
    id: 'routes',
    icon: <Tab />,
    literal: 'ROUTES',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'routes',
    id: 'routes',
    literal: 'LIST',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'routes/stats',
    id: 'routes',
    literal: 'ROUTES_STATS',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'machineRoute/',
    id: 'routes',
    literal: 'MENU_ADD_ROUTES',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'routes/schedule',
    id: 'routes-schedule',
    icon: <Map />,
    literal: 'ROUTES_SCHEDULE',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'routes/today',
    id: 'routes-today',
    icon: <CalendarToday />,
    literal: 'ROUTES_OF_THE_DAY',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'tasks/',
    id: 'tasks',
    icon: <Assignment />,
    literal: 'PARTS',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'clients/',
    id: 'clients',
    icon: <Business />,
    literal: 'CLIENTS',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'clients/',
    id: 'clients',
    literal: 'LIST',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'clients/transactions',
    id: 'clients',
    literal: 'TRANSACTIONS',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'articles/',
    id: 'articles',
    icon: <ShoppingBasket />,
    literal: 'ARTICLES',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'articles/',
    id: 'articles',
    literal: 'LIST',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'articles/prices',
    id: 'articles',
    literal: 'PRICES',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'incidents/',
    id: 'incidents',
    icon: <Build />,
    literal: 'INCIDENTS',
    oldItem: false,
    protect: true,
    role: 'ROLE_MECHANIC',
  },
  {
    anchor: 'warehouse/',
    id: 'warehouse',
    icon: <Warehouse />,
    literal: 'WAREHOUSE',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'warehouse/',
    id: 'warehouse',
    literal: 'LIST',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'warehouse/pending_moves',
    id: 'warehouse_pending_moves',
    literal: 'PENDING_MOVES',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'warehouse/inventory',
    id: 'inventory',
    literal: 'INVENTORY',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'warehouse/order/',
    id: 'order',
    literal: 'ORDERS',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'warehouse/supplier',
    id: 'supplier',
    literal: 'SUPPLIER',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'scheduledTasks',
    id: 'schedules-tasks',
    icon: <ScheduleIcon />,
    literal: 'MAINTENANCE',
    oldItem: false,
    hide: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'scheduledTasks',
    id: 'schedules-tasks',
    literal: 'LIST',
    oldItem: false,
    hide: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'scheduledTasks/schedules',
    id: 'schedules-tasks',
    literal: 'TASKS_SCHEDULES',
    oldItem: false,
    hide: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'faults/',
    id: 'fault',
    icon: <BrokenImage />,
    literal: 'FAULTS',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'spareParts/',
    id: 'spare-parts',
    icon: <Extension />,
    literal: 'SPARE_PARTS',
    oldItem: false,
    protect: true,
    role: 'ROLE_MACHINE_MANAGEMENT',
  },
  {
    anchor: 'incentives/',
    id: 'incentives',
    icon: <MonetizationOn />,
    literal: 'INCENTIVE',
    oldItem: false,
  },
  {
    anchor: 'tracking/',
    id: 'tracking',
    icon: <MapOutlinedIcon />,
    literal: 'TRACKING',
    hide: isPro,
    oldItem: false,
  },
];
