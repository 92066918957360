import { call, put, takeLatest } from 'redux-saga/effects';

import IncentivesRepository from '../api/api.incentives';
import * as A from './incentives.action';
import * as C from './incentives.constants';

function* incentivesListRequest({ payload }) {
  try {
    const response = yield call(
      IncentivesRepository.listIncentivesSummary,
      payload
    );
    yield put(A.getIncentivesCustomSuccess(response));
  } catch (e) {
    yield put(A.getIncentivesCustomFailure(e.message));
  }
}
function* incentivesDetailRequest({ payload }) {
  try {
    const response = yield call(
      IncentivesRepository.retrieveIncentivesDetail,
      payload
    );
    yield put(A.getIncentivesDetailSuccess(response));
  } catch (e) {
    yield put(A.getIncentivesDetailFailure(e.message));
  }
}
export default function* incentivesSagas() {
  yield takeLatest(C.INCENTIVES_LIST_REQUEST, incentivesListRequest);
  yield takeLatest(C.INCENTIVES_DETAIL_REQUEST, incentivesDetailRequest);
}
