import { call, put, takeLatest } from 'redux-saga/effects';

import * as GlobalAction from '../../../redux/global/actions/global.action';
import ConfigRepository from '../api/api.config';
import * as A from './config.action';
import * as C from './config.constants';

function* apiKeyListEffect() {
  try {
    const response = yield call(ConfigRepository.getApiKeys);
    yield put(A.apiKeyListSuccess(response));
  } catch (e) {
    yield put(A.apiKeyListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* apiKeyCreateEffect({ payload }) {
  try {
    const response = yield call(ConfigRepository.createApiKey, payload);
    yield put(A.apiKeyCreateSuccess(response));
  } catch (e) {
    yield put(A.apiKeyCreateFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* apiKeyRevokeEffect({ payload }) {
  try {
    const response = yield call(ConfigRepository.revokeApiKey, payload);
    yield put(A.apiKeyRevokeSuccess(response));
    const response2 = yield call(ConfigRepository.getApiKeys);
    yield put(A.apiKeyListSuccess(response2));
  } catch (e) {
    yield put(A.apiKeyRevokeFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

export default function* configSagas() {
  yield takeLatest(C.API_KEY_LIST_REQUEST, apiKeyListEffect);
  yield takeLatest(C.API_KEY_CREATE_REQUEST, apiKeyCreateEffect);
  yield takeLatest(C.API_KEY_REVOKE_REQUEST, apiKeyRevokeEffect);
}
