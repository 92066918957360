import Api from '../../../api';

class RoutesRepository extends Api {
  constructor() {
    super();
    this.api.defaults.baseURL += 'routes';
  }

  listTasks = (data) => this.api.get(`/tasks/filter${this.cleanParams(data)}`);

  getTask = async (id) => this.api.get(`/tasks/${id}`);

  editTask = async (payload) => {
    const { routeId, taskId } = payload;
    return this.api.put(`/${routeId}/tasks/${taskId}`, payload);
  };
}
export default new RoutesRepository();
