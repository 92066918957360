/* eslint-disable no-case-declarations */
import * as C from './users.constants';

export const initialState = {
  users: [],
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.USERS_ALL_LIST_REQUEST:
    case C.USERS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case C.USERS_LIST_SUCCESS:
      const users = payload.map((user) => ({
        ...user,
        value: user.id,
        text: `${user.name} ${user.surname}`,
      }));
      return {
        ...state,
        users,
        loading: false,
      };
    case C.USERS_ALL_LIST_SUCCESS:
      const newUsers = payload.map((user) => ({
        ...user,
        value: user.id,
        text: `${user.name} ${user.surname}`,
      }));
      return {
        ...state,
        loading: false,
        users: newUsers,
      };
    case C.USERS_ALL_LIST_FAILURE:
    case C.USERS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
