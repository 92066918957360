import Api from '../../../api';

class ConfigRepository extends Api {
  constructor() {
    super();
    this.api.defaults.baseURL += 'authentication';
  }

  createApiKey = (data) => {
    return this.api.post('/apiKey/create', data);
  };

  revokeApiKey = (id) => {
    return this.api.post(`/apiKey/revoke/${id}`);
  };

  getApiKeys = () => {
    return this.api.get('/apiKey');
  };
}
export default new ConfigRepository();
