import * as C from '../constants/global.constants';

export const initialState = {
  message: '',
  redirect: false,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.CREATE_ERROR:
      return {
        ...state,
        message: payload,
      };
    case C.REMOVE_ERROR:
      return {
        ...state,
        message: '',
      };
    case C.CREATE_REDIRECT:
      return {
        ...state,
        redirect: payload,
      };
    case C.REMOVE_REDIRECT:
      return {
        ...state,
        redirect: false,
      };
    default:
      return state;
  }
};
