import Api from '../../../api';

class UsersRepository extends Api {
  constructor() {
    super();
    this.api.defaults.baseURL += 'users';
  }

  getAllUsers = async () => this.api.get('/search');

  getUsers = async (payload) => {
    const { users } = payload;
    delete payload.users;
    const response = await this.api.get(
      `/search${this.cleanParams(payload)}&text=${users}`
    );
    return response.slice(0, 10);
  };
}
export default new UsersRepository();
