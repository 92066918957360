import { lazy } from 'react';

const Articles = lazy(() => import('../articlesList'));
const ArticleNewAndEdit = lazy(() => import('../articlesList/newAndEdit'));
const ArticleDetail = lazy(() => import('../articlesList/detail'));
const ArticlesPricesLanding = lazy(() =>
  import('../prices/containers/ArticlesPricesLanding/ArticlesPricesLanding')
);
const ArticlesPricesNewForm = lazy(() =>
  import('../prices/containers/ArticlesPricesNewForm/ArticlesPricesNewForm')
);
const ArticlesPricesDetails = lazy(() =>
  import('../prices/containers/ArticlesPricesDetails/ArticlesPricesDetails')
);

export default [
  {
    path: '/articles',
    component: Articles,
  },
  {
    path: '/articles/prices',
    component: ArticlesPricesLanding,
  },
  {
    path: '/articles/prices/new',
    component: ArticlesPricesNewForm,
  },
  {
    path: '/articles/prices/:priceId',
    component: ArticlesPricesDetails,
  },
  {
    path: '/articles/new',
    component: ArticleNewAndEdit,
  },
  {
    path: '/articles/:articleId/edit',
    component: ArticleNewAndEdit,
  },
  {
    path: '/articles/:articleId/:tab?',
    component: ArticleDetail,
  },
];
