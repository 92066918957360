import Api from '../../../api';

class IncidentsRepository extends Api {
  constructor() {
    super();
    this.api.defaults.baseURL += 'incidents';
  }

  listIncidents = (params) => {
    return this.api.get(`/filter${this.cleanParams(params)}`);
  };

  getIncident = (id) => {
    return this.api.get(`/${id}`);
  };

  createIncident = (data) => {
    return this.api.post('', data);
  };

  getIncidentToEdit = (id) => {
    if (!id) return null;
    return this.getArticle(`/${id}`);
  };

  editIncident = (data) => {
    const { id } = data;
    return this.api.put(`/${id}`, data);
  };

  listFaults = (params) => {
    return this.api.get(`/faults/filter${this.cleanParams(params)}`);
  };

  getFault = (id) => {
    return this.api.get(`/faults/${id}`);
  };

  createFault = (data) => {
    return this.api.post('/faults', data);
  };

  editFault = (data) => {
    const { id } = data;
    return this.api.put(`/faults/${id}`, data);
  };

  listSpareParts = (params) => {
    return this.api.get(`/spareParts/filter${this.cleanParams(params)}`);
  };

  getSparePart = (id) => {
    return this.api.get(`/spareParts/${id}`);
  };

  createSparePart = (data) => {
    return this.api.post('/spareParts', data);
  };

  editSparePart = (data) => {
    const { id } = data;
    return this.api.put(`/spareParts/${id}`, data);
  };
}
export default new IncidentsRepository();
