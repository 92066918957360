import * as C from './incentives.constants';

export const initialState = {
  incentives: {},
  loading: false,
  message: '',
  pageLimit: 20,
  currentPage: 1,
  totalItems: 0,
  from: null,
  to: null,
  detailModalData: {},
  showDetailModal: false,
  userName: '',
  autorizedUsers: [
    'Joaquim Pineda',
    'Marc Vallmitjana',
    'Oscar Carreras',
    'Miguel Garzon',
    'Héctor Giménez Álvarez',
    'alberte alberte',
  ],
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.INCENTIVES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case C.INCENTIVES_LIST_SUCCESS:
      if (state.userName !== '') {
        if (state.autorizedUsers.some((users) => users === state.userName)) {
          state.totalItems = payload.length;
          state.incentives = payload;
          state.loading = false;
        } else {
          const newIncentives = payload.filter(
            (i) => i.userName === state.userName
          );
          state.totalItems = newIncentives.length;
          state.incentives = newIncentives;
          state.loading = false;
        }
      }
      return {
        ...state,
      };
    case C.INCENTIVES_DETAIL_REQUEST:
      return {
        ...state,
      };
    case C.INCENTIVES_DETAIL_SUCCESS:
      return {
        ...state,
        detailModalData: payload,
        showDetailModal: true,
      };
    case C.INCENTIVES_LIST_FAILURE:
    case C.INCENTIVES_DETAIL_FAILURE:
      return {
        ...state,
        message: payload,
      };
    case C.CLOSE_MODAL:
      return {
        ...state,
        showDetailModal: false,
      };
    case C.SET_USER:
      return {
        ...state,
        userName: payload,
      };

    default:
      return state;
  }
};
