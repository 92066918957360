export const CREATE_ROUTE_REQUEST = '[MACHINE_ROUTE] CREATE_ROUTE_REQUEST';
export const CREATE_ROUTE_SUCCESS = '[MACHINE_ROUTE] CREATE_ROUTE_SUCCESS';
export const CREATE_ROUTE_FAILURE = '[MACHINE_ROUTE] CREATE_ROUTE_FAILURE';

export const ADD_TASK_REQUEST = '[MACHINE_ROUTE] ADD_TASK_REQUEST';
export const ADD_TASK_SUCCESS = '[MACHINE_ROUTE] ADD_TASK_SUCCESS';
export const ADD_TASK_FAILURE = '[MACHINE_ROUTE] ADD_TASK_FAILURE';

export const FINISH_TASK_REQUEST = '[MACHINE_ROUTE] FINISH_TASK_REQUEST';
export const FINISH_TASK_SUCCESS = '[MACHINE_ROUTE] FINISH_TASK_SUCCESS';
export const FINISH_TASK_FAILURE = '[MACHINE_ROUTE] FINISH_TASK_FAILURE';

export const ADD_OUTSIDE_TASK_REQUEST =
  '[MACHINE_ROUTE] ADD_OUTSIDE_TASK_REQUEST';
export const ADD_OUTSIDE_TASK_SUCCESS =
  '[MACHINE_ROUTE] ADD_OUTSIDE_TASK_SUCCESS';
export const ADD_OUTSIDE_TASK_FAILURE =
  '[MACHINE_ROUTE] ADD_OUTSIDE_TASK_FAILURE';

export const START_ROUTE_REQUEST = '[MACHINE_ROUTE] START_ROUTE_REQUEST';
export const START_ROUTE_SUCCESS = '[MACHINE_ROUTE] START_ROUTE_SUCCESS';
export const START_ROUTE_FAILURE = '[MACHINE_ROUTE] START_ROUTE_FAILURE';

export const FINISH_ROUTE_REQUEST = '[MACHINE_ROUTE] FINISH_ROUTE_REQUEST';
export const FINISH_ROUTE_SUCCESS = '[MACHINE_ROUTE] FINISH_ROUTE_SUCCESS';
export const FINISH_ROUTE_FAILURE = '[MACHINE_ROUTE] FINISH_ROUTE_FAILURE';

export const GET_VEHICLES_REQUEST = '[MACHINE_ROUTE] GET_VEHICLES_REQUEST';
export const GET_VEHICLES_SUCCESS = '[MACHINE_ROUTE] GET_VEHICLES_SUCCESS';
export const GET_VEHICLES_FAILURE = '[MACHINE_ROUTE] GET_VEHICLES_FAILURE';
