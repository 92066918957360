/* eslint-disable no-underscore-dangle */
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import clientsSaga from '../pages/clients/store/clientsList.saga';
import configSaga from '../pages/config/store/config.saga';
import routesSaga from '../pages/distribution_routes/store/routesList.saga';
import incentivesSaga from '../pages/incentives/store/incentives.saga';
import incidentsSaga from '../pages/incidents/store/incidents.saga';
import machinesSaga from '../pages/machines/store/machineList.saga';
import usersSaga from '../pages/users/store/users.saga';
import articlesSaga from './articles/articles.saga';
import authSaga from './auth/saga/auth.saga';
import routeSaga from './machineRoute/machineRoute.saga';
import modalSaga from './modal/modal.saga';
import reducers from './reducers';
import reportsSaga from './reports/reports.saga';
import tasksSaga from './tasks/tasks.saga';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

export default createStore(
  reducers(history),
  compose(
    applyMiddleware(sagaMiddleware, routerMiddleware(history)),
    typeof window === 'object' &&
      typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

function* rootSaga() {
  yield all([
    authSaga(),
    machinesSaga(),
    articlesSaga(),
    clientsSaga(),
    routesSaga(),
    usersSaga(),
    incentivesSaga(),
    incidentsSaga(),
    tasksSaga(),
    modalSaga(),
    configSaga(),
    routeSaga(),
    reportsSaga(),
  ]);
}

sagaMiddleware.run(rootSaga);
