import * as C from '../constants/auth.constants';

export const loginRequest = (payload) => ({
  type: C.LOGIN_REQUEST,
  payload,
});
export const loginSuccess = (payload) => ({
  type: C.LOGIN_SUCCESS,
  payload,
});
export const loginFailure = (payload) => ({
  type: C.LOGIN_FAILURE,
  payload,
});

export const setUserData = (payload) => ({
  type: C.SET_USER_DATA,
  payload,
});
