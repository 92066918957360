import * as C from './incidents.constants';

export const incidentsReset = (payload) => ({
  type: C.INCIDENTS_RESET,
  payload,
});

export const incidentsListRequest = (payload) => ({
  type: C.INCIDENTS_LIST_REQUEST,
  payload,
});
export const incidentsListSuccess = (payload) => ({
  type: C.INCIDENTS_LIST_SUCCESS,
  payload,
});
export const incidentsListFailure = (payload) => ({
  type: C.INCIDENTS_LIST_FAILURE,
  payload,
});

export const incidentDetailRequest = (payload) => ({
  type: C.INCIDENT_DETAIL_REQUEST,
  payload,
});
export const incidentDetailSuccess = (payload) => ({
  type: C.INCIDENT_DETAIL_SUCCESS,
  payload,
});
export const incidentDetailFailure = (payload) => ({
  type: C.INCIDENT_DETAIL_FAILURE,
  payload,
});

export const incidentCreateRequest = (payload) => ({
  type: C.INCIDENT_CREATE_REQUEST,
  payload,
});
export const incidentCreateSuccess = (payload) => ({
  type: C.INCIDENT_CREATE_SUCCESS,
  payload,
});
export const incidentCreateFailure = (payload) => ({
  type: C.INCIDENT_CREATE_FAILURE,
  payload,
});
export const incidentCreateNextRequest = (payload) => ({
  type: C.INCIDENT_CREATE_NEXT_REQUEST,
  payload,
});

export const incidentEditRequest = (payload) => ({
  type: C.INCIDENT_EDIT_REQUEST,
  payload,
});
export const incidentEditSuccess = (payload) => ({
  type: C.INCIDENT_EDIT_SUCCESS,
  payload,
});
export const incidentEditFailure = (payload) => ({
  type: C.INCIDENT_EDIT_FAILURE,
  payload,
});

export const faultsListRequest = (payload) => ({
  type: C.FAULTS_LIST_REQUEST,
  payload,
});
export const faultsListSuccess = (payload) => ({
  type: C.FAULTS_LIST_SUCCESS,
  payload,
});
export const faultsListFailure = (payload) => ({
  type: C.FAULTS_LIST_FAILURE,
  payload,
});

export const faultDetailRequest = (payload) => ({
  type: C.FAULT_DETAIL_REQUEST,
  payload,
});
export const faultDetailSuccess = (payload) => ({
  type: C.FAULT_DETAIL_SUCCESS,
  payload,
});
export const faultDetailFailure = (payload) => ({
  type: C.FAULT_DETAIL_FAILURE,
  payload,
});

export const faultCreateRequest = (payload) => ({
  type: C.FAULT_CREATE_REQUEST,
  payload,
});
export const faultCreateSuccess = (payload) => ({
  type: C.FAULT_CREATE_SUCCESS,
  payload,
});
export const faultCreateFailure = (payload) => ({
  type: C.FAULT_CREATE_FAILURE,
  payload,
});
export const faultCreateNextRequest = (payload) => ({
  type: C.FAULT_CREATE_NEXT_REQUEST,
  payload,
});

export const faultEditRequest = (payload) => ({
  type: C.FAULT_EDIT_REQUEST,
  payload,
});
export const faultEditSuccess = (payload) => ({
  type: C.FAULT_EDIT_SUCCESS,
  payload,
});
export const faultEditFailure = (payload) => ({
  type: C.FAULT_EDIT_FAILURE,
  payload,
});

export const sparePartsListRequest = (payload) => ({
  type: C.SPARE_PARTS_LIST_REQUEST,
  payload,
});
export const sparePartsListSuccess = (payload) => ({
  type: C.SPARE_PARTS_LIST_SUCCESS,
  payload,
});
export const sparePartsListFailure = (payload) => ({
  type: C.SPARE_PARTS_LIST_FAILURE,
  payload,
});

export const sparePartDetailRequest = (payload) => ({
  type: C.SPARE_PART_DETAIL_REQUEST,
  payload,
});
export const sparePartDetailSuccess = (payload) => ({
  type: C.SPARE_PART_DETAIL_SUCCESS,
  payload,
});
export const sparePartDetailFailure = (payload) => ({
  type: C.SPARE_PART_DETAIL_FAILURE,
  payload,
});

export const sparePartCreateRequest = (payload) => ({
  type: C.SPARE_PART_CREATE_REQUEST,
  payload,
});
export const sparePartCreateSuccess = (payload) => ({
  type: C.SPARE_PART_CREATE_SUCCESS,
  payload,
});
export const sparePartCreateFailure = (payload) => ({
  type: C.SPARE_PART_CREATE_FAILURE,
  payload,
});
export const sparePartCreateNextRequest = (payload) => ({
  type: C.SPARE_PART_CREATE_NEXT_REQUEST,
  payload,
});

export const sparePartEditRequest = (payload) => ({
  type: C.SPARE_PART_EDIT_REQUEST,
  payload,
});
export const sparePartEditSuccess = (payload) => ({
  type: C.SPARE_PART_EDIT_SUCCESS,
  payload,
});
export const sparePartEditFailure = (payload) => ({
  type: C.SPARE_PART_EDIT_FAILURE,
  payload,
});
