import * as C from './reports.constants';

export const getLandAndSurplusRequest = (payload) => ({
  type: C.GET_LACK_AND_SURPLUS_REQUEST,
  payload,
});
export const getLandAndSurplusSuccess = (payload) => ({
  type: C.GET_LACK_AND_SURPLUS_SUCCESS,
  payload,
});
export const getLandAndSurplusFailure = (payload) => ({
  type: C.GET_LACK_AND_SURPLUS_FAILURE,
  payload,
});
