import { call, put, takeLatest } from 'redux-saga/effects';

import * as GlobalAction from '../../../redux/global/actions/global.action';
import ClientRepository from '../api/api.clients';
import * as A from './clientsList.action';
import * as C from './clientsList.constants';

const catchError = (response) => {
  if (response.error) {
    throw response.error;
  }
};
function* clientListEffect({ payload }) {
  try {
    const response = yield call(ClientRepository.listClients, payload);
    yield put(A.clientListSuccess(response));
  } catch (e) {
    yield put(A.clientListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* clientDataEffect({ payload }) {
  try {
    const response = yield call(ClientRepository.getClientById, payload);
    yield put(A.clientDataSuccess(response));
  } catch (e) {
    yield put(A.clientDataFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* clientSendDataEffect({ payload }) {
  try {
    const response = yield call(ClientRepository.editClient, payload);
    yield put(A.sendClientSuccess(response));
  } catch (e) {
    yield put(A.sendClientFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* sendNewAlias({ payload }) {
  try {
    const response = yield call(ClientRepository.addClientAlias, payload);
    yield put(A.sendNewAliasSuccess(response));
  } catch (e) {
    yield put(A.sendNewAliasFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* removeAlias({ payload }) {
  try {
    const response = yield call(ClientRepository.removeClientAlias, payload);
    yield put(A.removeAliasSuccess(response));
  } catch (e) {
    yield put(A.removeAliasFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* updateAllClient({ payload }) {
  try {
    const response = yield call(ClientRepository.updateAllClientData, payload);
    yield put(A.updateAllClientDataSuccess(response));
  } catch (e) {
    yield put(A.updateAllClientDataFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* createNewClient({ payload }) {
  try {
    const response = yield call(ClientRepository.createClient, payload);
    catchError(response);
    yield put(A.createNewclientSuccess(response));
  } catch (e) {
    yield put(A.createNewclientFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
export default function* clientsSagas() {
  yield takeLatest(C.CLIENT_LIST_REQUEST, clientListEffect);
  yield takeLatest(C.CLIENT_DATA_REQUEST, clientDataEffect);
  yield takeLatest(C.CLIENT_SEND_DATA_REQUEST, clientSendDataEffect);
  yield takeLatest(C.CLIENT_ALIAS_ADD_REQUEST, sendNewAlias);
  yield takeLatest(C.CLIENT_ALIAS_REMOVE_REQUEST, removeAlias);
  yield takeLatest(C.CLIENT_UPDATE_ALL_DATA_REQUEST, updateAllClient);
  yield takeLatest(C.CREATE_NEW_CLIENT_REQUEST, createNewClient);
}
