export const INCIDENTS_RESET = '[INCIDENTS] INCIDENTS_RESET';

export const INCIDENTS_LIST_REQUEST = '[INCIDENTS] INCIDENTS_LIST_REQUEST';
export const INCIDENTS_LIST_SUCCESS = '[INCIDENTS] INCIDENTS_LIST_SUCCESS';
export const INCIDENTS_LIST_FAILURE = '[INCIDENTS] INCIDENTS_LIST_FAILURE';

export const INCIDENT_DETAIL_REQUEST = '[INCIDENTS] INCIDENT_DETAIL_REQUEST';
export const INCIDENT_DETAIL_SUCCESS = '[INCIDENTS] INCIDENT_DETAIL_SUCCESS';
export const INCIDENT_DETAIL_FAILURE = '[INCIDENTS] INCIDENT_DETAIL_FAILURE';

export const INCIDENT_EDIT_REQUEST = '[INCIDENTS] INCIDENT_EDIT_REQUEST';
export const INCIDENT_EDIT_SUCCESS = '[INCIDENTS] INCIDENT_EDIT_SUCCESS';
export const INCIDENT_EDIT_FAILURE = '[INCIDENTS] INCIDENT_EDIT_FAILURE';

export const INCIDENT_CREATE_REQUEST = '[INCIDENTS] INCIDENT_CREATE_REQUEST';
export const INCIDENT_CREATE_NEXT_REQUEST =
  '[INCIDENTS] INCIDENT_CREATE_NEXT_REQUEST';
export const INCIDENT_CREATE_SUCCESS = '[INCIDENTS] INCIDENT_CREATE_SUCCESS';
export const INCIDENT_CREATE_FAILURE = '[INCIDENTS] INCIDENT_CREATE_FAILURE';

export const FAULTS_LIST_REQUEST = '[INCIDENTS] FAULTS_LIST_REQUEST';
export const FAULTS_LIST_SUCCESS = '[INCIDENTS] FAULTS_LIST_SUCCESS';
export const FAULTS_LIST_FAILURE = '[INCIDENTS] FAULTS_LIST_FAILURE';

export const FAULT_DETAIL_REQUEST = '[INCIDENTS] FAULT_DETAIL_REQUEST';
export const FAULT_DETAIL_SUCCESS = '[INCIDENTS] FAULT_DETAIL_SUCCESS';
export const FAULT_DETAIL_FAILURE = '[INCIDENTS] FAULT_DETAIL_FAILURE';

export const FAULT_CREATE_REQUEST = '[INCIDENTS] FAULT_CREATE_REQUEST';
export const FAULT_CREATE_NEXT_REQUEST =
  '[INCIDENTS] FAULT_CREATE_NEXT_REQUEST';
export const FAULT_CREATE_SUCCESS = '[INCIDENTS] FAULT_CREATE_SUCCESS';
export const FAULT_CREATE_FAILURE = '[INCIDENTS] FAULT_CREATE_FAILURE';

export const FAULT_EDIT_REQUEST = '[INCIDENTS] FAULT_EDIT_REQUEST';
export const FAULT_EDIT_SUCCESS = '[INCIDENTS] FAULT_EDIT_SUCCESS';
export const FAULT_EDIT_FAILURE = '[INCIDENTS] FAULT_EDIT_FAILURE';

export const SPARE_PARTS_LIST_REQUEST = '[INCIDENTS] SPARE_PARTS_LIST_REQUEST';
export const SPARE_PARTS_LIST_SUCCESS = '[INCIDENTS] SPARE_PARTS_LIST_SUCCESS';
export const SPARE_PARTS_LIST_FAILURE = '[INCIDENTS] SPARE_PARTS_LIST_FAILURE';

export const SPARE_PART_DETAIL_REQUEST =
  '[INCIDENTS] SPARE_PART_DETAIL_REQUEST';
export const SPARE_PART_DETAIL_SUCCESS =
  '[INCIDENTS] SPARE_PART_DETAIL_SUCCESS';
export const SPARE_PART_DETAIL_FAILURE =
  '[INCIDENTS] SPARE_PART_DETAIL_FAILURE';

export const SPARE_PART_CREATE_REQUEST =
  '[INCIDENTS] SPARE_PART_CREATE_REQUEST';
export const SPARE_PART_CREATE_NEXT_REQUEST =
  '[INCIDENTS] SPARE_PART_CREATE_NEXT_REQUEST';
export const SPARE_PART_CREATE_SUCCESS =
  '[INCIDENTS] SPARE_PART_CREATE_SUCCESS';
export const SPARE_PART_CREATE_FAILURE =
  '[INCIDENTS] SPARE_PART_CREATE_FAILURE';

export const SPARE_PART_EDIT_REQUEST = '[INCIDENTS] SPARE_PART_EDIT_REQUEST';
export const SPARE_PART_EDIT_SUCCESS = '[INCIDENTS] SPARE_PART_EDIT_SUCCESS';
export const SPARE_PART_EDIT_FAILURE = '[INCIDENTS] SPARE_PART_EDIT_FAILURE';
