import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import MachineRouteRepository from '../../pages/machineRoute/api/api.machineRoute';
import MachineRoutePHPRepository from '../../pages/machineRoute/api/php.machineRoute';
import * as GlobalAction from '../global/actions/global.action';
import * as A from './machineRoute.action';
import * as C from './machineRoute.constants';

function* createRouteEffect({ payload }) {
  try {
    const response = yield call(MachineRouteRepository.createRoute, payload);
    yield put(A.createRouteSuccess(response));
  } catch (e) {
    yield put(A.createRouteFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* addTaskEffect({ payload }) {
  try {
    const response = yield call(MachineRouteRepository.addTask, payload);
    yield put(A.addTaskSuccess(response));
  } catch (e) {
    yield put(A.addTaskFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* finishTaskEffect({ payload }) {
  try {
    const response = yield call(MachineRouteRepository.finishTask, payload);
    yield put(A.finishTaskSuccess(response));
  } catch (e) {
    yield put(A.finishTaskFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* addOutsideTaskEffect({ payload }) {
  try {
    const response = yield call(MachineRouteRepository.addOutsideTask, payload);
    const { routeId } = payload;
    yield put(push(`/machineRoute/${routeId}/machine/${response.id}`));
  } catch (e) {
    yield put(A.addOutsideTaskFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* startRouteEffect({ payload }) {
  try {
    const response = yield call(MachineRouteRepository.startRoute, payload);
    yield put(A.startRouteSuccess(response));
    yield put(push(`/machineRoute/${response.id}`));
  } catch (e) {
    yield put(A.startRouteFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* finishRouteEffect({ payload }) {
  try {
    const response = yield call(MachineRouteRepository.finishRoute, payload);
    yield put(A.finishRouteSuccess(response));
  } catch (e) {
    yield put(A.finishRouteFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* getVehiclesEffect() {
  try {
    const response = yield call(MachineRoutePHPRepository.getVehicles);
    yield put(A.getVehiclesSuccess(response));
  } catch (e) {
    yield put(A.getVehiclesFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

export default function* incidentSagas() {
  yield takeLatest(C.CREATE_ROUTE_REQUEST, createRouteEffect);
  yield takeLatest(C.ADD_TASK_REQUEST, addTaskEffect);
  yield takeLatest(C.FINISH_TASK_REQUEST, finishTaskEffect);
  yield takeLatest(C.ADD_OUTSIDE_TASK_REQUEST, addOutsideTaskEffect);
  yield takeLatest(C.START_ROUTE_REQUEST, startRouteEffect);
  yield takeLatest(C.FINISH_ROUTE_REQUEST, finishRouteEffect);
  yield takeLatest(C.GET_VEHICLES_REQUEST, getVehiclesEffect);
}
