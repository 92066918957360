import * as C from './tasks.constants';

export const tasksListRequest = (payload) => ({
  type: C.TASKS_REQUEST,
  payload,
});
export const tasksListSuccess = (payload) => ({
  type: C.TASKS_SUCCESS,
  payload,
});
export const tasksListFailure = (payload) => ({
  type: C.TASKS_FAILURE,
  payload,
});

export const taskRequest = (payload) => ({
  type: C.GET_TASK_REQUEST,
  payload,
});
export const taskSuccess = (payload) => ({
  type: C.GET_TASK_SUCCESS,
  payload,
});
export const taskFailure = (payload) => ({
  type: C.GET_TASK_FAILURE,
  payload,
});

export const taskEditRequest = (payload) => ({
  type: C.EDIT_TASK_REQUEST,
  payload,
});
export const taskEditSuccess = (payload) => ({
  type: C.EDIT_TASK_SUCCESS,
  payload,
});
export const taskEditFailure = (payload) => ({
  type: C.EDIT_TASK_FAILURE,
  payload,
});
