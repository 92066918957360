const useSaveFile = (blob, pdfName) => {
  const newBlob = new Blob([blob], { type: 'application/pdf' });

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = pdfName;
  link.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(data);
  }, 100);
};

export default useSaveFile;
