import classNames from 'classnames';
import { Link } from 'react-router-dom';

const BASE_CLASS = 'vm-Nav';
const LOGO_CLASS = `${BASE_CLASS}-logo`;
const BURGER_CLASS = `${BASE_CLASS}-burger navbar-burger`;

const Nav = function Nav({ handleToggle = () => {}, isOpen }) {
  const BURGER_ACTIVE_CLASS = classNames(BURGER_CLASS, {
    'is-active': isOpen,
  });

  return (
    <nav
      className={`${BASE_CLASS} navbar`}
      role='navigation'
      aria-label='main navigation'
    >
      <button
        type='button'
        className={BURGER_ACTIVE_CLASS}
        aria-label='menu'
        aria-expanded='false'
        onClick={handleToggle}
      >
        <span aria-hidden='true' />
        <span aria-hidden='true' />
        <span aria-hidden='true' />
      </button>
      <div className='navbar-brand'>
        <Link className='navbar-item' to='/'>
          <img
            alt='Logo'
            src='https://web.vement.io/assets/images/cafegra/logo.png'
            height='30px'
          />
          <h2 className={`${LOGO_CLASS}-client`}>Cafegra</h2>
        </Link>
      </div>
    </nav>
  );
};

export default Nav;
