export const API_KEY_LIST_REQUEST = '[API_KEY] API_KEY_LIST_REQUEST';
export const API_KEY_LIST_SUCCESS = '[API_KEY] API_KEY_LIST_SUCCESS';
export const API_KEY_LIST_FAILURE = '[API_KEY] API_KEY_LIST_FAILURE';

export const API_KEY_REVOKE_REQUEST = '[API_KEY] API_KEY_REVOKE_REQUEST';
export const API_KEY_REVOKE_SUCCESS = '[API_KEY] API_KEY_REVOKE_SUCCESS';
export const API_KEY_REVOKE_FAILURE = '[API_KEY] API_KEY_REVOKE_FAILURE';

export const API_KEY_CREATE_REQUEST = '[API_KEY] API_KEY_CREATE_REQUEST';
export const API_KEY_CREATE_SUCCESS = '[API_KEY] API_KEY_CREATE_SUCCESS';
export const API_KEY_CREATE_FAILURE = '[API_KEY] API_KEY_CREATE_FAILURE';

export const SET_LOADING = '[API_KEY] SET_LOADING';
