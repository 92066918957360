import { lazy } from 'react';

const ApiKeyLanding = lazy(() =>
  import('../containers/ApiKeyLanding/ApiKeyLanding')
);
const ApiKeyNewForm = lazy(() =>
  import('../containers/ApiKeyNewForm/ApiKeyNewForm')
);

export default [
  {
    path: '/config/apikey',
    component: ApiKeyLanding,
  },
  {
    path: '/config/apikey/new',
    component: ApiKeyNewForm,
  },
];
