import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const MenuItem = function MenuItem({
  anchor,
  className,
  icon,
  isActive,
  literal,
  onClick,
  redirect = true,
  handleToggleMenu,
  oldItem = false,
  hide = false,
  protect = false,
  role,
}) {
  const { t } = useTranslation();
  const { roles } = useSelector((state) => state.auth);

  const itemAnchor = oldItem
    ? process.env.DOMAIN_OLD + anchor
    : process.env.DOMAIN + anchor;

  let itemClass = className;

  if (isActive) {
    itemClass = `${className} ${className}--isActive`;
  }

  const inside = () => (
    <>
      <span className={`${className}-icon`}>{icon}</span>
      <span className={`${className}-literal`}>{t(literal)}</span>
    </>
  );

  const renderLink = () =>
    oldItem ? (
      <a onClick={handleToggleMenu} href={itemAnchor}>
        {inside()}
      </a>
    ) : (
      <Link onClick={handleToggleMenu} to={`/${anchor}`}>
        {inside()}
      </Link>
    );

  const accessMenu = () => {
    if (hide) return false;
    if (!protect) return true;
    const hasRole = roles.includes(role);
    if (hasRole) return true;
    return false;
  };

  return accessMenu() ? (
    <li className={itemClass}>
      {redirect ? (
        renderLink()
      ) : (
        <div role='button' tabIndex={0} onClick={onClick}>
          {inside()}
        </div>
      )}
    </li>
  ) : null;
};
