import { lazy } from 'react';

const MachineRouteLanding = lazy(() =>
  import('../containers/MachineRouteLanding/MachineRouteLanding')
);
const MachineRouteList = lazy(() =>
  import('../containers/MachineRouteDetails/MachineRouteList')
);
const MachineRouteCharge = lazy(() =>
  import('../containers/MachineRouteDetails/MachineRouteCharge')
);

export default [
  {
    path: '/machineRoute',
    component: MachineRouteLanding,
  },
  {
    path: '/machineRoute/:routeId',
    component: MachineRouteList,
  },
  {
    path: '/machineRoute/:routeId/machine/:taskId',
    component: MachineRouteCharge,
  },
];
