import * as C from './machineRoute.constants';

export const initialState = {
  pageLimit: 20,
  currentPage: 1,
  totalItems: 0,
  loading: true,
  vehicles: [],
  idOutsideRoute: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.CREATE_ROUTE_REQUEST:
    case C.ADD_TASK_REQUEST:
    case C.FINISH_TASK_REQUEST:
    case C.ADD_OUTSIDE_TASK_REQUEST:
    case C.START_ROUTE_REQUEST:
    case C.FINISH_ROUTE_REQUEST:
    case C.GET_VEHICLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case C.GET_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: payload,
        loading: false,
      };
    case C.CREATE_ROUTE_SUCCESS:
      return {
        ...state,
        idOutsideRoute: payload.id,
        loading: false,
      };
    case C.CREATE_ROUTE_FAILURE:
    case C.ADD_TASK_FAILURE:
    case C.FINISH_TASK_FAILURE:
    case C.ADD_OUTSIDE_TASK_FAILURE:
    case C.START_ROUTE_FAILURE:
    case C.FINISH_ROUTE_FAILURE:
    case C.GET_VEHICLES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
