import * as C from './articles.constants';

export const articleListRequest = (payload) => ({
  type: C.ARTICLE_LIST_REQUEST,
  payload,
});
export const articleListSuccess = (payload) => ({
  type: C.ARTICLE_LIST_SUCCESS,
  payload,
});
export const articleListFailure = (payload) => ({
  type: C.ARTICLE_LIST_FAILURE,
  payload,
});

export const articleDetailsRequest = (payload) => ({
  type: C.ARTICLE_DETAIL_REQUEST,
  payload,
});
export const articleDetailsSuccess = (payload) => ({
  type: C.ARTICLE_DETAIL_SUCCESS,
  payload,
});
export const articleDetailsFailure = (payload) => ({
  type: C.ARTICLE_DETAIL_FAILURE,
  payload,
});

export const articleNewRequest = (payload) => ({
  type: C.ARTICLE_NEW_REQUEST,
  payload,
});
export const articleNewSuccess = (payload) => ({
  type: C.ARTICLE_NEW_SUCCESS,
  payload,
});
export const articleNewFailure = (payload) => ({
  type: C.ARTICLE_NEW_FAILURE,
  payload,
});

export const articleEditRequest = (payload) => ({
  type: C.ARTICLE_EDIT_REQUEST,
  payload,
});
export const articleEditSuccess = (payload) => ({
  type: C.ARTICLE_EDIT_SUCCESS,
  payload,
});
export const articleEditFailure = (payload) => ({
  type: C.ARTICLE_EDIT_FAILURE,
  payload,
});

export const articleFilterNameRequest = (payload) => ({
  type: C.ARTICLE_FILTER_NAME_REQUEST,
  payload,
});
export const articleFilterNameSuccess = (payload) => ({
  type: C.ARTICLE_FILTER_NAME_SUCCESS,
  payload,
});
export const articleFilterNameFailure = (payload) => ({
  type: C.ARTICLE_FILTER_NAME_FAILURE,
  payload,
});
export const articleUpdatePageRequest = (payload) => ({
  type: C.ARTICLE_UPDATE_PAGE_REQUEST,
  payload,
});

export const articlePriceListRequest = (payload) => ({
  type: C.ARTICLE_PRICE_LIST_REQUEST,
  payload,
});
export const articlePriceListSuccess = (payload) => ({
  type: C.ARTICLE_PRICE_LIST_SUCCESS,
  payload,
});
export const articlePriceListFailure = (payload) => ({
  type: C.ARTICLE_PRICE_LIST_FAILURE,
  payload,
});

export const articlePriceDeleteRequest = (payload) => ({
  type: C.ARTICLE_PRICE_DELETE_REQUEST,
  payload,
});
export const articlePriceDeleteSuccess = (payload) => ({
  type: C.ARTICLE_PRICE_DELETE_SUCCESS,
  payload,
});
export const articlePriceDeleteFailure = (payload) => ({
  type: C.ARTICLE_PRICE_DELETE_FAILURE,
  payload,
});

export const articlePriceCreateRequest = (payload) => ({
  type: C.ARTICLE_PRICE_CREATE_REQUEST,
  payload,
});
export const articlePriceCreateSuccess = (payload) => ({
  type: C.ARTICLE_PRICE_CREATE_SUCCESS,
  payload,
});
export const articlePriceCreateFailure = (payload) => ({
  type: C.ARTICLE_PRICE_CREATE_FAILURE,
  payload,
});

export const articlePriceEditRequest = (payload) => ({
  type: C.ARTICLE_PRICE_EDIT_REQUEST,
  payload,
});
export const articlePriceEditSuccess = (payload) => ({
  type: C.ARTICLE_PRICE_EDIT_SUCCESS,
  payload,
});
export const articlePriceEditFailure = (payload) => ({
  type: C.ARTICLE_PRICE_EDIT_FAILURE,
  payload,
});

export const articlePriceGetRequest = (payload) => ({
  type: C.ARTICLE_PRICE_GET_REQUEST,
  payload,
});
export const articlePriceGetSuccess = (payload) => ({
  type: C.ARTICLE_PRICE_GET_SUCCESS,
  payload,
});
export const articlePriceGetFailure = (payload) => ({
  type: C.ARTICLE_PRICE_GET_FAILURE,
  payload,
});

export const articleMachinePriceCreateRequest = (payload) => ({
  type: C.ARTICLE_MACHINE_PRICE_CREATE_REQUEST,
  payload,
});
export const articleMachinePriceCreateSuccess = (payload) => ({
  type: C.ARTICLE_MACHINE_PRICE_CREATE_SUCCESS,
  payload,
});
export const articleMachinePriceCreateFailure = (payload) => ({
  type: C.ARTICLE_MACHINE_PRICE_CREATE_FAILURE,
  payload,
});

export const articleMachinePriceEditRequest = (payload) => ({
  type: C.ARTICLE_MACHINE_PRICE_EDIT_REQUEST,
  payload,
});
export const articleMachinePriceEditSuccess = (payload) => ({
  type: C.ARTICLE_MACHINE_PRICE_EDIT_SUCCESS,
  payload,
});
export const articleMachinePriceEditFailure = (payload) => ({
  type: C.ARTICLE_MACHINE_PRICE_EDIT_FAILURE,
  payload,
});

export const articleMachinePriceDeleteRequest = (payload) => ({
  type: C.ARTICLE_MACHINE_PRICE_DELETE_REQUEST,
  payload,
});
export const articleMachinePriceDeleteSuccess = (payload) => ({
  type: C.ARTICLE_MACHINE_PRICE_DELETE_SUCCESS,
  payload,
});
export const articleMachinePriceDeleteFailure = (payload) => ({
  type: C.ARTICLE_MACHINE_PRICE_DELETE_FAILURE,
  payload,
});

export const articleMachinePriceGetRequest = (payload) => ({
  type: C.ARTICLE_MACHINE_PRICE_GET_REQUEST,
  payload,
});
export const articleMachinePriceGetSuccess = (payload) => ({
  type: C.ARTICLE_MACHINE_PRICE_GET_SUCCESS,
  payload,
});
export const articleMachinePriceGetFailure = (payload) => ({
  type: C.ARTICLE_MACHINE_PRICE_GET_FAILURE,
  payload,
});

export const articleClientPriceCreateRequest = (payload) => ({
  type: C.ARTICLE_CLIENT_PRICE_CREATE_REQUEST,
  payload,
});
export const articleClientPriceCreateSuccess = (payload) => ({
  type: C.ARTICLE_CLIENT_PRICE_CREATE_SUCCESS,
  payload,
});
export const articleClientPriceCreateFailure = (payload) => ({
  type: C.ARTICLE_CLIENT_PRICE_CREATE_FAILURE,
  payload,
});

export const articleClientPriceEditRequest = (payload) => ({
  type: C.ARTICLE_CLIENT_PRICE_EDIT_REQUEST,
  payload,
});
export const articleClientPriceEditSuccess = (payload) => ({
  type: C.ARTICLE_CLIENT_PRICE_EDIT_SUCCESS,
  payload,
});
export const articleClientPriceEditFailure = (payload) => ({
  type: C.ARTICLE_CLIENT_PRICE_EDIT_FAILURE,
  payload,
});

export const articleClientPriceGetRequest = (payload) => ({
  type: C.ARTICLE_CLIENT_PRICE_GET_REQUEST,
  payload,
});
export const articleClientPriceGetSuccess = (payload) => ({
  type: C.ARTICLE_CLIENT_PRICE_GET_SUCCESS,
  payload,
});
export const articleClientPriceGetFailure = (payload) => ({
  type: C.ARTICLE_CLIENT_PRICE_GET_FAILURE,
  payload,
});

export const articleClientPriceDeleteRequest = (payload) => ({
  type: C.ARTICLE_CLIENT_PRICE_DELETE_REQUEST,
  payload,
});
export const articleClientPriceDeleteSuccess = (payload) => ({
  type: C.ARTICLE_CLIENT_PRICE_DELETE_SUCCESS,
  payload,
});
export const articleClientPriceDeleteFailure = (payload) => ({
  type: C.ARTICLE_CLIENT_PRICE_DELETE_FAILURE,
  payload,
});
