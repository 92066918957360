import ArticleRoutes from '../pages/articles/routes';
import ClientRoutes from '../pages/clients/routes';
import ConfigRoutes from '../pages/config/routes';
import DistributionRoutes from '../pages/distribution_routes/routes';
import IncentivesRoutes from '../pages/incentives/routes';
import IncidentsRoutes from '../pages/incidents/routes';
import MachineRouteRoutes from '../pages/machineRoute/routes';
import MachineRoutes from '../pages/machines/routes';
import ReportsRoutes from '../pages/reports/routes';
import RecurrentActions from '../pages/scheduledTasks/routes';
import TasksRoutes from '../pages/tasks/routes';
import TrackingRoutes from '../pages/tracking/routes';
import WarehouseRoutes from '../pages/warehouse/routes';

const notFound = {
  path: '*',
  component: () => null,
};

export default [
  IncentivesRoutes,
  ClientRoutes,
  ArticleRoutes,
  MachineRoutes,
  DistributionRoutes,
  IncidentsRoutes,
  RecurrentActions,
  ConfigRoutes,
  TasksRoutes,
  MachineRouteRoutes,
  ReportsRoutes,
  TrackingRoutes,
  WarehouseRoutes,
  notFound,
].flat();
