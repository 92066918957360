import ApiPHP from '../../../api/php';

class MachineRouteRepository extends ApiPHP {
  constructor() {
    super();
    this.ApiPHP.defaults.baseURL += 'vehicles/';
  }

  getVehicles = () => this.ApiPHP.get('/');
}
export default new MachineRouteRepository();
