export const INCENTIVES_LIST_REQUEST = '[INCENTIVES] INCENTIVES_LIST_REQUEST';
export const INCENTIVES_LIST_SUCCESS = '[INCENTIVES] INCENTIVES_LIST_SUCCESS';
export const INCENTIVES_LIST_FAILURE = '[INCENTIVES] INCENTIVES_LIST_FAILURE';

export const INCENTIVES_DETAIL_REQUEST =
  '[INCENTIVES] INCENTIVES_DETAIL_REQUEST';
export const INCENTIVES_DETAIL_SUCCESS =
  '[INCENTIVES] INCENTIVES_DETAIL_SUCCESS';
export const INCENTIVES_DETAIL_FAILURE =
  '[INCENTIVES] INCENTIVES_DETAIL_FAILURE';

export const CLOSE_MODAL = '[INCENTIVES] CLOSEMODAL';

export const SET_USER = '[INCENTIVES] SETUSER';
