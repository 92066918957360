import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import * as GlobalAction from '../../../redux/global/actions/global.action';
import IncidentsRepository from '../api/api.incidents';
import * as A from './incidents.action';
import * as C from './incidents.constants';

function* incidentsListEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.listIncidents, payload);
    yield put(A.incidentsListSuccess(response));
  } catch (e) {
    yield put(A.incidentsListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* incidentDetailEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.getIncident, payload);
    yield put(A.incidentDetailSuccess(response));
  } catch (e) {
    yield put(A.incidentDetailFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* incidentCreateEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.createIncident, payload);
    yield put(A.incidentCreateSuccess(response));
    yield put(push(`/incidents/${response.id}`));
  } catch (e) {
    yield put(A.incidentCreateFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* incidentEditEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.editIncident, payload);
    yield put(A.incidentEditSuccess(response));
    yield put(push('/incidents'));
  } catch (e) {
    yield put(A.incidentEditFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* incidentCreateNextEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.createIncident, payload);
    yield put(A.incidentCreateSuccess(response));
  } catch (e) {
    yield put(A.incidentCreateFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* faultsListEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.listFaults, payload);
    yield put(A.faultsListSuccess(response));
  } catch (e) {
    yield put(A.faultsListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* faultDetailEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.getFault, payload);
    yield put(A.faultDetailSuccess(response));
  } catch (e) {
    yield put(A.faultDetailFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* faultCreateEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.createFault, payload);
    yield put(A.faultCreateSuccess(response));
    yield put(push(`/faults/${response.id}`));
  } catch (e) {
    yield put(A.faultCreateFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* faultCreateNextEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.createFault, payload);
    yield put(A.faultCreateSuccess(response));
  } catch (e) {
    yield put(A.faultCreateFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* faultEditEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.editFault, payload);
    yield put(A.faultEditSuccess(response));
    yield put(push('/faults'));
  } catch (e) {
    yield put(A.faultEditFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* sparePartsListEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.listSpareParts, payload);
    yield put(A.sparePartsListSuccess(response));
  } catch (e) {
    yield put(A.sparePartsListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* sparePartDetailEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.getSparePart, payload);
    yield put(A.sparePartDetailSuccess(response));
  } catch (e) {
    yield put(A.sparePartDetailFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* sparePartCreateEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.createSparePart, payload);
    yield put(A.sparePartCreateSuccess(response));
    yield put(push(`/spareParts/${response.id}`));
  } catch (e) {
    yield put(A.sparePartCreateFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* sparePartCreateNextEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.createSparePart, payload);
    yield put(A.sparePartCreateSuccess(response));
  } catch (e) {
    yield put(A.sparePartCreateFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* sparePartEditEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.editSparePart, payload);
    yield put(A.sparePartEditSuccess(response));
    yield put(push('/spareParts'));
  } catch (e) {
    yield put(A.sparePartEditFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

export default function* incidentSagas() {
  yield takeLatest(C.INCIDENTS_LIST_REQUEST, incidentsListEffect);
  yield takeLatest(C.INCIDENT_DETAIL_REQUEST, incidentDetailEffect);
  yield takeLatest(C.INCIDENT_CREATE_REQUEST, incidentCreateEffect);
  yield takeLatest(C.INCIDENT_CREATE_NEXT_REQUEST, incidentCreateNextEffect);
  yield takeLatest(C.INCIDENT_EDIT_REQUEST, incidentEditEffect);
  yield takeLatest(C.FAULTS_LIST_REQUEST, faultsListEffect);
  yield takeLatest(C.FAULT_DETAIL_REQUEST, faultDetailEffect);
  yield takeLatest(C.FAULT_CREATE_REQUEST, faultCreateEffect);
  yield takeLatest(C.FAULT_CREATE_NEXT_REQUEST, faultCreateNextEffect);
  yield takeLatest(C.FAULT_EDIT_REQUEST, faultEditEffect);
  yield takeLatest(C.SPARE_PARTS_LIST_REQUEST, sparePartsListEffect);
  yield takeLatest(C.SPARE_PART_DETAIL_REQUEST, sparePartDetailEffect);
  yield takeLatest(C.SPARE_PART_CREATE_REQUEST, sparePartCreateEffect);
  yield takeLatest(C.SPARE_PART_CREATE_NEXT_REQUEST, sparePartCreateNextEffect);
  yield takeLatest(C.SPARE_PART_EDIT_REQUEST, sparePartEditEffect);
}
