import { lazy } from 'react';

const Tasks = lazy(() => import('../containers/TasksLanding/TaskLanding'));
const TaskDetail = lazy(() => import('../containers/TaskDetail/TaskDetail'));

export default [
  {
    path: '/tasks',
    component: Tasks,
  },
  {
    component: TaskDetail,
    path: '/tasks/:taskId',
  },
];
