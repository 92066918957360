import * as C from './config.constants';

export const initialState = {
  apiKey: null,
  apiKeys: [],
  loading: true,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.API_KEY_LIST_REQUEST:
    case C.API_KEY_REVOKE_REQUEST:
    case C.API_KEY_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case C.API_KEY_LIST_SUCCESS:
      return {
        ...state,
        apiKeys: payload.sort((a, b) => a.name - b.name),
        loading: false,
      };
    case C.API_KEY_CREATE_SUCCESS:
      return {
        ...state,
        apiKey: payload,
        loading: false,
      };
    case C.API_KEY_REVOKE_SUCCESS:
    case C.API_KEY_LIST_FAILURE:
    case C.API_KEY_REVOKE_FAILURE:
    case C.API_KEY_CREATE_FAILURE:
    case C.SET_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
