/* eslint-disable react/no-array-index-key */
import Dashboard from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import { Accordion, AccordionSummary } from '@mui/material';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as A from '../../../redux/navbar/actions/navbar.actions';
import { MenuItem } from '../menu-item';
import { MenuTitle } from '../menu-title';
import ConfigMenu from './ConfigMenu';
import MenuLabels from './menuLabels';
import { MenuReports } from './menuReports';
import { MenuVehicles } from './menuVehicles';
import { MenuVending } from './menuVending';

const BASE_CLASS = 'vm-MenuList';
const TITLE_CLASS = `${BASE_CLASS}-title`;
const ITEM_CLASS = `${BASE_CLASS}-item`;

const menuItemsMain = {
  anchor: '',
  id: 'main',
  icon: <Dashboard />,
  literal: 'MAIN',
  oldItem: true,
};

const ConfigSwap = memo(function ConfigSwap({ literal, icon }) {
  const dispatch = useDispatch();

  const swapMenu = () => {
    dispatch(A.swapMenu());
  };

  const menuConfigSwapData = {
    anchor: '',
    id: 'config',
    oldItem: false,
    onClick: swapMenu,
  };

  return (
    <MenuItem
      key={menuConfigSwapData.id}
      className={ITEM_CLASS}
      redirect={false}
      anchor={menuConfigSwapData.anchor}
      icon={icon}
      onClick={menuConfigSwapData.onClick}
      literal={literal}
    />
  );
});

const cleanCookies = () => {
  document.cookie = `jwt=; path=/; domain=.vement.io; expires=${new Date(
    0
  ).toUTCString()}`;
  document.cookie = `jwt=; path=/; domain=localhost; expires=${new Date(
    0
  ).toUTCString()}`;
  document.cookie = `PHPSESSID=; path=/; domain=.vement.io; expires=${new Date(
    0
  ).toUTCString()}`;
  document.cookie = `refresh=; path=/; domain=.vement.io; expires=${new Date(
    0
  ).toUTCString()}`;
};

export const MenuList = function MenuList({ handleToggleMenu }) {
  const { menu } = useSelector((state) => state.navBar);
  const [vendingExpanded, setVendingExpanded] = useState(true);

  return menu ? (
    <ul className={BASE_CLASS}>
      <MenuItem
        key='LOGOUT'
        className={ITEM_CLASS}
        handleToggleMenu={() => cleanCookies()}
        anchor='login'
        icon={<ExitToAppIcon />}
        literal='LOGOUT'
        oldItem={false}
      />
      <ConfigSwap literal='CONFIG' icon={<SettingsIcon />} />
      <MenuItem
        handleToggleMenu={handleToggleMenu}
        key={menuItemsMain.id}
        className={ITEM_CLASS}
        anchor={menuItemsMain.anchor}
        hide={menuItemsMain.hide}
        icon={menuItemsMain.icon}
        literal={menuItemsMain.literal}
        oldItem={menuItemsMain.oldItem}
      />
      <Accordion
        expanded={vendingExpanded}
        onChange={() => setVendingExpanded(!vendingExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
        >
          <MenuTitle className={TITLE_CLASS} literal='VENDING' />
        </AccordionSummary>
        {MenuVending.map(
          (
            { anchor, id, icon, literal, oldItem, hide, protect, role },
            index
          ) => (
            <MenuItem
              handleToggleMenu={handleToggleMenu}
              key={`${index}-${id}`}
              className={ITEM_CLASS}
              anchor={anchor}
              icon={icon}
              hide={hide}
              isActive={false}
              literal={literal}
              oldItem={oldItem}
              protect={protect}
              role={role}
            />
          )
        )}
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
        >
          <MenuTitle className={TITLE_CLASS} literal='VEHICLES' />
        </AccordionSummary>
        {MenuVehicles.map(
          (
            { anchor, id, icon, literal, oldItem, hide, protect, role },
            index
          ) => (
            <MenuItem
              handleToggleMenu={handleToggleMenu}
              key={`${index}-${id}`}
              className={ITEM_CLASS}
              anchor={anchor}
              icon={icon}
              hide={hide}
              isActive={false}
              literal={literal}
              oldItem={oldItem}
              protect={protect}
              role={role}
            />
          )
        )}
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
        >
          <MenuTitle className={TITLE_CLASS} literal='LABELS' />
        </AccordionSummary>
        {MenuLabels.map(
          (
            { anchor, id, icon, literal, oldItem, hide, protect, role },
            index
          ) => (
            <MenuItem
              handleToggleMenu={handleToggleMenu}
              key={`${index}-${id}`}
              className={ITEM_CLASS}
              anchor={anchor}
              icon={icon}
              hide={hide}
              isActive={false}
              literal={literal}
              oldItem={oldItem}
              protect={protect}
              role={role}
            />
          )
        )}
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
        >
          <MenuTitle className={TITLE_CLASS} literal='REPORTS' />
        </AccordionSummary>
        {MenuReports.map(
          (
            { anchor, id, icon, literal, oldItem, hide, protect, role },
            index
          ) => (
            <MenuItem
              handleToggleMenu={handleToggleMenu}
              key={`${index}-${id}`}
              className={ITEM_CLASS}
              anchor={anchor}
              icon={icon}
              hide={hide}
              isActive={false}
              literal={literal}
              oldItem={oldItem}
              protect={protect}
              role={role}
            />
          )
        )}
      </Accordion>
    </ul>
  ) : (
    <ul className={BASE_CLASS}>
      <ConfigSwap literal='MENU' icon={<MenuIcon />} />
      {ConfigMenu.map(
        (
          { anchor, id, icon, literal, oldItem, hide, protect, role },
          index
        ) => (
          <MenuItem
            handleToggleMenu={handleToggleMenu}
            key={`${index}-${id}`}
            className={ITEM_CLASS}
            anchor={anchor}
            icon={icon}
            hide={hide}
            isActive={false}
            literal={literal}
            oldItem={oldItem}
            protect={protect}
            role={role}
          />
        )
      )}
    </ul>
  );
};
