import * as C from './incidents.constants';

export const initialState = {
  incidents: [],
  incident: {},
  faults: [],
  fault: {},
  spareParts: [],
  sparePart: {},
  pageLimit: 20,
  currentPage: 1,
  totalItems: 0,
  loading: true,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.FAULTS_LIST_REQUEST:
    case C.FAULT_DETAIL_REQUEST:
    case C.INCIDENT_DETAIL_REQUEST:
    case C.FAULT_CREATE_REQUEST:
    case C.INCIDENT_CREATE_REQUEST:
    case C.FAULT_EDIT_REQUEST:
    case C.SPARE_PARTS_LIST_REQUEST:
    case C.SPARE_PART_DETAIL_REQUEST:
    case C.SPARE_PART_CREATE_REQUEST:
    case C.SPARE_PART_CREATE_NEXT_REQUEST:
    case C.SPARE_PART_EDIT_REQUEST:
    case C.INCIDENT_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case C.FAULTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        faults: payload.results,
        totalItems: payload.totalItems,
      };
    case C.SPARE_PARTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        spareParts: payload.results,
        totalItems: payload.totalItems,
      };
    case C.INCIDENTS_RESET:
      return {
        ...state,
        incidents: [],
        incident: {},
      };
    case C.INCIDENTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        incidents: payload.results,
        totalItems: payload.totalItems,
      };
    case C.FAULT_CREATE_SUCCESS:
    case C.FAULT_DETAIL_SUCCESS:
    case C.FAULT_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        fault: payload,
      };
    case C.SPARE_PART_CREATE_SUCCESS:
    case C.SPARE_PART_DETAIL_SUCCESS:
    case C.SPARE_PART_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        sparePart: payload,
      };
    case C.INCIDENT_CREATE_SUCCESS:
    case C.INCIDENT_DETAIL_SUCCESS:
    case C.INCIDENT_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        incident: payload,
      };
    case C.FAULTS_LIST_FAILURE:
    case C.FAULT_DETAIL_FAILURE:
    case C.FAULT_CREATE_FAILURE:
    case C.INCIDENT_CREATE_FAILURE:
    case C.INCIDENT_DETAIL_FAILURE:
    case C.INCIDENTS_LIST_FAILURE:
    case C.FAULT_EDIT_FAILURE:
    case C.SPARE_PARTS_LIST_FAILURE:
    case C.SPARE_PART_DETAIL_FAILURE:
    case C.SPARE_PART_CREATE_FAILURE:
    case C.SPARE_PART_EDIT_FAILURE:
    case C.INCIDENT_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
