import Api from '../../../api';
import Utils from '../../../helpers/cleanEmptyData';

class MachinesRepository extends Api {
  constructor() {
    super();
    this.api.defaults.baseURL += 'machines';
  }

  typelist = async () => this.api.get('/types/all');

  machineClientRemove = async (id) => this.api.delete(`/${id}/client`);

  machineReplace = async ({
    originMachineId,
    destinationMachineId,
    cause = '',
  }) =>
    this.api.post(`/${originMachineId}/replace`, {
      destinationMachineId,
      cause,
    });

  listMachines = async (data) =>
    this.api.get(`/filter${this.cleanParams(data)}`);

  getMachine = async (id) => this.api.get(`/${id}`);

  getMachineTypesToFilter = async (value) => {
    if (value) {
      return this.api.get(`/types/selectable/${value}`);
    }
  };

  createMachine = async ({
    number,
    modelId,
    typeId,
    clientId,
    location,
    remarks,
    purchaseDate,
    serialNumber,
    counter,
  }) =>
    this.api.post('', {
      number,
      modelId,
      typeId,
      clientId,
      location,
      remarks,
      purchaseDate,
      serialNumber,
      counter,
    });

  getMachineToEdit = async (id) => {
    if (!id) return null;
    return this.getMachine({ id });
  };

  editMachine = async (data) => {
    const { id } = data;
    return this.api.put(`/${id}`, data);
  };

  getArticlesByMachineId = async (payload) =>
    this.api.get(`/${payload}/articles`);

  addArticleOnMachineByArticleId = async (payload) => {
    const { machineId, articleId, selectionNumber } = payload;
    return this.api.put(`/${machineId}/articles`, {
      articleId,
      selectionNumber,
    });
  };

  removeArticleOnMachineBySelectionNumber = async (payload) => {
    const { machineId, selectionNumber } = payload;
    return this.api.delete(`/${machineId}/selectionNumber/${selectionNumber}`);
  };

  createMachineBrand = async ({ name }) => this.api.post('/brands', { name });

  editMachineBrand = async ({ id, name }) =>
    this.api.put(`/brands/${id}`, { name });

  getMachineBrand = async ({ brandId }) => this.api.get(`/brands/${brandId}`);

  filterMachineBrand = async ({ name, pageLimit = 20, pageNumber = 1 }) => {
    const params = { pageLimit, pageNumber, name };
    return this.api.get(`/brands/filter${this.cleanParams(params)}`);
  };

  createMachineModel = async (data) => this.api.post('/models', data);

  editMachineModel = async (data) => this.api.put(`/models/${data.id}`, data);

  getMachineModel = async ({ modelId }) => this.api.get(`/models/${modelId}`);

  filterMachineModels = async ({ pageLimit = 20, pageNumber = 1, name }) => {
    const params = { pageLimit, pageNumber, name };
    return this.api.get(`/models/filter${this.cleanParams(params)}`);
  };

  getMachineModelsToFilter = async (payload) =>
    this.api.get(`/models/selectable/${payload}`);

  moveMachinesBulk = async (payload) => this.api.post('/bulk', payload);

  getMachineServices = async ({ from, to, machineId }) =>
    this.api.get(`/${machineId}/services?from=${from}&to=${to}`);

  editMachineRoute = async ({ schedule, machineId }) =>
    this.api.put(`/${machineId}/schedule`, Utils.clean(schedule));

  getMachineDataByNumber = async ({ payload }) =>
    this.api.get(`/number/${payload}`);

  removeAllMachinesArticles = async (payload) =>
    this.api.delete(`/${payload}/articles`);

  removeRoute = async (payload) => this.api.delete(`/${payload}/schedule`);

  machineChangeStatus = async ({ machineId, status }) =>
    this.api.put(`/${machineId}/status`, { status });
}
export default new MachinesRepository();
