export const ROUTES_REQUEST = '[ROUTES] ROUTES_REQUEST';
export const ROUTES_SUCCESS = '[ROUTES] ROUTES_SUCCESS';
export const ROUTES_FAILURE = '[ROUTES] ROUTES_FAILURE';
export const ROUTES_LIST_REQUEST = '[ROUTES] ROUTES_LIST_REQUEST';
export const ROUTES_LIST_SUCCESS = '[ROUTES] ROUTES_LIST_SUCCESS';
export const ROUTES_LIST_FAILURE = '[ROUTES] ROUTES_LIST_FAILURE';
export const ROUTE_DETAIL_REQUEST = '[ROUTES] ROUTE_DETAIL_REQUEST';
export const ROUTE_DETAIL_SUCCESS = '[ROUTES] ROUTE_DETAIL_SUCCESS';
export const ROUTE_DETAIL_FAILURE = '[ROUTES] ROUTE_DETAIL_FAILURE';
export const GET_TODAY_ROUTES_REQUEST = '[ROUTES] GET_TODAY_ROUTES_REQUEST';
export const GET_TODAY_ROUTES_SUCCESS = '[ROUTES] GET_TODAY_ROUTES_SUCCESS';
export const GET_TODAY_ROUTES_FAILURE = '[ROUTES] GET_TODAY_ROUTES_FAILURE';

export const ROUTES_SCHEDULE_NAME_FILTER =
  '[SCHEDULES] ROUTES_SCHEDULE_NAME_FILTER';
export const ROUTES_SCHEDULE_NUMBER_FILTER =
  '[SCHEDULES] ROUTES_SCHEDULE_NUMBER_FILTER';

export const ROUTES_SCHEDULES_LIST_REQUEST =
  '[SCHEDULES] ROUTES_SCHEDULES_LIST_REQUEST';
export const ROUTES_SCHEDULES_LIST_SUCCESS =
  '[SCHEDULES] ROUTES_SCHEDULES_LIST_SUCCESS';
export const ROUTES_SCHEDULES_LIST_FAILURE =
  '[SCHEDULES] ROUTES_SCHEDULES_LIST_FAILURE';

export const ROUTES_SCHEDULES_LIST_SELECTABLE_REQUEST =
  '[SCHEDULES] ROUTES_SCHEDULES_LIST_SELECTABLE_REQUEST';
export const ROUTES_SCHEDULES_LIST_SELECTABLE_SUCCESS =
  '[SCHEDULES] ROUTES_SCHEDULES_LIST_SELECTABLE_SUCCESS';
export const ROUTES_SCHEDULES_LIST_SELECTABLE_FAILURE =
  '[SCHEDULES] ROUTES_SCHEDULES_LIST_SELECTABLE_FAILURE';

export const SCHEDULE_DETAIL_REQUEST = '[SCHEDULES] SCHEDULE_DETAIL_REQUEST';
export const SCHEDULE_DETAIL_SUCCESS = '[SCHEDULES] SCHEDULE_DETAIL_SUCCESS';
export const SCHEDULE_DETAIL_FAILURE = '[SCHEDULES] SCHEDULE_DETAIL_FAILURE';

export const ROUTES_SCHEDULES_AVAILABLE_TYPES_REQUEST =
  '[SCHEDULES] ROUTES_SCHEDULES_AVAILABLE_TYPES_REQUEST';
export const ROUTES_SCHEDULES_AVAILABLE_TYPES_SUCCESS =
  '[SCHEDULES] ROUTES_SCHEDULES_AVAILABLE_TYPES_SUCCESS';
export const ROUTES_SCHEDULES_AVAILABLE_TYPES_FAILURE =
  '[SCHEDULES] ROUTES_SCHEDULES_AVAILABLE_TYPES_FAILURE';

export const ROUTES_SCHEDULES_STATS_TABLE =
  '[SCHEDULES] ROUTES_SCHEDULES_STATS_TABLE';

export const ROUTES_LOADING_FALSE = '[SCHEDULES] ROUTES_LOADING_FALSE';

export const ROUTES_SCHEDULES_EDIT_REQUEST =
  '[SCHEDULES] ROUTES_SCHEDULES_EDIT_REQUEST';
export const ROUTES_SCHEDULES_EDIT_SUCCESS =
  '[SCHEDULES] ROUTES_SCHEDULES_EDIT_SUCCESS';
export const ROUTES_SCHEDULES_EDIT_FAILURE =
  '[SCHEDULES] ROUTES_SCHEDULES_EDIT_FAILURE';

export const ROUTES_SCHEDULES_CREATE_REQUEST =
  '[SCHEDULES] ROUTES_SCHEDULES_CREATE_REQUEST';
export const ROUTES_SCHEDULES_CREATE_SUCCESS =
  '[SCHEDULES] ROUTES_SCHEDULES_CREATE_SUCCESS';
export const ROUTES_SCHEDULES_CREATE_FAILURE =
  '[SCHEDULES] ROUTES_SCHEDULES_CREATE_FAILURE';

export const ROUTES_SCHEDULES_TEMPLATES_EDIT_REQUEST =
  '[SCHEDULES] ROUTES_SCHEDULES_TEMPLATES_EDIT_REQUEST';
export const ROUTES_SCHEDULES_TEMPLATES_EDIT_SUCCESS =
  '[SCHEDULES] ROUTES_SCHEDULES_TEMPLATES_EDIT_SUCCESS';
export const ROUTES_SCHEDULES_TEMPLATES_EDIT_FAILURE =
  '[SCHEDULES] ROUTES_SCHEDULES_TEMPLATES_EDIT_FAILURE';

export const ROUTES_SCHEDULES_TEMPLATES_LIST_REQUEST =
  '[SCHEDULES] ROUTES_SCHEDULES_TEMPLATES_LIST_REQUEST';
export const ROUTES_SCHEDULES_TEMPLATES_LIST_SUCCESS =
  '[SCHEDULES] ROUTES_SCHEDULES_TEMPLATES_LIST_SUCCESS';
export const ROUTES_SCHEDULES_TEMPLATES_LIST_FAILURE =
  '[SCHEDULES] ROUTES_SCHEDULES_TEMPLATES_LIST_FAILURE';

export const ROUTES_SCHEDULES_TEMPLATES_REMOVE_REQUEST =
  '[SCHEDULES] ROUTES_SCHEDULES_TEMPLATES_REMOVE_REQUEST';
export const ROUTES_SCHEDULES_TEMPLATES_REMOVE_SUCCESS =
  '[SCHEDULES] ROUTES_SCHEDULES_TEMPLATES_REMOVE_SUCCESS';
export const ROUTES_SCHEDULES_TEMPLATES_REMOVE_FAILURE =
  '[SCHEDULES] ROUTES_SCHEDULES_TEMPLATES_REMOVE_FAILURE';

export const ROUTES_SCHEDULES_MOVE_MACHINE_REQUEST =
  '[SCHEDULES] ROUTES_SCHEDULES_MOVE_MACHINE_REQUEST';
export const ROUTES_SCHEDULES_MOVE_MACHINE_SUCCESS =
  '[SCHEDULES] ROUTES_SCHEDULES_MOVE_MACHINE_SUCCESS';
export const ROUTES_SCHEDULES_MOVE_MACHINE_FAILURE =
  '[SCHEDULES] ROUTES_SCHEDULES_MOVE_MACHINE_FAILURE';

export const ROUTES_SCHEDULES_MODAL_REQUEST =
  '[SCHEDULES] ROUTES_SCHEDULES_MODAL_REQUEST';
export const ROUTES_SCHEDULES_MODAL_SUCCESS =
  '[SCHEDULES] ROUTES_SCHEDULES_MODAL_SUCCESS';
export const ROUTES_SCHEDULES_MODAL_FAILURE =
  '[SCHEDULES] ROUTES_SCHEDULES_MODAL_FAILURE';

export const ROUTES_SCHEDULES_MACHINE_DETAILS_MODAL_REQUEST =
  '[SCHEDULES] ROUTES_SCHEDULES_MACHINE_DETAILS_MODAL_REQUEST';
export const ROUTES_SCHEDULES_MACHINE_DETAILS_MODAL_SUCCESS =
  '[SCHEDULES] ROUTES_SCHEDULES_MACHINE_DETAILS_MODAL_SUCCESS';
export const ROUTES_SCHEDULES_MACHINE_DETAILS_MODAL_FAILURE =
  '[SCHEDULES] ROUTES_SCHEDULES_MACHINE_DETAILS_MODAL_FAILURE';

export const ROUTES_SCHEDULES_PDF_REQUEST =
  '[SCHEDULES PRINT PDF] ROUTES_SCHEDULES_PDF_REQUEST';
export const ROUTES_SCHEDULES_PDF_SUCCESS =
  '[SCHEDULES PRINT PDF] ROUTES_SCHEDULES_PDF_SUCCESS';
export const ROUTES_SCHEDULES_PDF_FAILURE =
  '[SCHEDULES PRINT PDF] ROUTES_SCHEDULES_PDF_FAILURE';

export const ROUTES_FINISH_REQUEST = '[SCHEDULES DETAIL] ROUTES_FINISH_REQUEST';
export const ROUTES_FINISH_SUCCESS = '[SCHEDULES DETAIL] ROUTES_FINISH_SUCCESS';
export const ROUTES_FINISH_FAILURE = '[SCHEDULES DETAIL] ROUTES_FINISH_FAILURE';

export const ROUTES_STATS_REQUEST = '[ROUTES STATS] ROUTES_STATS_REQUEST';
export const ROUTES_STATS_SUCCESS = '[ROUTES STATS] ROUTES_STATS_SUCCESS';
export const ROUTES_STATS_FAILURE = '[ROUTES STATS] ROUTES_STATS_FAILURE';

export const MACHINE_TYPES_REQUEST = '[MACHINE TYPES] MACHINE_TYPES_REQUEST';
export const MACHINE_TYPES_SUCCESS = '[MACHINE TYPES] MACHINE_TYPES_SUCCESS';
export const MACHINE_TYPES_FAILURE = '[MACHINE TYPES] MACHINE_TYPES_FAILURE';
