import VpnKeyIcon from '@material-ui/icons/VpnKey';

export default [
  {
    anchor: 'config/apikey/',
    id: 'apiKey',
    icon: <VpnKeyIcon />,
    literal: 'API_KEY',
    oldItem: false,
    protect: true,
    role: 'ROLE_SUPER_ADMIN',
  },
];
