/* eslint-disable no-restricted-syntax */
import axios from 'axios';

import getCookie from '../helpers/getCookie';

class ApiPHP {
  constructor() {
    const header = document.cookie.includes('jwt')
      ? {
          Authorization: `Bearer ${getCookie('jwt')}`,
        }
      : null;
    const axiosInstance = axios.create({
      baseURL: `${process.env.API_PHP}api/`,
      headers: header,
    });
    axiosInstance.interceptors.response.use(
      (response) => response.data,
      (error) => {
        if (error.response.status === 401) {
          // eslint-disable-next-line no-param-reassign
          error.message = 'INVALID TOKEN';
          window.location.replace('/login');
          return Promise.reject(error);
        }
        return error;
      }
    );

    this.ApiPHP = axiosInstance;
  }
}

export default ApiPHP;
