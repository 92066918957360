import Api from '../../../api';

class ArticlesRepository extends Api {
  constructor() {
    super();
    this.api.defaults.baseURL += 'articles';
  }

  listArticles = ({
    pageNumber = 1,
    pageLimit = 20,
    number = undefined,
    name = undefined,
    unitsPerPack = undefined,
  }) => {
    const params = {
      pageNumber,
      pageLimit,
      number,
      name,
      unitsPerPack,
    };
    return this.api.get(`/filter${this.cleanParams(params)}`);
  };

  getArticle = (id) => this.api.get(`/${id}`);

  createArticle = (data) => this.api.post('', data);

  getArticleToEdit = (id) => {
    if (!id) return null;
    return this.getArticle(id);
  };

  getArticleToFilter = ({
    pageNumber = 1,
    pageLimit = 20,
    number = undefined,
    name = undefined,
    unitsPerPack = undefined,
  }) => {
    const params = {
      pageNumber,
      pageLimit,
      number,
      name,
      unitsPerPack,
    };
    return this.api.get(`/filter${this.cleanParams(params)}`);
  };

  editArticle = (data) => {
    const { id } = data;
    return this.api.put(`/${id}`, data);
  };

  listPrices = (data) =>
    this.api.get(`/prices/filter${this.cleanParams(data)}`);

  createPrice = (data) => this.api.post('/prices', data);

  getPrice = (id) => this.api.get(`/prices/${id}`);

  editPrice = (data) => {
    const { id } = data;
    return this.api.put(`/prices/${id}`, data);
  };

  deletePrice = (id) => this.api.delete(`prices/${id}`);

  createClientPrice = (data) => {
    const { clientId } = data;
    return this.api.post(`/prices/clients/${clientId}`, data);
  };

  getClientPrice = (id) => this.api.get(`prices/clients/${id}`);

  editClientPrice = (data) => {
    const { clientId } = data[0];
    return this.api.put(`/prices/clients/${clientId}/bulk`, data);
  };

  deleteClientPrice = (data) => {
    const { clientId, articleId } = data;
    return this.api.delete(`/prices/clients/${clientId}/${articleId}`);
  };

  createMachinePrice = (data) => {
    const { machineId } = data;
    return this.api.post(`/prices/machines/${machineId}`, data);
  };

  getMachinePrice = (id) => this.api.get(`prices/machines/${id}`);

  editMachinePrice = (data) => {
    const { machineId } = data[0];
    return this.api.put(`/prices/machines/${machineId}/bulk`, data);
  };

  deleteMachinePrice = (data) => {
    const { machineId, articleId } = data;
    return this.api.delete(`/prices/machines/${machineId}/${articleId}`);
  };
}
export default new ArticlesRepository();
