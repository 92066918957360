import * as C from './machineList.constants';

export const reset = () => ({
  type: C.RESET,
});

export const machineListRequest = (payload) => ({
  type: C.MACHINE_LIST_REQUEST,
  payload,
});
export const machineListSuccess = (payload) => ({
  type: C.MACHINE_LIST_SUCCESS,
  payload,
});
export const machineListFailure = (payload) => ({
  type: C.MACHINE_LIST_FAILURE,
  payload,
});
export const getMachineByNumberRequest = (payload) => ({
  type: C.GET_MACHINE_BY_NUMBER_REQUEST,
  payload,
});
export const getMachineByNumberSuccess = (payload) => ({
  type: C.GET_MACHINE_BY_NUMBER_SUCCESS,
  payload,
});
export const getMachineByNumberFailure = (payload) => ({
  type: C.GET_MACHINE_BY_NUMBER_FAILURE,
  payload,
});

export const machineNumberFilter = (payload) => ({
  type: C.MACHINE_NUMBER_FILTER,
  payload,
});

export const machineTypeFilter = (payload) => ({
  type: C.MACHINE_TYPE_FILTER,
  payload,
});

export const machineClientFilter = (payload) => ({
  type: C.MACHINE_CLIENT_FILTER,
  payload,
});

export const machineModelFilter = (payload) => ({
  type: C.MACHINE_MODEL_FILTER,
  payload,
});

export const machineModelFilterRequest = (payload) => ({
  type: C.MACHINE_MODEL_FILTER_REQUEST,
  payload,
});
export const machineModelFilterSuccess = (payload) => ({
  type: C.MACHINE_MODEL_FILTER_SUCCESS,
  payload,
});
export const machineModelFilterFailure = (payload) => ({
  type: C.MACHINE_MODEL_FILTER_FAILURE,
  payload,
});

export const machineTypeFilterRequest = (payload) => ({
  type: C.MACHINE_TYPE_FILTER_REQUEST,
  payload,
});
export const machineTypeFilterSuccess = (payload) => ({
  type: C.MACHINE_TYPE_FILTER_SUCCESS,
  payload,
});
export const machineTypeFilterFailure = (payload) => ({
  type: C.MACHINE_TYPE_FILTER_FAILURE,
  payload,
});

export const machineClientFilterRequest = (payload) => ({
  type: C.MACHINE_CLIENT_FILTER_REQUEST,
  payload,
});
export const machineClientFilterSuccess = (payload) => ({
  type: C.MACHINE_CLIENT_FILTER_SUCCESS,
  payload,
});
export const machineClientFilterFailure = (payload) => ({
  type: C.MACHINE_CLIENT_FILTER_FAILURE,
  payload,
});

export const machineDetailsRequest = (payload) => ({
  type: C.MACHINE_DETAIL_REQUEST,
  payload,
});
export const machineDetailsSuccess = (payload) => ({
  type: C.MACHINE_DETAIL_SUCCESS,
  payload,
});
export const machineDetailsFailure = (payload) => ({
  type: C.MACHINE_DETAIL_FAILURE,
  payload,
});

export const machineEditRequest = (payload) => ({
  type: C.MACHINE_EDIT_REQUEST,
  payload,
});
export const machineEditSuccess = (payload) => ({
  type: C.MACHINE_EDIT_SUCCESS,
  payload,
});
export const machineEditFailure = (payload) => ({
  type: C.MACHINE_EDIT_FAILURE,
  payload,
});

export const machineNewRequest = (payload) => ({
  type: C.MACHINE_NEW_REQUEST,
  payload,
});
export const machineNewSuccess = (payload) => ({
  type: C.MACHINE_NEW_SUCCESS,
  payload,
});
export const machineNewFailure = (payload) => ({
  type: C.MACHINE_NEW_FAILURE,
  payload,
});

export const machineClientDetailsRequest = (payload) => ({
  type: C.MACHINE_CLIENT_DETAILS_REQUEST,
  payload,
});
export const machineClientDetailsSuccess = (payload) => ({
  type: C.MACHINE_CLIENT_DETAILS_SUCCESS,
  payload,
});
export const machineClientDetailsFailure = (payload) => ({
  type: C.MACHINE_CLIENT_DETAILS_FAILURE,
  payload,
});

export const machineArticlesListRequest = (payload) => ({
  type: C.MACHINE_ARTICLES_LIST_REQUEST,
  payload,
});
export const machineArticlesListSuccess = (payload) => ({
  type: C.MACHINE_ARTICLES_LIST_SUCCESS,
  payload,
});
export const machineArticlesListFailure = (payload) => ({
  type: C.MACHINE_ARTICLES_LIST_FAILURE,
  payload,
});

export const machineArticlesRemoveRequest = (payload) => ({
  type: C.MACHINE_ARTICLES_REMOVE_REQUEST,
  payload,
});
export const machineArticlesRemoveSuccess = (payload) => ({
  type: C.MACHINE_ARTICLES_REMOVE_SUCCESS,
  payload,
});
export const machineArticlesRemoveFailure = (payload) => ({
  type: C.MACHINE_ARTICLES_REMOVE_FAILURE,
  payload,
});

export const machineArticlesFilter = (payload) => ({
  type: C.MACHINE_ARTICLES_FILTER,
  payload,
});

export const articlesListRequest = (payload) => ({
  type: C.ARTICLES_LIST_REQUEST,
  payload,
});
export const articlesListSuccess = (payload) => ({
  type: C.ARTICLES_LIST_SUCCESS,
  payload,
});
export const articlesListFailure = (payload) => ({
  type: C.ARTICLES_LIST_FAILURE,
  payload,
});

export const articlesAddRequest = (payload) => ({
  type: C.ARTICLES_ADD_REQUEST,
  payload,
});

export const articlesAddSuccess = (payload) => ({
  type: C.ARTICLES_ADD_SUCCESS,
  payload,
});

export const articlesAddFailure = (payload) => ({
  type: C.ARTICLES_ADD_FAILURE,
  payload,
});

export const machineBrandDataRequest = (payload) => ({
  type: C.MACHINE_BRAND_DATA_REQUEST,
  payload,
});

export const machineBrandDataSuccess = (payload) => ({
  type: C.MACHINE_BRAND_DATA_SUCCESS,
  payload,
});

export const machineBrandDataFailure = (payload) => ({
  type: C.MACHINE_BRAND_DATA_FAILURE,
  payload,
});

export const machineBrandsListRequest = (payload) => ({
  type: C.MACHINE_BRANDS_LIST_REQUEST,
  payload,
});

export const machineBrandsListSuccess = (payload) => ({
  type: C.MACHINE_BRANDS_LIST_SUCCESS,
  payload,
});

export const machineBrandsListFailure = (payload) => ({
  type: C.MACHINE_BRANDS_LIST_FAILURE,
  payload,
});

export const machineBrandEditRequest = (payload) => ({
  type: C.MACHINE_BRAND_EDIT_REQUEST,
  payload,
});

export const machineBrandEditSuccess = (payload) => ({
  type: C.MACHINE_BRAND_EDIT_SUCCESS,
  payload,
});
export const machineBrandEditFailure = (payload) => ({
  type: C.MACHINE_BRAND_EDIT_FAILURE,
  payload,
});

export const machineBrandNewRequest = (payload) => ({
  type: C.MACHINE_BRAND_NEW_REQUEST,
  payload,
});
export const machineBrandNewSuccess = (payload) => ({
  type: C.MACHINE_BRAND_NEW_SUCCESS,
  payload,
});
export const machineBrandNewFailure = (payload) => ({
  type: C.MACHINE_BRAND_NEW_FAILURE,
  payload,
});

export const machineModelDataRequest = (payload) => ({
  type: C.MACHINE_MODEL_DATA_REQUEST,
  payload,
});

export const machineModelDataSuccess = (payload) => ({
  type: C.MACHINE_MODEL_DATA_SUCCESS,
  payload,
});

export const machineModelDataFailure = (payload) => ({
  type: C.MACHINE_MODEL_DATA_FAILURE,
  payload,
});

export const machineModelsListRequest = (payload) => ({
  type: C.MACHINE_MODELS_LIST_REQUEST,
  payload,
});

export const machineModelsListSuccess = (payload) => ({
  type: C.MACHINE_MODELS_LIST_SUCCESS,
  payload,
});

export const machineModelsListFailure = (payload) => ({
  type: C.MACHINE_MODELS_LIST_FAILURE,
  payload,
});

export const machineModelEditRequest = (payload) => ({
  type: C.MACHINE_MODEL_EDIT_REQUEST,
  payload,
});
export const machineModelEditSuccess = (payload) => ({
  type: C.MACHINE_MODEL_EDIT_SUCCESS,
  payload,
});
export const machineModelEditFailure = (payload) => ({
  type: C.MACHINE_MODEL_EDIT_FAILURE,
  payload,
});

export const machineModelNewRequest = (payload) => ({
  type: C.MACHINE_MODEL_NEW_REQUEST,
  payload,
});
export const machineModelNewSuccess = (payload) => ({
  type: C.MACHINE_MODEL_NEW_SUCCESS,
  payload,
});
export const machineModelNewFailure = (payload) => ({
  type: C.MACHINE_MODEL_NEW_FAILURE,
  payload,
});

export const machineReplaceRequest = (payload) => ({
  type: C.MACHINE_REPLACE_REQUEST,
  payload,
});
export const machineReplaceSuccess = (payload) => ({
  type: C.MACHINE_REPLACE_SUCCESS,
  payload,
});
export const machineReplaceFailure = (payload) => ({
  type: C.MACHINE_REPLACE_FAILURE,
  payload,
});

export const machineDeleteClientRequest = (payload) => ({
  type: C.MACHINE_DELETE_CLIENT_REQUEST,
  payload,
});
export const machineDeleteClientSuccess = (payload) => ({
  type: C.MACHINE_DELETE_CLIENT_SUCCESS,
  payload,
});
export const machineDeleteClientFailure = (payload) => ({
  type: C.MACHINE_DELETE_CLIENT_FAILURE,
  payload,
});

export const machineLocalFilterRequest = (payload) => ({
  type: C.MACHINE_LOCAL_FILTER_REQUEST,
  payload,
});
export const machineLocalFilterSuccess = (payload) => ({
  type: C.MACHINE_LOCAL_FILTER_SUCCESS,
  payload,
});
export const machineLocalFilterFailure = (payload) => ({
  type: C.MACHINE_LOCAL_FILTER_FAILURE,
  payload,
});

export const machineToReplaceFilterRequest = (payload) => ({
  type: C.MACHINE_TO_REPLACE_FILTER_REQUEST,
  payload,
});
export const machineToReplaceFilterSuccess = (payload) => ({
  type: C.MACHINE_TO_REPLACE_FILTER_SUCCESS,
  payload,
});
export const machineToReplaceFilterFailure = (payload) => ({
  type: C.MACHINE_TO_REPLACE_FILTER_FAILURE,
  payload,
});

export const getMachineServicesRequest = (payload) => ({
  type: C.GET_MACHINE_SERVICES_REQUEST,
  payload,
});
export const getMachineServicesSuccess = (payload) => ({
  type: C.GET_MACHINE_SERVICES_SUCCESS,
  payload,
});
export const getMachineServicesFailure = (payload) => ({
  type: C.GET_MACHINE_SERVICES_FAILURE,
  payload,
});

export const editMachineScheduleRequest = (payload) => ({
  type: C.MACHINE_EDIT_SCHEDULE_REQUEST,
  payload,
});
export const editMachineScheduleSuccess = (payload) => ({
  type: C.MACHINE_EDIT_SCHEDULE_SUCCESS,
  payload,
});
export const editMachineScheduleFailure = (payload) => ({
  type: C.MACHINE_EDIT_SCHEDULE_FAILURE,
  payload,
});

export const machineRemoveAllArticlesRequest = (payload) => ({
  type: C.MACHINE_REMOVE_ALL_ARTICLES_REQUEST,
  payload,
});
export const machineRemoveAllArticlesSuccess = (payload) => ({
  type: C.MACHINE_REMOVE_ALL_ARTICLES_SUCCESS,
  payload,
});
export const machineRemoveAllArticlesFailure = (payload) => ({
  type: C.MACHINE_REMOVE_ALL_ARTICLES_FAILURE,
  payload,
});

export const machineReplaceArticlesRequest = (payload) => ({
  type: C.MACHINE_REPLACE_ARTICLES_REQUEST,
  payload,
});
export const machineReplaceArticlesSuccess = (payload) => ({
  type: C.MACHINE_REPLACE_ARTICLES_SUCCESS,
  payload,
});
export const machineReplaceArticlesFailure = (payload) => ({
  type: C.MACHINE_REPLACE_ARTICLES_FAILURE,
  payload,
});

export const machineRemoveRouteRequest = (payload) => ({
  type: C.MACHINE_REMOVE_ROUTE_REQUEST,
  payload,
});
export const machineRemoveRouteSuccess = (payload) => ({
  type: C.MACHINE_REMOVE_ROUTE_SUCCESS,
  payload,
});
export const machineRemoveRouteFailure = (payload) => ({
  type: C.MACHINE_REMOVE_ROUTE_FAILURE,
  payload,
});

export const machineChangeStatusRequest = (payload) => ({
  type: C.MACHINE_CHANGE_STATUS_REQUEST,
  payload,
});
export const machineChangeStatusSuccess = (payload) => ({
  type: C.MACHINE_CHANGE_STATUS_SUCCESS,
  payload,
});
export const machineChangeStatusFailure = (payload) => ({
  type: C.MACHINE_CHANGE_STATUS_FAILURE,
  payload,
});
