import Alert from '@mui/material/Alert';
import SnackbarComponent from '@mui/material/Snackbar';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function Snackbar() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const snackbarProps = useRef();
  const buffer = useRef([]);
  const timeout = useRef();

  const handleNotification = (message, severity) => {
    if (!show) {
      snackbarProps.current = {
        message,
        severity,
      };

      setShow(true);
    } else {
      buffer.current = [...buffer.current, { message, severity }];
    }
  };

  useEffect(() => {
    if (!show && buffer.current.length) {
      const sendNotification = async () => {
        const notification = buffer.current[0];
        handleNotification(notification.message, notification.severity);
        buffer.current = buffer.current.splice(1);
      };
      sendNotification();
    }
    if (show) {
      timeout.current = setTimeout(() => {
        setShow(false);
      }, 3000);
    }
    window.Notification = {
      info: (msg) => {
        handleNotification(msg, 'info');
      },
      success: (msg) => {
        handleNotification(msg, 'success');
      },
      warning: (msg) => {
        handleNotification(msg, 'warning');
      },
      error: (msg) => {
        handleNotification(msg, 'error');
      },
    };
  }, [show]);

  return show ? (
    <SnackbarComponent
      open
      direction='right'
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ marginTop: '2.5rem' }}
    >
      <Alert variant='filled' severity={snackbarProps.current.severity}>
        {t(snackbarProps.current.message)}
      </Alert>
    </SnackbarComponent>
  ) : null;
}

export default Snackbar;
