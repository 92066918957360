import { lazy } from 'react';

const SchedulesTaskLanding = lazy(() =>
  import('../containers/SchedulesTaskLanding/SchedulesTaskLanding')
);
const SchedulesTaskDetails = lazy(() =>
  import('../containers/SchedulesTaskDetails/SchedulesTaskDetails')
);
const RecurrentSchedulesLanding = lazy(() =>
  import('../containers/SchedulesLanding/SchedulesLanding')
);
const RecurrentSchedulesDetails = lazy(() =>
  import('../containers/SchedulesDetails/SchedulesDetails')
);
const RecurrentSchedulesNewForm = lazy(() =>
  import('../containers/SchedulesNewForm/SchedulesNewForm')
);
const SchedulesTaskNew = lazy(() =>
  import('../containers/SchedulesTaskNew/SchedulesTaskNew')
);

export default [
  {
    path: '/scheduledTasks',
    component: SchedulesTaskLanding,
  },
  {
    path: '/scheduledTasks/new',
    component: SchedulesTaskNew,
  },
  {
    path: '/scheduledTasks/schedules',
    component: RecurrentSchedulesLanding,
  },
  {
    path: '/scheduledTasks/:id',
    component: SchedulesTaskDetails,
  },
  {
    path: '/scheduledTasks/schedules/new',
    component: RecurrentSchedulesNewForm,
  },
  {
    path: '/scheduledTasks/schedules/:scheduleId',
    component: RecurrentSchedulesDetails,
  },
];
