export const mockAvailabletypes = [
  {
    CHARGE: 20,
  },
  {
    SUPERVISION: 10,
  },
  {
    CHARGE_AND_RECOLLECT: 20,
  },
  {
    SUPERVISION_AND_RECOLLECT: 20,
  },
  {
    DOUBLE: 40,
  },
];

export const statsRowTable = (weeks) => {
  const array = [];
  for (let i = 1; i <= weeks * 7; i += 1) {
    array[i] = {
      totalTime: 0,
      recollect: 0,
      vigilancy: 0,
      totalMachines: 0,
    };
  }
  return array;
};

export const getVisits = (machine, weeks = 4) => {
  const schedule = [];
  if (!machine) {
    return [];
  }
  const visitStats = {
    visions: 0,
    charges: 0,
  };
  const { visits } = machine;
  for (let i = 1; i <= weeks * 7; i += 1) {
    if (visits.some((visit) => visit?.day === i)) {
      schedule[i] = {
        ...visits[
          visits
            .map((e) => {
              return e?.day;
            })
            .indexOf(i)
        ],
      };
    } else {
      schedule[i] = {
        day: undefined,
        type: undefined,
        edit: undefined,
      };
    }
  }
  if (machine?.model?.serviceCapacity) {
    const isCafeType = machine.machineType === 'Cafe';
    const { hasWaterTank } = machine;
    const { servicesPerCharge, servicesPerVisit } =
      machine.model.serviceCapacity;
    let calcPerCharge = 200;
    let calcPerVisits = 100;
    if (isCafeType && hasWaterTank) {
      if (servicesPerCharge <= calcPerCharge) calcPerCharge = servicesPerCharge;
      if (servicesPerCharge <= calcPerVisits) calcPerVisits = servicesPerVisit;
    } else {
      calcPerCharge = servicesPerCharge;
      calcPerVisits = servicesPerVisit;
    }
    machine.visits.forEach((visit) => {
      if (visit?.day && visit?.type) {
        const { type } = visit;
        switch (type) {
          case 'CHARGE':
            visitStats.charges += calcPerCharge;
            break;
          case 'SUPERVISION':
            visitStats.visions += calcPerVisits;
            break;
          case 'CHARGE_AND_RECOLLECT':
            visitStats.charges += calcPerCharge;
            break;
          case 'SUPERVISION_AND_RECOLLECT':
            visitStats.visions += calcPerVisits;
            break;
          case 'DOUBLE':
            visitStats.charges += calcPerCharge;
            visitStats.visions += calcPerVisits;
            break;
          default:
            break;
        }
      }
    });
  }
  const hasVisits = schedule?.some((visit) => !!visit?.type || false);
  return {
    ...machine,
    visits: schedule,
    visitStats: visitStats.charges + visitStats.visions,
    hasVisits,
  };
};

export const formatTemplateToVisits = (visits = []) => {
  const newVisits = [];
  for (let i = 1; i <= 4 * 7; i += 1) {
    if (visits.some((visit) => visit?.day === i)) {
      newVisits[i] = {
        ...visits[
          visits
            .map((e) => {
              return e?.day;
            })
            .indexOf(i)
        ],
        edit: undefined,
      };
    } else {
      newVisits[i] = {
        day: undefined,
        type: undefined,
        edit: undefined,
      };
    }
  }
  return newVisits;
};

export const getTemplateVisits = (machine, weeks = 4) => {
  const schedule = [];
  if (!machine) {
    return [];
  }
  const { visits } = machine;
  for (let i = 1; i <= weeks * 7; i += 1) {
    if (visits.some((visit) => visit?.day === i)) {
      schedule[i] = {
        ...visits[
          visits
            .map((e) => {
              return e?.day;
            })
            .indexOf(i)
        ],
        edit: undefined,
      };
    } else {
      schedule[i] = {
        day: undefined,
        type: undefined,
        edit: undefined,
      };
    }
  }
  return schedule;
};

export const formatTemplateVisits = (arrayData, weeks) => {
  const editSendData = [...arrayData];
  editSendData.forEach((template, templateIndex) => {
    const formatedVisit = getTemplateVisits(template, weeks);
    editSendData[templateIndex].visits = formatedVisit;
  });
  return editSendData;
};

export const recalcStatsTable = (schedules, visitTypes, weeks) => {
  const newStats = statsRowTable(weeks);
  if (Object.entries(schedules).length > 0 && schedules?.machineVisits) {
    schedules?.machineVisits.forEach((machine) => {
      machine.visits.forEach((visit) => {
        if (visit?.day && visit?.type) {
          const { day, type } = visit;
          switch (type) {
            case 'CHARGE':
              newStats[day].totalTime += visitTypes.CHARGE;
              newStats[day].recollect += 1;
              newStats[day].totalMachines += 1;
              break;
            case 'SUPERVISION':
              newStats[day].totalTime += visitTypes.SUPERVISION;
              newStats[day].totalMachines += 1;
              newStats[day].vigilancy += 1;
              break;
            case 'CHARGE_AND_RECOLLECT':
              newStats[day].totalTime += visitTypes.CHARGE_AND_RECOLLECT;
              newStats[day].totalMachines += 1;
              newStats[day].recollect += 1;
              break;
            case 'SUPERVISION_AND_RECOLLECT':
              newStats[day].totalTime += visitTypes.SUPERVISION_AND_RECOLLECT;
              newStats[day].totalMachines += 1;
              newStats[day].vigilancy += 1;
              break;
            case 'DOUBLE':
              newStats[day].totalTime += visitTypes.DOUBLE;
              newStats[day].recollect += 1;
              newStats[day].vigilancy += 1;
              newStats[day].totalMachines += 2;
              break;
            default:
              break;
          }
        }
      });
    });
  }
  newStats.forEach(({ totalTime }, idx) => {
    if (totalTime > 0) {
      // TODO: Falta la api añadir el tiempo de desayuno y carga
      newStats[idx].totalTime += schedules.drivingMinutes + 40 + 20;
    }
  });
  return newStats;
};

export const timeConvert = (n) => {
  const num = n;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  if (rminutes <= 9) {
    rminutes = `0${rminutes}`;
  }
  return `${rhours}:${rminutes}`;
};
