import * as C from './modal.constants';

export const tasksModalRequest = (payload) => ({
  type: C.TASKS_MODAL_REQUEST,
  payload,
});
export const tasksModalSuccess = (payload) => ({
  type: C.TASKS_MODAL_SUCCESS,
  payload,
});
export const tasksModalFailure = (payload) => ({
  type: C.TASKS_MODAL_FAILURE,
  payload,
});

export const tasksShowModal = (payload) => ({
  type: C.TASKS_SHOW_MODAL,
  payload,
});

export const closeModal = (payload) => ({
  type: C.CLOSE_MODAL,
  payload,
});

export const incidentsModalRequest = (payload) => ({
  type: C.INCIDENTS_MODAL_REQUEST,
  payload,
});
export const incidentsModalSuccess = (payload) => ({
  type: C.INCIDENTS_MODAL_SUCCESS,
  payload,
});
export const incidentsModalFailure = (payload) => ({
  type: C.INCIDENTS_MODAL_FAILURE,
  payload,
});

export const incidentsShowModal = (payload) => ({
  type: C.INCIDENTS_SHOW_MODAL,
  payload,
});
