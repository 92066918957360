import { call, put, takeLatest } from 'redux-saga/effects';

import RoutesRepository from '../../pages/tasks/api/api.routes';
import * as GlobalAction from '../global/actions/global.action';
import * as A from './tasks.action';
import * as C from './tasks.constants';

const calcArticlesFiltered = (response, id) => {
  const filteredArticles = {
    ...response,
    results: response.results.map((result) => {
      const addedArticles = result.replenishment?.addedArticles?.filter(
        (added) => added.articleId === id
      );
      const removedArticles = result.replenishment?.removedArticles.filter(
        (removed) => removed.articleId === id
      );
      return {
        ...result,
        replenishment: {
          addedArticles,
          removedArticles,
          user: result.replenishment?.user,
        },
      };
    }),
  };
  return filteredArticles;
};

function* tasksEffect({ payload }) {
  try {
    const { articleIds } = payload;
    let response = yield call(RoutesRepository.listTasks, payload);
    if (articleIds) {
      response = calcArticlesFiltered(response, articleIds);
    }
    yield put(A.tasksListSuccess(response));
  } catch (e) {
    yield put(A.tasksListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* taskEffect({ payload }) {
  try {
    const response = yield call(RoutesRepository.getTask, payload.taskId);
    yield put(A.taskSuccess(response));
  } catch (e) {
    yield put(A.taskFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* editTaskEffect({ payload }) {
  try {
    const response = yield call(RoutesRepository.editTask, payload);
    yield put(A.taskEditSuccess(response));
  } catch (e) {
    yield put(A.taskEditFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

export default function* routesSaga() {
  yield takeLatest(C.GET_TASK_REQUEST, taskEffect);
  yield takeLatest(C.EDIT_TASK_REQUEST, editTaskEffect);
  yield takeLatest(C.TASKS_REQUEST, tasksEffect);
}
