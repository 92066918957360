import { call, put, takeLatest } from 'redux-saga/effects';

import ReportsRepository from '../../pages/reports/api/api.reports';
import * as GlobalAction from '../global/actions/global.action';
import * as A from './reports.actions';
import * as C from './reports.constants';

function* getLandAndSurplusEffect({ payload }) {
  try {
    const response = yield call(ReportsRepository.getLackAndSurplus, payload);
    yield put(A.getLandAndSurplusSuccess(response));
  } catch (e) {
    yield put(A.getLandAndSurplusFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

export default function* incidentSagas() {
  yield takeLatest(C.GET_LACK_AND_SURPLUS_REQUEST, getLandAndSurplusEffect);
}
