import * as C from './users.constants';

export const usersListRequest = (payload) => ({
  type: C.USERS_LIST_REQUEST,
  payload,
});
export const usersListSuccess = (payload) => ({
  type: C.USERS_LIST_SUCCESS,
  payload,
});
export const usersListFailure = (payload) => ({
  type: C.USERS_LIST_FAILURE,
  payload,
});
export const usersAllListRequest = () => ({
  type: C.USERS_ALL_LIST_REQUEST,
});
export const usersAllListSuccess = (payload) => ({
  type: C.USERS_ALL_LIST_SUCCESS,
  payload,
});
export const usersAllListFailure = (payload) => ({
  type: C.USERS_ALL_LIST_FAILURE,
  payload,
});
