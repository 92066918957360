export const formatRouteToToday = (route) => {
  const newRouteTodayArrayObjects = [];
  route.map((data) => {
    const { isFinished, isStarted, tasks, user, name, number, day, id } = data;
    let status;
    if (isStarted) {
      status = 'IN_PROGRESS';
      if (isFinished) {
        status = 'FINISHED';
      }
    } else {
      status = 'NOT_STARTED';
    }

    const finishedTask = tasks
      .filter((task) => !!task.replenishment || task.solvedIn)
      .sort((a, b) => {
        a = new Date(a?.replenishment?.finishedAt);
        b = new Date(b?.replenishment?.finishedAt);
        // eslint-disable-next-line no-nested-ternary
        return a > b ? -1 : a < b ? 1 : 0;
      });
    const totalTasksCounter =
      tasks.filter((task) => task.visitType !== 'OUTSIDE_ROUTE').length || 0;

    const finishedTasksCounter =
      finishedTask.filter(
        (task) =>
          (task.replenishment || task.solvedIn) &&
          task.visitType !== 'OUTSIDE_ROUTE'
      ).length || 0;

    let lastUpdate = null;
    let lastClient = '';
    let percentageCompleted = 0;
    const outsideRouteCount =
      finishedTask.filter((task) => task.visitType === 'OUTSIDE_ROUTE')
        .length || 0;

    if (finishedTasksCounter > 0 || outsideRouteCount > 0) {
      lastUpdate = finishedTask[0]?.replenishment?.finishedAt;
      lastClient = finishedTask[0].machine.clientName;
      percentageCompleted = (100 * finishedTasksCounter) / totalTasksCounter;
      if (finishedTasksCounter === 0) {
        percentageCompleted = 100;
      }
    }

    let operator = '';
    if (isStarted) {
      operator = `${user.name} ${user.surname}`;
    }
    const task = {
      detailUrl: `/routes/${id}`,
      totalTasksCounter,
      percentageCompleted,
      outsideRouteCount,
      lastClient,
      name,
      lastUpdate,
      operator,
      finishedTasksCounter,
      status,
      number,
      day,
      tasks,
      routeId: tasks[0]?.routeId,
    };
    newRouteTodayArrayObjects.push(task);
    return null;
  });
  return newRouteTodayArrayObjects;
};

export const formatStatsRoute = (routes) => {
  const routesMap = [];
  routes.forEach((route) => {
    if (route.tasks) {
      route.tasks.forEach((task) => {
        const routeIndex = routesMap.findIndex(
          (e) =>
            e.number ===
            (task?.machine?.assignedSchedule?.number || task?.routeNumber)
        );
        const routeObject = {
          number: task?.machine?.assignedSchedule?.number || task?.routeNumber,
          name: task?.machine?.assignedSchedule?.name || task?.routeName,
          id: task?.machine?.assignedSchedule?.id || task?.routeId,
          replenishment: {
            total:
              task?.visitType !== 'OUTSIDE_ROUTE' &&
              (task?.replenishment || task.solvedIn)
                ? 1
                : 0,
            done:
              task?.visitType !== 'OUTSIDE_ROUTE' &&
              (task?.replenishment?.finishedAt || task.solvedIn)
                ? 1
                : 0,
            outside: task?.visitType === 'OUTSIDE_ROUTE' ? 1 : 0,
          },
          percentageCompleted:
            // eslint-disable-next-line no-unsafe-optional-chaining
            (100 * (task.replenishment?.finishedAt || task.solvedIn) ? 1 : 0) /
            task.replenishment
              ? 1
              : 0 || 0,
          services: task?.services,
          charges:
            task?.visitType.toUpperCase().includes('CHARGE') &&
            task?.replenishment
              ? 1
              : 0,
          visits:
            task?.visitType.toUpperCase().includes('SUPERVISION') &&
            task?.replenishment
              ? 1
              : 0,
        };
        if (routeIndex === -1) {
          routesMap.push(routeObject);
        } else {
          const replenishment = {
            total:
              routesMap[routeIndex].replenishment.total +
              (task?.visitType !== 'OUTSIDE_ROUTE' &&
              (task?.replenishment || task.solvedIn)
                ? 1
                : 0),
            done:
              routesMap[routeIndex].replenishment.done +
              (task?.visitType !== 'OUTSIDE_ROUTE' &&
              (task?.replenishment?.finishedAt || task.solvedIn)
                ? 1
                : 0),
            outside:
              routesMap[routeIndex].replenishment.outside +
              (task?.visitType === 'OUTSIDE_ROUTE' ? 1 : 0),
          };
          routesMap[routeIndex].replenishment = replenishment;
          routesMap[routeIndex].percentageCompleted =
            (100 * replenishment.done) / replenishment.total || 0;
          routesMap[routeIndex].services += task?.services || 0;
          routesMap[routeIndex].charges +=
            task?.visitType.toUpperCase().includes('CHARGE') &&
            task?.replenishment
              ? 1
              : 0;
          routesMap[routeIndex].visits +=
            task?.visitType.toUpperCase().includes('SUPERVISION') &&
            task?.replenishment
              ? 1
              : 0;
        }
      });
    }
  });

  const totalStats = {
    number: routesMap.length || 0,
    name: ' ',
    replenishment: `${routesMap.reduce(
      (acc, current) => acc + current.replenishment.done,
      0
    )} / ${routesMap.reduce(
      (acc, current) => acc + current.replenishment.total,
      0
    )} (${routesMap.reduce(
      (acc, current) => acc + current.replenishment.outside,
      0
    )})`,
    services: routesMap.reduce((acc, current) => acc + current.services, 0),
    charges: routesMap.reduce((acc, current) => acc + current.charges, 0),
    visits: routesMap.reduce((acc, current) => acc + current.visits, 0),
  };

  routesMap.sort((a, b) => a.number - b.number);

  return { data: routesMap, dataStats: routesMap.length ? totalStats : null };
};

export const formatStatsWorker = (list) => {
  const map = new Map();
  list.forEach((item) => {
    item.tasks?.forEach((task) => {
      if (task.replenishment) {
        const collection = map.get(task?.replenishment?.user?.userNumber);
        if (!collection) {
          map.set(task?.replenishment?.user?.userNumber, [task]);
        } else {
          collection.push(task);
        }
      }
    });
  });

  const newMap = Object.entries(Object.fromEntries(map)).map((client) => {
    const typeVisits = {};
    const getTypeVisits = (task) => {
      if (task.replenishment && task.machine?.type) {
        const { type } = task.machine;
        if (typeVisits[type]) {
          typeVisits[type] += 1;
        } else {
          typeVisits[type] = 1;
        }
      }
    };
    client[1].forEach((task) => {
      getTypeVisits(task);
    });
    return {
      number: Number(client[1][0].replenishment.user.userNumber),
      name: `${client[1][0].replenishment.user.name} ${client[1][0].replenishment.user.surname}`,
      replenishment: client[1].length,
      services: client[1].reduce((acc, current) => acc + current.services, 0),
      charges: client[1].reduce((acc, current) => {
        if (
          current?.visitType.toUpperCase().includes('CHARGE') &&
          current?.replenishment
        ) {
          return acc + 1;
        }
        return acc;
      }, 0),
      visits: client[1].reduce((acc, current) => {
        if (
          current?.visitType.toUpperCase().includes('SUPERVISION') &&
          current?.replenishment
        ) {
          return acc + 1;
        }
        return acc;
      }, 0),
      outsides: client[1].reduce((acc, current) => {
        if (
          current?.visitType.toUpperCase().includes('OUTSIDE') &&
          current?.replenishment
        ) {
          return acc + 1;
        }
        return acc;
      }, 0),
      extraData: [typeVisits],
    };
  });
  const typeVisitsStats = {};
  const getTypeVisitsStats = (worker) => {
    if (worker.extraData) {
      Object.keys(worker.extraData).forEach((type) => {
        if (typeVisitsStats[type]) {
          typeVisitsStats[type] += 1;
        } else {
          typeVisitsStats[type] = 1;
        }
      });
    }
  };
  newMap.forEach((data) => getTypeVisitsStats(data));
  const totalStats = {
    empty: '',
    number: newMap.length || 0,
    name: '',
    replenishment: newMap.reduce(
      (acc, current) => acc + current.replenishment,
      0
    ),
    services: newMap.reduce((acc, current) => acc + current.services, 0),
    charges: newMap.reduce((acc, current) => acc + current.charges, 0),
    visits: newMap.reduce((acc, current) => acc + current.visits, 0),
    outsides: newMap.reduce((acc, current) => acc + current.outsides, 0),
    extraData: [typeVisitsStats],
  };
  return { data: newMap, dataStats: newMap.length ? totalStats : null };
};

export const arrayToObject = (array, keyField, secondKeyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item[secondKeyField];
    return obj;
  }, {});
