import Api from '../../../api';

class MachineRouteRepository extends Api {
  constructor() {
    super();
    this.api.defaults.baseURL += 'routes';
  }

  createRoute = (data) => this.api.post('', data);

  addTask = (data) => {
    const { routeId } = data;
    return this.api.post(`/${routeId}/tasks`, data);
  };

  finishTask = (data) => {
    const { routeId, taskId } = data;
    return this.api.post(`/${routeId}/tasks/${taskId}/finish`, data);
  };

  addOutsideTask = (data) => {
    const { routeId } = data;
    return this.api.post(`/${routeId}/taskOutsideRoute`, data);
  };

  startRoute = (data) => {
    const { routeId, vehicle } = data;
    return this.api.post(`/${routeId}/start`, { vehicle });
  };

  finishRoute = (id) => {
    return this.api.post(`/${id}/finish`);
  };
}
export default new MachineRouteRepository();
