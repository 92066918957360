import * as C from './machineRoute.constants';

export const createRouteRequest = (payload) => ({
  type: C.CREATE_ROUTE_REQUEST,
  payload,
});
export const createRouteSuccess = (payload) => ({
  type: C.CREATE_ROUTE_SUCCESS,
  payload,
});
export const createRouteFailure = (payload) => ({
  type: C.CREATE_ROUTE_FAILURE,
  payload,
});

export const addTaskRequest = (payload) => ({
  type: C.ADD_TASK_REQUEST,
  payload,
});
export const addTaskSuccess = (payload) => ({
  type: C.ADD_TASK_SUCCESS,
  payload,
});
export const addTaskFailure = (payload) => ({
  type: C.ADD_TASK_FAILURE,
  payload,
});

export const finishTaskRequest = (payload) => ({
  type: C.FINISH_TASK_REQUEST,
  payload,
});
export const finishTaskSuccess = (payload) => ({
  type: C.FINISH_TASK_SUCCESS,
  payload,
});
export const finishTaskFailure = (payload) => ({
  type: C.FINISH_TASK_FAILURE,
  payload,
});

export const addOutsideTaskRequest = (payload) => ({
  type: C.ADD_OUTSIDE_TASK_REQUEST,
  payload,
});
export const addOutsideTaskSuccess = (payload) => ({
  type: C.ADD_OUTSIDE_TASK_SUCCESS,
  payload,
});
export const addOutsideTaskFailure = (payload) => ({
  type: C.ADD_OUTSIDE_TASK_FAILURE,
  payload,
});

export const startRouteRequest = (payload) => ({
  type: C.START_ROUTE_REQUEST,
  payload,
});
export const startRouteSuccess = (payload) => ({
  type: C.START_ROUTE_SUCCESS,
  payload,
});
export const startRouteFailure = (payload) => ({
  type: C.START_ROUTE_FAILURE,
  payload,
});

export const finishRouteRequest = (payload) => ({
  type: C.FINISH_ROUTE_REQUEST,
  payload,
});
export const finishRouteSuccess = (payload) => ({
  type: C.FINISH_ROUTE_SUCCESS,
  payload,
});
export const finishRouteFailure = (payload) => ({
  type: C.FINISH_ROUTE_FAILURE,
  payload,
});

export const getVehiclesRequest = () => ({
  type: C.GET_VEHICLES_REQUEST,
});
export const getVehiclesSuccess = (payload) => ({
  type: C.GET_VEHICLES_SUCCESS,
  payload,
});
export const getVehiclesFailure = (payload) => ({
  type: C.GET_VEHICLES_FAILURE,
  payload,
});
