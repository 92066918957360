import { call, put, takeLatest } from 'redux-saga/effects';

import * as GlobalAction from '../../../redux/global/actions/global.action';
import MachinesRepository from '../../machines/api/api.machines';
import RoutesRepository from '../api/api.routes';
import * as A from './routesList.action';
import * as C from './routesList.constants';

function* routesListEffect({ payload }) {
  try {
    const response = yield call(RoutesRepository.listRoutes, payload);
    yield put(A.routesListSuccess(response));
  } catch (e) {
    yield put(A.routesListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* routeDetailEffect({ payload }) {
  try {
    const response = yield call(RoutesRepository.getRoute, payload);
    yield put(A.routeDetailSuccess(response));
  } catch (e) {
    yield put(A.routeDetailFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* getTodayRoutes() {
  try {
    const response = yield call(RoutesRepository.getTodayRoutes);
    yield put(A.getTodayRoutesSuccess(response));
  } catch (e) {
    yield put(A.getTodayRoutesFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* routesEffect({ payload }) {
  try {
    const response = yield call(RoutesRepository.scheduleFilter, payload);
    yield put(A.routesSuccess(response));
  } catch (e) {
    yield put(A.routesFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* routesSchedulesListEffect({ payload }) {
  try {
    const availableTypes = yield call(RoutesRepository.getAvailableVisitTypes);
    yield put(A.routesSchedulesAvailableTypesSuccess(availableTypes));
    const schedules = yield call(RoutesRepository.scheduleFilter, payload);
    yield put(
      A.routesSchedulesListSuccess({ ...schedules, weeks: payload?.weeks })
    );
  } catch (e) {
    yield put(A.routesSchedulesListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* routesSchedulesListSelectableEffect({ payload }) {
  try {
    const availableTypes = yield call(RoutesRepository.getAvailableVisitTypes);
    yield put(A.routesSchedulesAvailableTypesSuccess(availableTypes));
    const schedules = yield call(RoutesRepository.scheduleFilter, payload);
    yield put(
      A.routesSchedulesListSuccess({ ...schedules, weeks: payload?.weeks })
    );
  } catch (e) {
    yield put(A.routesSchedulesListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* schedulesDetailsEffect({ payload }) {
  try {
    const availableTypes = yield call(RoutesRepository.getAvailableVisitTypes);
    yield put(A.routesSchedulesAvailableTypesSuccess(availableTypes));
    const schedule = yield call(RoutesRepository.getSchedule, payload);
    yield put(A.scheduleDetailSuccess(schedule));
  } catch (e) {
    yield put(A.scheduleDetailFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* routesSchedulesEditEffect({ payload }) {
  try {
    yield call(RoutesRepository.scheduleEdit, payload);
    yield put(A.routesSchedulesEditSuccess());
  } catch (e) {
    yield put(A.routesSchedulesEditFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* routesSchedulesCreateEffect({ payload }) {
  try {
    const response = yield call(RoutesRepository.scheduleCreate, payload);
    yield put(A.routesSchedulesCreateSuccess());
    yield put(GlobalAction.createRedirect(`/routes/schedule/${response.id}`));
  } catch (e) {
    yield put(A.routesSchedulesCreateFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* routesSchedulesAvailableTypesEffect() {
  try {
    const response = yield call(RoutesRepository.getAvailableVisitTypes);
    yield put(A.routesSchedulesAvailableTypesSuccess(response));
  } catch (e) {
    yield put(A.routesSchedulesAvailableTypesFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* routesSchedulesTemplatesListEffect() {
  try {
    const availableTypes = yield call(RoutesRepository.getAvailableVisitTypes);
    yield put(A.routesSchedulesAvailableTypesSuccess(availableTypes));
    const templates = yield call(RoutesRepository.getSchedulesTemplates);
    yield put(A.routesSchedulesTemplatesListSuccess(templates));
  } catch (e) {
    yield put(A.routesSchedulesTemplatesListFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* routesSchedulesTemplatesEditEffect({ payload }) {
  try {
    const templates = yield call(
      RoutesRepository.editSchedulesTemplates,
      payload
    );
    yield put(A.routesSchedulesTemplatesEditSuccess(templates));
  } catch (e) {
    yield put(A.routesSchedulesTemplatesEditFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* routesSchedulesTemplatesRemoveEffect({ payload }) {
  try {
    yield call(RoutesRepository.removeSchedulesTemplates, payload);
    yield put(A.routesSchedulesTemplatesRemoveSuccess(payload));
  } catch (e) {
    yield put(A.routesSchedulesTemplatesRemoveFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* routesSchedulesMoveMachineEffect({ payload }) {
  try {
    yield call(MachinesRepository.moveMachinesBulk, payload);
    yield put(A.routesSchedulesMoveMachineSuccess(payload));
  } catch (e) {
    yield put(A.routesSchedulesMoveMachineFailure(payload));
  }
}

function* routesSchedulesModalEffect({ scheduleId }) {
  try {
    const schedules = yield call(RoutesRepository.scheduleAll);
    yield put(A.routesSchedulesModalSuccess({ ...schedules, scheduleId }));
  } catch (e) {
    yield put(A.routesSchedulesModalFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* routesSchedulesMachineDetailsModalEffect({ payload }) {
  try {
    const machineDetails = yield call(MachinesRepository.getMachine, payload);
    yield put(A.routesSchedulesMachineDetailsModalSuccess({ machineDetails }));
  } catch (e) {
    yield put(A.routesSchedulesMachineDetailsModalFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* getSchedulesPdf({ payload }) {
  try {
    const response = yield call(RoutesRepository.getSchedulePDF(payload));
    yield put(A.routesSchedulesPdfSucces({ response }));
  } catch (e) {
    yield put(A.routesSchedulesPdfFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* routesScheduleFinish({ payload }) {
  try {
    const response = yield call(RoutesRepository.finishRoute(payload));
    yield put(A.routesFinishSuccess({ response }));
  } catch (e) {
    yield put(A.routesFinishFailure());
    yield put(GlobalAction.createError(e.message));
  }
}
function* machineTypesEffect({ payload }) {
  try {
    const response = yield call(
      MachinesRepository.getMachineTypesToFilter,
      payload
    );
    yield put(A.machineTypesSuccess(response));
  } catch (e) {
    yield put(A.machineTypesFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* routesStatsEffect({ payload }) {
  try {
    const response = yield call(RoutesRepository.listRoutes, payload);
    yield put(A.routesStatsSuccess(response));
  } catch (e) {
    yield put(A.routesStatsFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

export default function* routesSaga() {
  yield takeLatest(C.ROUTES_REQUEST, routesEffect);
  yield takeLatest(C.ROUTES_LIST_REQUEST, routesListEffect);
  yield takeLatest(C.ROUTE_DETAIL_REQUEST, routeDetailEffect);
  yield takeLatest(C.GET_TODAY_ROUTES_REQUEST, getTodayRoutes);
  yield takeLatest(C.SCHEDULE_DETAIL_REQUEST, schedulesDetailsEffect);
  yield takeLatest(C.ROUTES_SCHEDULES_LIST_REQUEST, routesSchedulesListEffect);
  yield takeLatest(
    C.ROUTES_SCHEDULES_LIST_SELECTABLE_REQUEST,
    routesSchedulesListSelectableEffect
  );
  yield takeLatest(
    C.ROUTES_SCHEDULES_CREATE_REQUEST,
    routesSchedulesCreateEffect
  );
  yield takeLatest(
    C.ROUTES_SCHEDULES_AVAILABLE_TYPES_REQUEST,
    routesSchedulesAvailableTypesEffect
  );
  yield takeLatest(C.ROUTES_SCHEDULES_EDIT_REQUEST, routesSchedulesEditEffect);
  yield takeLatest(
    C.ROUTES_SCHEDULES_TEMPLATES_EDIT_REQUEST,
    routesSchedulesTemplatesEditEffect
  );
  yield takeLatest(
    C.ROUTES_SCHEDULES_TEMPLATES_LIST_REQUEST,
    routesSchedulesTemplatesListEffect
  );
  yield takeLatest(
    C.ROUTES_SCHEDULES_TEMPLATES_REMOVE_REQUEST,
    routesSchedulesTemplatesRemoveEffect
  );
  yield takeLatest(
    C.ROUTES_SCHEDULES_MOVE_MACHINE_REQUEST,
    routesSchedulesMoveMachineEffect
  );
  yield takeLatest(
    C.ROUTES_SCHEDULES_MODAL_REQUEST,
    routesSchedulesModalEffect
  );
  yield takeLatest(
    C.ROUTES_SCHEDULES_MACHINE_DETAILS_MODAL_REQUEST,
    routesSchedulesMachineDetailsModalEffect
  );
  yield takeLatest(C.ROUTES_SCHEDULES_PDF_REQUEST, getSchedulesPdf);
  yield takeLatest(C.ROUTES_FINISH_REQUEST, routesScheduleFinish);
  yield takeLatest(C.ROUTES_STATS_REQUEST, routesStatsEffect);
  yield takeLatest(C.MACHINE_TYPES_REQUEST, machineTypesEffect);
}
