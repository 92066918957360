import * as C from './config.constants';

export const apiKeyListRequest = () => ({
  type: C.API_KEY_LIST_REQUEST,
});
export const apiKeyListSuccess = (payload) => ({
  type: C.API_KEY_LIST_SUCCESS,
  payload,
});
export const apiKeyListFailure = (payload) => ({
  type: C.API_KEY_LIST_FAILURE,
  payload,
});

export const apiKeyRevokeRequest = (payload) => ({
  type: C.API_KEY_REVOKE_REQUEST,
  payload,
});
export const apiKeyRevokeSuccess = (payload) => ({
  type: C.API_KEY_REVOKE_SUCCESS,
  payload,
});
export const apiKeyRevokeFailure = (payload) => ({
  type: C.API_KEY_REVOKE_FAILURE,
  payload,
});

export const apiKeyCreateRequest = (payload) => ({
  type: C.API_KEY_CREATE_REQUEST,
  payload,
});
export const apiKeyCreateSuccess = (payload) => ({
  type: C.API_KEY_CREATE_SUCCESS,
  payload,
});
export const apiKeyCreateFailure = (payload) => ({
  type: C.API_KEY_CREATE_FAILURE,
  payload,
});

export const setLoading = () => ({
  type: C.SET_LOADING,
});
