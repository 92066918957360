import { call, put, takeLatest } from 'redux-saga/effects';

import IncidentsRepository from '../../pages/incidents/api/api.incidents';
import RoutesRepository from '../../pages/tasks/api/api.routes';
import * as GlobalAction from '../global/actions/global.action';
import * as A from './modal.action';
import * as C from './modal.constants';

function* tasksModalEffect({ payload }) {
  try {
    const response = yield call(RoutesRepository.listTasks, payload);
    yield put(A.tasksModalSuccess(response));
  } catch (e) {
    yield put(A.tasksModalFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

function* incidentsModalEffect({ payload }) {
  try {
    const response = yield call(IncidentsRepository.listIncidents, payload);
    yield put(A.incidentsModalSuccess(response));
  } catch (e) {
    yield put(A.incidentsModalFailure());
    yield put(GlobalAction.createError(e.message));
  }
}

export default function* routesSaga() {
  yield takeLatest(C.TASKS_MODAL_REQUEST, tasksModalEffect);
  yield takeLatest(C.INCIDENTS_MODAL_REQUEST, incidentsModalEffect);
}
