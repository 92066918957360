import * as C from './tasks.constants';

export const initialState = {
  task: {},
  tasks: [],
  tasksModal: [],
  loading: true,
  loadingModal: true,
  tasksModalTotalItems: 0,
  totalItems: 0,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.GET_TASK_REQUEST:
    case C.TASKS_REQUEST:
    case C.EDIT_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case C.EDIT_TASK_SUCCESS:
    case C.GET_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        task: payload,
      };
    case C.TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        tasks: payload.results,
        totalItems: payload.totalItems,
      };
    case C.EDIT_TASK_FAILURE:
    case C.GET_TASK_FAILURE:
    case C.TASKS_FAILURE:
      return {
        ...state,
        loading: false,
        loadingModal: false,
      };
    default:
      return state;
  }
};
