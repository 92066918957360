export const ARTICLE_LIST_REQUEST = '[ARTICLES] ARTICLE_LIST_REQUEST';
export const ARTICLE_LIST_SUCCESS = '[ARTICLES] ARTICLE_LIST_SUCCESS';
export const ARTICLE_LIST_FAILURE = '[ARTICLES] ARTICLE_LIST_FAILURE';

export const ARTICLE_NUMBER_FILTER = '[ARTICLES] ARTICLE_NUMBER_FILTER';
export const ARTICLE_UNITS_PER_PACK_FILTER =
  '[ARTICLES] ARTICLE_UNITS_PER_PACK_FILTER';
export const ARTICLE_NAME_FILTER = '[ARTICLES] ARTICLE_NAME_FILTER';

export const ARTICLE_NAME_FILTER_REQUEST =
  '[ARTICLES] ARTICLE_NAME_FILTER_REQUEST';
export const ARTICLE_NAME_FILTER_SUCCESS =
  '[ARTICLES] ARTICLE_NAME_FILTER_SUCCESS';
export const ARTICLE_NAME_FILTER_FAILURE =
  '[ARTICLES] ARTICLE_NAME_FILTER_FAILURE';

export const ARTICLE_DETAIL_REQUEST = '[ARTICLES] ARTICLE_DETAIL_REQUEST';
export const ARTICLE_DETAIL_SUCCESS = '[ARTICLES] ARTICLE_DETAIL_SUCCESS';
export const ARTICLE_DETAIL_FAILURE = '[ARTICLES] ARTICLE_DETAIL_FAILURE';

export const ARTICLE_EDIT_REQUEST = '[ARTICLES] ARTICLE_EDIT_REQUEST';
export const ARTICLE_EDIT_SUCCESS = '[ARTICLES] ARTICLE_EDIT_SUCCESS';
export const ARTICLE_EDIT_FAILURE = '[ARTICLES] ARTICLE_EDIT_FAILURE';

export const ARTICLE_NEW_REQUEST = '[ARTICLES] ARTICLE_NEW_REQUEST';
export const ARTICLE_NEW_SUCCESS = '[ARTICLES] ARTICLE_NEW_SUCCESS';
export const ARTICLE_NEW_FAILURE = '[ARTICLES] ARTICLE_NEW_FAILURE';

export const ARTICLE_FILTER_NAME_REQUEST =
  '[ARTICLES] ARTICLE_FILTER_NAME_REQUEST';
export const ARTICLE_FILTER_NAME_SUCCESS =
  '[ARTICLES] ARTICLE_FILTER_NAME_SUCCESS';
export const ARTICLE_FILTER_NAME_FAILURE =
  '[ARTICLES] ARTICLE_FILTER_NAME_FAILURE';
export const ARTICLE_UPDATE_PAGE_REQUEST =
  '[ARTICLES] ARTICLE_UPDATE_PAGE_REQUEST';

export const ARTICLE_PRICE_LIST_REQUEST =
  '[ARTICLES] ARTICLE_PRICE_LIST_REQUEST';
export const ARTICLE_PRICE_LIST_SUCCESS =
  '[ARTICLES] ARTICLE_PRICE_LIST_SUCCESS';
export const ARTICLE_PRICE_LIST_FAILURE =
  '[ARTICLES] ARTICLE_PRICE_LIST_FAILURE';

export const ARTICLE_PRICE_DELETE_REQUEST =
  '[ARTICLES] ARTICLE_PRICE_DELETE_REQUEST';
export const ARTICLE_PRICE_DELETE_SUCCESS =
  '[ARTICLES] ARTICLE_PRICE_DELETE_SUCCESS';
export const ARTICLE_PRICE_DELETE_FAILURE =
  '[ARTICLES] ARTICLE_PRICE_DELETE_FAILURE';

export const ARTICLE_PRICE_CREATE_REQUEST =
  '[ARTICLES] ARTICLE_PRICE_CREATE_REQUEST';
export const ARTICLE_PRICE_CREATE_SUCCESS =
  '[ARTICLES] ARTICLE_PRICE_CREATE_SUCCESS';
export const ARTICLE_PRICE_CREATE_FAILURE =
  '[ARTICLES] ARTICLE_PRICE_CREATE_FAILURE';

export const ARTICLE_PRICE_EDIT_REQUEST =
  '[ARTICLES] ARTICLE_PRICE_EDIT_REQUEST';
export const ARTICLE_PRICE_EDIT_SUCCESS =
  '[ARTICLES] ARTICLE_PRICE_EDIT_SUCCESS';
export const ARTICLE_PRICE_EDIT_FAILURE =
  '[ARTICLES] ARTICLE_PRICE_EDIT_FAILURE';

export const ARTICLE_PRICE_GET_REQUEST = '[ARTICLES] ARTICLE_PRICE_GET_REQUEST';
export const ARTICLE_PRICE_GET_SUCCESS = '[ARTICLES] ARTICLE_PRICE_GET_SUCCESS';
export const ARTICLE_PRICE_GET_FAILURE = '[ARTICLES] ARTICLE_PRICE_GET_FAILURE';

export const ARTICLE_MACHINE_PRICE_CREATE_REQUEST =
  '[ARTICLES] ARTICLE_MACHINE_PRICE_CREATE_REQUEST';
export const ARTICLE_MACHINE_PRICE_CREATE_SUCCESS =
  '[ARTICLES] ARTICLE_MACHINE_PRICE_CREATE_SUCCESS';
export const ARTICLE_MACHINE_PRICE_CREATE_FAILURE =
  '[ARTICLES] ARTICLE_MACHINE_PRICE_CREATE_FAILURE';

export const ARTICLE_MACHINE_PRICE_EDIT_REQUEST =
  '[ARTICLES] ARTICLE_MACHINE_PRICE_EDIT_REQUEST';
export const ARTICLE_MACHINE_PRICE_EDIT_SUCCESS =
  '[ARTICLES] ARTICLE_MACHINE_PRICE_EDIT_SUCCESS';
export const ARTICLE_MACHINE_PRICE_EDIT_FAILURE =
  '[ARTICLES] ARTICLE_MACHINE_PRICE_EDIT_FAILURE';

export const ARTICLE_MACHINE_PRICE_DELETE_REQUEST =
  '[ARTICLES] ARTICLE_MACHINE_PRICE_DELETE_REQUEST';
export const ARTICLE_MACHINE_PRICE_DELETE_SUCCESS =
  '[ARTICLES] ARTICLE_MACHINE_PRICE_DELETE_SUCCESS';
export const ARTICLE_MACHINE_PRICE_DELETE_FAILURE =
  '[ARTICLES] ARTICLE_MACHINE_PRICE_DELETE_FAILURE';

export const ARTICLE_MACHINE_PRICE_GET_REQUEST =
  '[ARTICLES] ARTICLE_MACHINE_PRICE_GET_REQUEST';
export const ARTICLE_MACHINE_PRICE_GET_SUCCESS =
  '[ARTICLES] ARTICLE_MACHINE_PRICE_GET_SUCCESS';
export const ARTICLE_MACHINE_PRICE_GET_FAILURE =
  '[ARTICLES] ARTICLE_MACHINE_PRICE_GET_FAILURE';

export const ARTICLE_CLIENT_PRICE_CREATE_REQUEST =
  '[ARTICLES] ARTICLE_CLIENT_PRICE_CREATE_REQUEST';
export const ARTICLE_CLIENT_PRICE_CREATE_SUCCESS =
  '[ARTICLES] ARTICLE_CLIENT_PRICE_CREATE_SUCCESS';
export const ARTICLE_CLIENT_PRICE_CREATE_FAILURE =
  '[ARTICLES] ARTICLE_CLIENT_PRICE_CREATE_FAILURE';

export const ARTICLE_CLIENT_PRICE_EDIT_REQUEST =
  '[ARTICLES] ARTICLE_CLIENT_PRICE_EDIT_REQUEST';
export const ARTICLE_CLIENT_PRICE_EDIT_SUCCESS =
  '[ARTICLES] ARTICLE_CLIENT_PRICE_EDIT_SUCCESS';
export const ARTICLE_CLIENT_PRICE_EDIT_FAILURE =
  '[ARTICLES] ARTICLE_CLIENT_PRICE_EDIT_FAILURE';

export const ARTICLE_CLIENT_PRICE_GET_REQUEST =
  '[ARTICLES] ARTICLE_CLIENT_PRICE_GET_REQUEST';
export const ARTICLE_CLIENT_PRICE_GET_SUCCESS =
  '[ARTICLES] ARTICLE_CLIENT_PRICE_GET_SUCCESS';
export const ARTICLE_CLIENT_PRICE_GET_FAILURE =
  '[ARTICLES] ARTICLE_CLIENT_PRICE_GET_FAILURE';

export const ARTICLE_CLIENT_PRICE_DELETE_REQUEST =
  '[ARTICLES] ARTICLE_CLIENT_PRICE_DELETE_REQUEST';
export const ARTICLE_CLIENT_PRICE_DELETE_SUCCESS =
  '[ARTICLES] ARTICLE_CLIENT_PRICE_DELETE_SUCCESS';
export const ARTICLE_CLIENT_PRICE_DELETE_FAILURE =
  '[ARTICLES] ARTICLE_CLIENT_PRICE_DELETE_FAILURE';
