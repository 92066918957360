import BrokenImage from '@material-ui/icons/BrokenImage';
import Build from '@material-ui/icons/Build';
import LocalGasStation from '@material-ui/icons/LocalGasStation';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Store from '@material-ui/icons/Store';

export const MenuVehicles = [
  {
    anchor: 'carmechanic/incidents/new',
    id: 'new-incident',
    icon: <Build />,
    literal: 'NEW_INCIDENT',
    oldItem: true,
    protect: true,
    role: 'ROLE_VEHICLE_LIST',
  },
  {
    anchor: 'carmechanic/vehicle',
    id: 'vehicles',
    icon: <LocalShipping />,
    literal: 'VEHICLES',
    oldItem: true,
    protect: true,
    role: 'ROLE_VEHICLE_LIST',
  },
  {
    anchor: 'carmechanic/refuel/new',
    id: 'refueling',
    icon: <LocalGasStation />,
    literal: 'REFUELING',
    oldItem: true,
    protect: false,
    role: 'ROLE_VEHICLE_LIST',
  },
  {
    anchor: 'carmechanic/incidents',
    id: 'vehicles-incidents',
    icon: <Build />,
    literal: 'INCIDENTS',
    oldItem: true,
    protect: true,
    role: 'ROLE_VEHICLE_LIST',
  },
  {
    anchor: 'carmechanic/faults',
    id: 'fault',
    icon: <BrokenImage />,
    literal: 'FAULTS',
    oldItem: true,
    protect: true,
    role: 'ROLE_VEHICLE_LIST',
  },
  {
    anchor: 'carmechanic/garages',
    id: 'garage',
    icon: <Store />,
    literal: 'GARAGE',
    oldItem: true,
    protect: true,
    role: 'ROLE_VEHICLE_LIST',
  },
];
