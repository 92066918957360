import * as C from './clientsList.constants';

const formatAlias = (payload) => {
  return payload.aliases.map((alias) => {
    return { id: alias.id, resultsData: { name: alias.name } };
  });
};
const formatClientDetails = (payload) => {
  return {
    name: payload.name,
    number: payload.number,
  };
};
const generateUrlIframeGmaps = (latitude, longitude) => {
  if (latitude && longitude) {
    return `https://www.google.com/maps/embed/v1/place?q=${latitude},${longitude}&key=AIzaSyBEey9wwVPEP0w9VWdDXTbwGpOs5HH2pds`;
  }
  return null;
};
const formatTableData = (payload) => {
  return payload.results.map((client) => ({
    resultsData: {
      number: client.number,
      name: client.name,
      city: client.address.city,
    },
    linkPath: `/clients/${client.id}`,
  }));
};
export const initialState = {
  edit: false,
  redirect: false,
  clients: [],
  client: {},
  tableData: [],
  pageLimit: 20,
  currentPage: 1,
  totalItems: 0,
  message: ' ',
  loading: true,
  number: undefined,
  name: undefined,
  aliasToDelete: [],
  aliasToAdd: [],
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.CREATE_NEW_CLIENT_REQUEST:
    case C.CLIENT_LIST_REQUEST:
    case C.CLIENT_ALIAS_ADD_REQUEST:
    case C.CLIENT_ALIAS_REMOVE_REQUEST:
    case C.CLIENT_UPDATE_ALL_DATA_REQUEST:
    case C.CLIENT_SEND_DATA_REQUEST:
      return {
        ...state,
        redirect: false,
        message: ' ',
      };

    case C.CLIENT_DATA_REQUEST:
      return {
        ...state,
        message: ' ',
        loading: true,
      };
    case C.CLIENT_LIST_SUCCESS:
      return {
        ...state,
        pageNumber: payload.currentPage,
        totalItems: payload.totalItems,
        clients: payload.results,
        tableData: formatTableData(payload),
        message: ' ',
        loading: false,
        totalResults: payload.totalItems,
      };
    case C.CLIENT_UPDATE_ALL_DATA_SUCCESS:
    case C.CLIENT_ALIAS_REMOVE_SUCCESS:
    case C.CLIENT_ALIAS_ADD_SUCCESS:
    case C.CLIENT_SEND_DATA_SUCCESS:
    case C.CLIENT_DATA_SUCCESS:
      state.clientLocation = generateUrlIframeGmaps(
        payload?.position?.latitude,
        payload?.position?.longitude
      );
      return {
        ...state,
        client: {
          ...payload,
          originalClientNumber: payload.number,
          hasEdited: false,
        },
        clientDetail: formatClientDetails(payload),
        alias: formatAlias(payload),
        clientLocation: state.clientLocation,
        message: ' ',
        edit: false,
        loading: false,
        aliasToDelete: [],
        aliasToAdd: [],
      };
    case C.CLIENT_ALIAS_DELETE:
      if (
        typeof state.client.aliases[
          state.client.aliases.findIndex((v) => v.id === payload.id)
        ].id === 'number'
      ) {
        state.aliasToAdd.splice(
          state.aliasToAdd.findIndex((v) => v.id === payload.id),
          1
        );
      } else {
        state.aliasToDelete.push({ id: payload.id, clientId: state.client.id });
      }
      state.client.aliases.splice(
        state.client.aliases.findIndex((v) => v.id === payload.id),
        1
      );
      return {
        ...state,
        alias: formatAlias(state.client),
      };
    case C.CLIENT_ALIAS_ADD:
      state.aliasToAdd.push({ ...payload });
      state.client.aliases.push({ ...payload });
      return {
        ...state,
        alias: formatAlias(state.client),
      };
    case C.CLIENT_EDIT_ADDRESS:
      // eslint-disable-next-line prefer-destructuring
      state.client.address[payload[0]] = payload[1];
      state.client.hasEdited = true;
      return {
        ...state,
      };
    case C.CLIENT_EDIT_DETAILS:
      // eslint-disable-next-line prefer-destructuring
      state.client[payload[0]] = payload[1];
      state.client.hasEdited = true;

      return {
        ...state,
        clientDetail: formatClientDetails({ ...state.client }),
      };

    case C.CHANGE_EDIT_MODE:
      state.edit = !state.edit;
      return {
        ...state,
      };
    case C.CLIENT_SET_ERROR:
      return {
        ...state,
        message: payload,
      };

    case C.CREATE_NEW_CLIENT_SUCCESS:
      return {
        ...state,
        message: payload,
        redirect: true,
      };
    case C.CREATE_NEW_CLIENT_FAILURE:
    case C.CLIENT_ALIAS_ADD_FAILURE:
    case C.CLIENT_UPDATE_ALL_DATA_FAILURE:
    case C.CLIENT_ALIAS_REMOVE_FAILURE:
    case C.CLIENT_SEND_DATA_FAILURE:
    case C.CLIENT_DATA_FAILURE:
    case C.CLIENT_LIST_FAILURE:
      state.client = {};
      return {
        ...state,
        message: payload,
        loading: false,
      };
    default:
      return state;
  }
};
