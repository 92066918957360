import { lazy } from 'react';

const TrackingLanding = lazy(() =>
  import('../containers/TrackingLanding/TrackingLanding')
);

export default [
  {
    component: TrackingLanding,
    path: '/tracking',
  },
];
