import { lazy } from 'react';

const Clients = lazy(() => import('..'));
const ClientNewAndEdit = lazy(() => import('../new'));
const ClientDetail = lazy(() => import('../detail'));
const TransactionsLanding = lazy(() =>
  import('../containers/TransactionsLanding/TransactionsLanding')
);
const Transaction = lazy(() => import('../containers/Transaction/Transaction'));

export default [
  { path: '/clients', component: Clients },
  { path: '/clients/new', component: ClientNewAndEdit },
  { path: '/clients/transactions', component: TransactionsLanding },
  { path: '/clients/transaction/new', component: Transaction },
  { path: '/clients/transaction/:id', component: Transaction },
  { path: '/clients/:clientId/:tab?', component: ClientDetail },
];
