import * as C from './clientsList.constants';

export const createNewclientRequest = (payload) => ({
  type: C.CREATE_NEW_CLIENT_REQUEST,
  payload,
});
export const createNewclientSuccess = (payload) => ({
  type: C.CREATE_NEW_CLIENT_SUCCESS,
  payload,
});
export const createNewclientFailure = (payload) => ({
  type: C.CREATE_NEW_CLIENT_FAILURE,
  payload,
});

export const clientListRequest = (payload) => ({
  type: C.CLIENT_LIST_REQUEST,
  payload,
});
export const clientListSuccess = (payload) => ({
  type: C.CLIENT_LIST_SUCCESS,
  payload,
});
export const clientListFailure = (payload) => ({
  type: C.CLIENT_LIST_FAILURE,
  payload,
});

export const clientDataRequest = (payload) => ({
  type: C.CLIENT_DATA_REQUEST,
  payload,
});
export const clientDataSuccess = (payload) => ({
  type: C.CLIENT_DATA_SUCCESS,
  payload,
});
export const clientDataFailure = (payload) => ({
  type: C.CLIENT_DATA_FAILURE,
  payload,
});

export const clientAliasDelete = (payload) => ({
  type: C.CLIENT_ALIAS_DELETE,
  payload,
});
export const clientAliasAdd = (payload) => ({
  type: C.CLIENT_ALIAS_ADD,
  payload,
});
export const clientEditAddress = (payload) => ({
  type: C.CLIENT_EDIT_ADDRESS,
  payload,
});
export const clientEditDetails = (payload) => ({
  type: C.CLIENT_EDIT_DETAILS,
  payload,
});
export const sendClientData = (payload) => ({
  type: C.CLIENT_SEND_DATA_REQUEST,
  payload,
});
export const sendClientSuccess = (payload) => ({
  type: C.CLIENT_SEND_DATA_SUCCESS,
  payload,
});
export const sendClientFailure = (payload) => ({
  type: C.CLIENT_SEND_DATA_FAILURE,
  payload,
});
export const setEditMode = (payload) => ({
  type: C.CHANGE_EDIT_MODE,
  payload,
});
export const sendNewAliasRequest = (payload) => ({
  type: C.CLIENT_ALIAS_ADD_REQUEST,
  payload,
});
export const sendNewAliasFailure = (payload) => ({
  type: C.CLIENT_ALIAS_ADD_FAILURE,
  payload,
});
export const sendNewAliasSuccess = (payload) => ({
  type: C.CLIENT_ALIAS_ADD_SUCCESS,
  payload,
});
export const removeAliasRequest = (payload) => ({
  type: C.CLIENT_ALIAS_REMOVE_REQUEST,
  payload,
});
export const removeAliasFailure = (payload) => ({
  type: C.CLIENT_ALIAS_REMOVE_FAILURE,
  payload,
});
export const removeAliasSuccess = (payload) => ({
  type: C.CLIENT_ALIAS_REMOVE_SUCCESS,
  payload,
});
export const updateAllClientDataRequest = (payload) => ({
  type: C.CLIENT_UPDATE_ALL_DATA_REQUEST,
  payload,
});
export const updateAllClientDataSuccess = (payload) => ({
  type: C.CLIENT_UPDATE_ALL_DATA_SUCCESS,
  payload,
});
export const updateAllClientDataFailure = (payload) => ({
  type: C.CLIENT_UPDATE_ALL_DATA_FAILURE,
  payload,
});
export const setError = (payload) => ({
  type: C.CLIENT_SET_ERROR,
  payload,
});
