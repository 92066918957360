import styles from './index.module.scss';

const Loading = function Loading({ noMargin = false }) {
  return (
    <div
      className={noMargin ? styles.LoadingStyles : styles.LoadingStylesCenter}
    >
      <div className={styles.LdsDualRing} />
    </div>
  );
};

export default Loading;
