import Api from '../../../api';

class ClientsRepository extends Api {
  constructor() {
    super();
    this.api.defaults.baseURL += 'clients';
  }

  listClients = async ({
    pageNumber = 1,
    pageLimit = 20,
    number = undefined,
    name = undefined,
    status,
  }) => {
    const params = {
      pageNumber,
      pageLimit,
      name,
      number,
      status,
    };
    return this.api.get(`/filter${this.cleanParams(params)}`);
  };

  getClientById = async ({ id }) => this.api.get(`/${id}`);

  getClientBynumber = async (number) => this.api.get(`/number/${number}`);

  getClientLogs = async (id) => this.api.get(`/${id}/logs`);

  createClient = async ({ number, name, address }) =>
    this.api.post('', { number, name, address });

  getClientToEdit = async (id) => {
    if (!id) return null;
    return this.getClient({ id });
  };

  editClient = async (payload) => {
    const { id, address, name, number, status } = payload;
    return this.api.put(id, { address, name, number, status });
  };

  addClientAlias = (payload) => {
    let response;
    for (let index = 0; index < payload.length; index += 1) {
      response = this.api.post(`/${payload[index].clientId}/alias`, {
        name: payload[index].name,
      });
    }
    return response;
  };

  removeClientAlias = (payload) => {
    let response;
    for (let index = 0; index < payload.length; index += 1) {
      response = this.api.delete(
        `/${payload[index].clientId}/alias/${payload[index].id}`
      );
    }
    return response;
  };

  updateAllClientData = async (payload) => {
    let response;
    if (payload.client.hasEdited) {
      response = this.editClient(payload.client);
    }
    if (payload.aliasToAdd.length !== 0) {
      response = this.addClientAlias(payload.aliasToAdd);
    }
    if (payload.aliasToDelete.length !== 0) {
      response = this.removeClientAlias(payload.aliasToDelete);
    }
    return response;
  };
}
export default new ClientsRepository();
