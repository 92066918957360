import * as C from '../constants/navbar.constants';

export const isMobileRequest = (payload) => ({
  type: C.ISMOBILE_REQUEST,
  payload,
});

export const openMenu = () => ({
  type: C.OPEN_MENU,
});

export const closeMenu = () => ({
  type: C.CLOSE_MENU,
});

export const swapMenu = () => ({
  type: C.SWAP_MENU,
});
