import Assignment from '@material-ui/icons/Assignment';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import Print from '@material-ui/icons/Print';

export default [
  {
    anchor: 'labels/generate/',
    id: 'generate',
    icon: <Print />,
    literal: 'GENERATE',
    oldItem: true,
    protect: true,
    role: 'ROLE_LABEL_MANAGEMENT',
  },
  {
    anchor: 'labels/',
    id: 'manage',
    icon: <Assignment />,
    literal: 'MANAGE',
    oldItem: true,
    protect: true,
    role: 'ROLE_LABEL_MANAGEMENT',
  },
  {
    anchor: 'labels/category',
    id: 'categories',
    icon: <FormatListNumbered />,
    literal: 'CATEGORIES',
    oldItem: true,
    protect: true,
    role: 'ROLE_LABEL_MANAGEMENT',
  },
];
