import { lazy } from 'react';

const Routes = lazy(() => import('../routesList'));
const RoutesToday = lazy(() => import('../today'));
const RouteDetail = lazy(() => import('../routesList/detail'));
const RouteStats = lazy(() => import('../routeStats/RouteStats'));
const PrintRoute = lazy(() => import('../schedule/printRoute'));
const Schedule = lazy(() => import('../schedule'));
const ScheduleNew = lazy(() => import('../schedule/newSchedule'));
const ScheduleDetails = lazy(() => import('../schedule/detail/ScheduleDetail'));
const ScheduleTemplates = lazy(() => import('../schedule/templates'));
export default [
  {
    path: '/routes',
    component: Routes,
  },
  {
    path: '/routes/stats',
    component: RouteStats,
  },
  {
    path: '/routes/today',
    component: RoutesToday,
  },
  { path: '/routes/schedule', component: Schedule },
  { path: '/routes/schedule/new', component: ScheduleNew },
  {
    path: '/routes/schedule/templates',
    component: ScheduleTemplates,
  },
  {
    path: '/routes/schedule/print',
    component: PrintRoute,
  },
  { path: '/routes/schedule/:scheduleId', component: ScheduleDetails },
  {
    path: '/routes/:routeId',
    component: RouteDetail,
  },
];
