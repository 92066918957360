import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import clientList from '../pages/clients/store/clientsList.reducers';
import config from '../pages/config/store/config.reducers';
import routesList from '../pages/distribution_routes/store/routesList.reducers';
import incentiveList from '../pages/incentives/store/incentives.reducers';
import incidents from '../pages/incidents/store/incidents.reducers';
import machineList from '../pages/machines/store/machineList.reducers';
import users from '../pages/users/store/users.reducers';
import articles from './articles/articles.reducers';
import auth from './auth/reducers/auth.reducers';
import global from './global/reducers/global.reducers';
import route from './machineRoute/machineRoute.reducers';
import modal from './modal/modal.reducers';
import navBar from './navbar/reducers/navbar.reducers';
import reports from './reports/reports.reducers';
import tasks from './tasks/tasks.reducers';

const appReducer = (history) =>
  combineReducers({
    auth,
    machineList,
    articles,
    clientList,
    routesList,
    incentiveList,
    incidents,
    navBar,
    users,
    global,
    tasks,
    route,
    modal,
    router: connectRouter(history),
    reports,
    config,
  });

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
