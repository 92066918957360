import Api from '../../../api';

class IncentivesRepository extends Api {
  constructor() {
    super();
    this.api.defaults.baseURL += 'incentives';
  }

  listIncentivesSummary = async (payload) =>
    this.api.get(`/summary${this.cleanParams(payload)}`);

  retrieveIncentivesDetail = async (payload) =>
    this.api.get(`/user/${payload.userId}${this.cleanParams(payload)}`);
}
export default new IncentivesRepository();
