import * as C from '../constants/navbar.constants';

export const initialState = {
  mobile: false,
  menu: true,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.ISMOBILE_REQUEST:
      return {
        ...state,
        mobile: payload,
      };
    case C.OPEN_MENU:
      return {
        ...state,
        mobile: false,
      };
    case C.CLOSE_MENU:
      return {
        ...state,
        mobile: true,
      };
    case C.SWAP_MENU:
      return {
        ...state,
        menu: !state.menu,
      };
    default:
      return state;
  }
};
