import { call, put, takeLatest } from 'redux-saga/effects';

import UsersRepository from '../api/api.users';
import * as A from './users.action';
import * as C from './users.constants';

function* usersAllListEffect() {
  try {
    const response = yield call(UsersRepository.getAllUsers);
    yield put(A.usersAllListSuccess(response));
  } catch (e) {
    yield put(A.usersAllListFailure(e.message));
  }
}
function* usersListEffect({ payload }) {
  try {
    const response = yield call(UsersRepository.getUsers, payload);
    yield put(A.usersListSuccess(response));
  } catch (e) {
    yield put(A.usersListFailure(e.message));
  }
}

export default function* machinesSagas() {
  yield takeLatest(C.USERS_ALL_LIST_REQUEST, usersAllListEffect);
  yield takeLatest(C.USERS_LIST_REQUEST, usersListEffect);
}
