export const CREATE_NEW_CLIENT_REQUEST =
  '[CLIENTLIST] CREATE_NEW_CLIENT_REQUEST';
export const CREATE_NEW_CLIENT_SUCCESS =
  '[CLIENTLIST] CREATE_NEW_CLIENT_SUCCESS';
export const CREATE_NEW_CLIENT_FAILURE =
  '[CLIENTLIST] CREATE_NEW_CLIENT_FAILURE';
export const CLIENT_LIST_REQUEST = '[CLIENTLIST] CLIENT_LIST_REQUEST';
export const CLIENT_LIST_SUCCESS = '[CLIENTLIST] CLIENT_LIST_SUCCESS';
export const CLIENT_LIST_FAILURE = '[CLIENTLIST] CLIENT_LIST_FAILURE';
export const CLIENT_DATA_REQUEST = '[CLIENTLIST] CLIENT_DATA_REQUEST';
export const CLIENT_DATA_SUCCESS = '[CLIENTLIST] CLIENT_DATA_SUCCESS';
export const CLIENT_DATA_FAILURE = '[CLIENTLIST] CLIENT_DATA_FAILURE';
export const CLIENT_ALIAS_DELETE = '[CLIENTLIST] CLIENT_ALIAS_DELETE';
export const CLIENT_ALIAS_ADD = '[CLIENTLIST] CLIENT_ALIAS_ADD';
export const CLIENT_EDIT_ADDRESS = '[CLIENTLIST] CLIENT_EDIT_ADDRESS';
export const CLIENT_EDIT_DETAILS = '[CLIENTLIST] CLIENT_EDIT_DETAILS';
export const CLIENT_SEND_DATA_REQUEST =
  '[CLIENTLIST] SEND CLIENT DATA TO API REQUEST';
export const CLIENT_SEND_DATA_SUCCESS =
  '[CLIENTLIST] SEND CLIENT DATA TO API SUCCESS';
export const CLIENT_SEND_DATA_FAILURE =
  '[CLIENTLIST] SEND CLIENT DATA TO API FAILURE';
export const CHANGE_EDIT_MODE = '[CLIENTLIST] CHANGE EDIT MODE';
export const CLIENT_ALIAS_ADD_REQUEST = '[CLIENTLIST] CLIENT_ALIAS_ADD_REQUEST';
export const CLIENT_ALIAS_ADD_SUCCESS = '[CLIENTLIST] CLIENT_ALIAS_ADD_SUCCESS';
export const CLIENT_ALIAS_ADD_FAILURE = '[CLIENTLIST] CLIENT_ALIAS_ADD_FAILURE';
export const CLIENT_ALIAS_REMOVE_REQUEST =
  '[CLIENTLIST] CLIENT_ALIAS_REMOVE_REQUEST';
export const CLIENT_ALIAS_REMOVE_SUCCESS =
  '[CLIENTLIST] CLIENT_ALIAS_REMOVE_SUCCESS';
export const CLIENT_ALIAS_REMOVE_FAILURE =
  '[CLIENTLIST] CLIENT_ALIAS_REMOVE_FAILURE';
export const CLIENT_UPDATE_ALL_DATA_REQUEST =
  '[CLIENTLIST] UPDATE ALL CLIENT DATA REQUEST';
export const CLIENT_UPDATE_ALL_DATA_SUCCESS =
  '[CLIENTLIST] UPDATE ALL CLIENT DATA SUCCESS';
export const CLIENT_UPDATE_ALL_DATA_FAILURE =
  '[CLIENTLIST] UPDATE ALL CLIENT DATA FAILURE';
export const CLIENT_SET_ERROR = '[CLIENTLIST] SET ERROR';
