const useJwt = (token) => {
  const decodeToken = () => {
    try {
      if (token.split('.').length !== 3 || typeof token !== 'string') {
        return null;
      }

      const payload = token.split('.')[1];
      const base64 = payload.replace('-', '+').replace('_', '/');
      const decoded = JSON.parse(atob(base64));

      return decoded;
    } catch (error) {
      return null;
    }
  };

  const isTokenExpired = () => {
    const decodedToken = decodeToken(token);
    let result = true;

    if (decodedToken && decodedToken.exp) {
      const expirationDate = new Date(0);
      expirationDate.setUTCSeconds(decodedToken.exp);
      result = expirationDate.valueOf() < new Date().valueOf();
    }

    return result;
  };

  return { decodedToken: decodeToken(), isExpired: isTokenExpired() };
};

export default useJwt;
