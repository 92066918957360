import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import EuroIcon from '@material-ui/icons/Euro';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';

export const MenuReports = [
  {
    anchor: 'reports/lackAndSurplus/',
    id: 'lack-surplus',
    icon: <EuroIcon />,
    literal: 'LACK_AND_SURPLUS',
    oldItem: false,
    protect: true,
    role: 'ROLE_REPORTS',
  },
  {
    anchor: 'reports/tpv/',
    id: 'tpv',
    icon: <ConfirmationNumberIcon />,
    literal: 'TPV',
    oldItem: false,
    protect: true,
    role: 'ROLE_REPORTS',
  },
  {
    anchor: 'reports/refuel',
    id: 'refueling',
    icon: <FormatListNumbered />,
    literal: 'REFUELING',
    oldItem: true,
    protect: true,
    role: 'ROLE_REPORTS',
  },
  {
    anchor: 'reports/incident',
    id: 'reports-incidents',
    icon: <FormatListNumbered />,
    literal: 'INCIDENTS',
    oldItem: true,
    protect: true,
    role: 'ROLE_REPORTS',
  },
];
