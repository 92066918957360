import * as C from './routesList.constants';

export const routesRequest = (payload) => ({
  type: C.ROUTES_REQUEST,
  payload,
});
export const routesSuccess = (payload) => ({
  type: C.ROUTES_SUCCESS,
  payload,
});
export const routesFailure = (payload) => ({
  type: C.ROUTES_FAILURE,
  payload,
});
export const routesListRequest = (payload) => ({
  type: C.ROUTES_LIST_REQUEST,
  payload,
});
export const routesListSuccess = (payload) => ({
  type: C.ROUTES_LIST_SUCCESS,
  payload,
});
export const routesListFailure = (payload) => ({
  type: C.ROUTES_LIST_FAILURE,
  payload,
});

export const routeDetailRequest = (payload) => ({
  type: C.ROUTE_DETAIL_REQUEST,
  payload,
});
export const routeDetailSuccess = (payload) => ({
  type: C.ROUTE_DETAIL_SUCCESS,
  payload,
});
export const routeDetailFailure = (payload) => ({
  type: C.ROUTE_DETAIL_FAILURE,
  payload,
});

export const getTodayRoutesRequest = (payload) => ({
  type: C.GET_TODAY_ROUTES_REQUEST,
  payload,
});
export const getTodayRoutesSuccess = (payload) => ({
  type: C.GET_TODAY_ROUTES_SUCCESS,
  payload,
});
export const getTodayRoutesFailure = (payload) => ({
  type: C.GET_TODAY_ROUTES_FAILURE,
  payload,
});

export const routesScheduleNameFilter = (payload) => ({
  type: C.ROUTES_SCHEDULE_NAME_FILTER,
  payload,
});
export const routesScheduleNumberFilter = (payload) => ({
  type: C.ROUTES_SCHEDULE_NUMBER_FILTER,
  payload,
});

export const routesSchedulesListRequest = (payload) => ({
  type: C.ROUTES_SCHEDULES_LIST_REQUEST,
  payload,
});

export const routesSchedulesListSuccess = (payload) => ({
  type: C.ROUTES_SCHEDULES_LIST_SUCCESS,
  payload,
});
export const routesSchedulesListFailure = (payload) => ({
  type: C.ROUTES_SCHEDULES_LIST_FAILURE,
  payload,
});

export const routesSchedulesListSelectableRequest = (payload) => ({
  type: C.ROUTES_SCHEDULES_LIST_SELECTABLE_REQUEST,
  payload,
});

export const routesSchedulesListSelectableSuccess = (payload) => ({
  type: C.ROUTES_SCHEDULES_LIST_SELECTABLE_SUCCESS,
  payload,
});
export const routesSchedulesListSelectableFailure = (payload) => ({
  type: C.ROUTES_SCHEDULES_LIST_SELECTABLE_FAILURE,
  payload,
});

export const scheduleDetailRequest = (payload) => ({
  type: C.SCHEDULE_DETAIL_REQUEST,
  payload,
});

export const scheduleDetailSuccess = (payload) => ({
  type: C.SCHEDULE_DETAIL_SUCCESS,
  payload,
});

export const scheduleDetailFailure = (payload) => ({
  type: C.SCHEDULE_DETAIL_FAILURE,
  payload,
});

export const routesSchedulesAvailableTypesRequest = (payload) => ({
  type: C.ROUTES_SCHEDULES_AVAILABLE_TYPES_REQUEST,
  payload,
});
export const routesSchedulesAvailableTypesSuccess = (payload) => ({
  type: C.ROUTES_SCHEDULES_AVAILABLE_TYPES_SUCCESS,
  payload,
});
export const routesSchedulesAvailableTypesFailure = (payload) => ({
  type: C.ROUTES_SCHEDULES_AVAILABLE_TYPES_FAILURE,
  payload,
});

export const routesSchedulesStatsTable = (payload) => ({
  type: C.ROUTES_SCHEDULES_STATS_TABLE,
  payload,
});

export const routesSchedulesLoadingFalse = () => ({
  type: C.ROUTES_LOADING_FALSE,
});

export const routesSchedulesEditRequest = (payload) => ({
  type: C.ROUTES_SCHEDULES_EDIT_REQUEST,
  payload,
});
export const routesSchedulesEditSuccess = (payload) => ({
  type: C.ROUTES_SCHEDULES_EDIT_SUCCESS,
  payload,
});
export const routesSchedulesEditFailure = (payload) => ({
  type: C.ROUTES_SCHEDULES_EDIT_FAILURE,
  payload,
});

export const routesSchedulesCreateRequest = (payload) => ({
  type: C.ROUTES_SCHEDULES_CREATE_REQUEST,
  payload,
});
export const routesSchedulesCreateSuccess = (payload) => ({
  type: C.ROUTES_SCHEDULES_CREATE_SUCCESS,
  payload,
});
export const routesSchedulesCreateFailure = (payload) => ({
  type: C.ROUTES_SCHEDULES_CREATE_FAILURE,
  payload,
});

export const routesSchedulesTemplatesListRequest = () => ({
  type: C.ROUTES_SCHEDULES_TEMPLATES_LIST_REQUEST,
});
export const routesSchedulesTemplatesListSuccess = (payload) => ({
  type: C.ROUTES_SCHEDULES_TEMPLATES_LIST_SUCCESS,
  payload,
});
export const routesSchedulesTemplatesListFailure = (payload) => ({
  type: C.ROUTES_SCHEDULES_TEMPLATES_LIST_FAILURE,
  payload,
});

export const routesSchedulesTemplatesRemoveRequest = (payload) => ({
  type: C.ROUTES_SCHEDULES_TEMPLATES_REMOVE_REQUEST,
  payload,
});
export const routesSchedulesTemplatesRemoveSuccess = (payload) => ({
  type: C.ROUTES_SCHEDULES_TEMPLATES_REMOVE_SUCCESS,
  payload,
});
export const routesSchedulesTemplatesRemoveFailure = (payload) => ({
  type: C.ROUTES_SCHEDULES_TEMPLATES_REMOVE_FAILURE,
  payload,
});

export const routesSchedulesTemplatesEditRequest = (payload) => ({
  type: C.ROUTES_SCHEDULES_TEMPLATES_EDIT_REQUEST,
  payload,
});
export const routesSchedulesTemplatesEditSuccess = (payload) => ({
  type: C.ROUTES_SCHEDULES_TEMPLATES_EDIT_SUCCESS,
  payload,
});
export const routesSchedulesTemplatesEditFailure = (payload) => ({
  type: C.ROUTES_SCHEDULES_TEMPLATES_EDIT_FAILURE,
  payload,
});

export const routesSchedulesModalRequest = (payload) => ({
  type: C.ROUTES_SCHEDULES_MODAL_REQUEST,
  payload,
});
export const routesSchedulesModalSuccess = (payload) => ({
  type: C.ROUTES_SCHEDULES_MODAL_SUCCESS,
  payload,
});
export const routesSchedulesModalFailure = (payload) => ({
  type: C.ROUTES_SCHEDULES_MODAL_FAILURE,
  payload,
});

export const routesSchedulesMoveMachineRequest = (payload) => ({
  type: C.ROUTES_SCHEDULES_MOVE_MACHINE_REQUEST,
  payload,
});
export const routesSchedulesMoveMachineSuccess = (payload) => ({
  type: C.ROUTES_SCHEDULES_MOVE_MACHINE_SUCCESS,
  payload,
});
export const routesSchedulesMoveMachineFailure = (payload) => ({
  type: C.ROUTES_SCHEDULES_MOVE_MACHINE_FAILURE,
  payload,
});

export const routesSchedulesMachineDetailsModalRequest = (payload) => ({
  type: C.ROUTES_SCHEDULES_MACHINE_DETAILS_MODAL_REQUEST,
  payload,
});
export const routesSchedulesMachineDetailsModalSuccess = (payload) => ({
  type: C.ROUTES_SCHEDULES_MACHINE_DETAILS_MODAL_SUCCESS,
  payload,
});
export const routesSchedulesMachineDetailsModalFailure = (payload) => ({
  type: C.ROUTES_SCHEDULES_MACHINE_DETAILS_MODAL_FAILURE,
  payload,
});

export const routesSchedulesPdfRequest = (payload) => ({
  type: C.ROUTES_SCHEDULES_PDF_REQUEST,
  payload,
});

export const routesSchedulesPdfSucces = (payload) => ({
  type: C.ROUTES_SCHEDULES_PDF_SUCCESS,
  payload,
});
export const routesSchedulesPdfFailure = (payload) => ({
  type: C.ROUTES_SCHEDULES_PDF_FAILURE,
  payload,
});

export const routesFinishRequest = (payload) => ({
  type: C.ROUTES_FINISH_REQUEST,
  payload,
});

export const routesFinishSuccess = (payload) => ({
  type: C.ROUTES_FINISH_SUCCESS,
  payload,
});
export const routesFinishFailure = (payload) => ({
  type: C.ROUTES_FINISH_FAILURE,
  payload,
});

export const routesStatsRequest = (payload) => ({
  type: C.ROUTES_STATS_REQUEST,
  payload,
});

export const routesStatsSuccess = (payload) => ({
  type: C.ROUTES_STATS_SUCCESS,
  payload,
});

export const routesStatsFailure = (payload) => ({
  type: C.ROUTES_STATS_FAILURE,
  payload,
});

export const machineTypesRequest = (payload) => ({
  type: C.MACHINE_TYPES_REQUEST,
  payload,
});

export const machineTypesSuccess = (payload) => ({
  type: C.MACHINE_TYPES_SUCCESS,
  payload,
});

export const machineTypesFailure = (payload) => ({
  type: C.MACHINE_TYPES_FAILURE,
  payload,
});
