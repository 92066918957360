/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import axios from 'axios';

import getCookie from '../helpers/getCookie';

class Api {
  constructor() {
    const header = document.cookie.includes('jwt')
      ? {
          Authorization: `Bearer ${getCookie('jwt')}`,
        }
      : null;
    const axiosInstance = axios.create({
      baseURL: process.env.API_BASE_URL,
      headers: header,
    });
    axiosInstance.interceptors.response.use(
      (response) => response.data,
      (error) => {
        if (error.response.status === 401) {
          error.message = 'INVALID TOKEN';
          window.location.replace('/login');
          return Promise.reject(error);
        }
        if (error.response.status === 400) {
          if (
            error.response?.data?.message
              ?.toLowerCase()
              .includes('already exists')
          ) {
            window.Notification.error('Ja existeix');
            return Promise.reject(error);
          }
          error.message = "S'ha produït un error";
          return Promise.reject(error);
        }
        if (error.response.status === 404) {
          error.message = "S'ha produït un error";
          return Promise.reject(error);
        }
        if (error.response.status === 500) {
          error.message = "S'ha produït un error";
          return Promise.reject(error);
        }
        switch (error.response.data.message) {
          case 'Name can not be empty':
            error.message = 'CLIENT_NAME_EMPTY';
            return Promise.reject(error);
          case 'Provided Number is already in use by another Client':
            error.message = 'CLIENT_NUMBER_EMPTY_OR_IN_USE';
            return Promise.reject(error);
          case "Can't overwrite cause with io.vement.api.client.domain.ClientNumberAlreadyInUse: Provided Number is already in use by another Client; nested exception is java.lang.IllegalStateException: Can't overwrite cause with io.vement.api.client.domain.ClientNumberAlreadyInUse: Provided Number is already in use by another Client":
            error.message = 'CLIENT_ALREADY_EXISTS';
            return Promise.reject(error);
          case "Requested brand doesn't exist":
            error.message = 'BRAND_NOT_EXIST';
            return Promise.reject(error);
          default:
            error.message = 'UNCONTROLLED_ERROR';
            return Promise.reject(error);
        }
      }
    );

    this.api = axiosInstance;
  }

  get(url) {
    return this.api.get(url);
  }

  post(url, data) {
    return this.api.post(url, data.arg ? data.arg : data);
  }

  put(url, data) {
    return this.api.put(url, data.arg ? data.arg : data);
  }

  delete(url, data) {
    return this.api.delete(url, data.arg ? data.arg : data);
  }

  // eslint-disable-next-line class-methods-use-this
  cleanParams(params) {
    const newParams = { ...params };
    for (const key in newParams) {
      if (
        newParams[key] === undefined ||
        newParams[key] === null ||
        newParams[key] === ''
      ) {
        delete newParams[key];
      }
    }
    return `?${new URLSearchParams(newParams)}`;
  }
}
export default Api;
