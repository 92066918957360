import * as C from '../constants/global.constants';

export const createError = (payload) => ({
  type: C.CREATE_ERROR,
  payload,
});

export const removeError = () => ({
  type: C.REMOVE_ERROR,
});

export const createRedirect = (payload) => ({
  type: C.CREATE_REDIRECT,
  payload,
});

export const removeRedirect = () => ({
  type: C.REMOVE_REDIRECT,
});
