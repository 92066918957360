import { lazy } from 'react';

const Machines = lazy(() => import('../machinesList'));
const MachineDetail = lazy(() => import('../machinesList/detail'));
const MachineNewAndEdit = lazy(() => import('../machinesList/newAndEdit'));
const MachineBrands = lazy(() => import('../brands'));
const MachineBrandsNewAndEdit = lazy(() => import('../brands/newAndEdit'));
const MachineModels = lazy(() => import('../models'));
const MachineModelsNewAndEdit = lazy(() => import('../models/newAndEdit'));
const MachineReplace = lazy(() => import('../machinesList/newAndEdit/replace'));

export default [
  {
    component: MachineNewAndEdit,
    path: '/machines/new',
  },
  {
    component: MachineModelsNewAndEdit,
    path: '/machines/models/edit/:modelId',
  },

  {
    component: MachineBrandsNewAndEdit,
    path: '/machines/brands/edit/:brandId',
  },
  {
    component: Machines,
    path: '/machines',
  },
  {
    component: MachineBrands,
    path: '/machines/brands',
  },
  {
    component: MachineBrandsNewAndEdit,
    path: '/machines/brands/new',
  },
  {
    component: MachineModels,
    path: '/machines/models',
  },
  {
    component: MachineModelsNewAndEdit,
    path: '/machines/models/new',
  },
  {
    component: MachineReplace,
    path: '/machines/:machineId/replace',
  },
  {
    component: MachineDetail,
    path: '/machines/:machineId/:tab?',
  },
];
