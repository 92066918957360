/* eslint-disable no-case-declarations */
import cloneDeep from 'lodash/cloneDeep';

import {
  formatTemplateToVisits,
  getTemplateVisits,
} from '../../distribution_routes/helpers/schedules';
import * as C from './machineList.constants';

export const initialState = {
  machines: [],
  refresh: true,
  machine: {},
  machineNumber: {},
  pageLimit: 20,
  currentPage: 1,
  totalItems: 0,
  number: undefined,
  typeId: undefined,
  typesList: undefined,
  typeIdList: undefined,
  clientId: undefined,
  clientIdList: undefined,
  clientDetails: {},
  clientsList: undefined,
  modelId: undefined,
  modelIdList: undefined,
  modelList: undefined,
  articles: [],
  articlesList: undefined,
  filterArticles: [],
  brands: [],
  machineBrand: undefined,
  models: [],
  machineModel: undefined,
  loading: true,
  loadingModal: true,
  servicesArray: [],
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.RESET:
      return initialState;
    case C.MACHINE_NEW_REQUEST:
    case C.MACHINE_EDIT_REQUEST:
    case C.MACHINE_ARTICLES_LIST_REQUEST:
    case C.MACHINE_BRAND_EDIT_REQUEST:
    case C.MACHINE_BRANDS_LIST_REQUEST:
    case C.MACHINE_MODELS_LIST_REQUEST:
    case C.MACHINE_MODEL_EDIT_REQUEST:
    case C.MACHINE_MODEL_DATA_REQUEST:
    case C.MACHINE_BRAND_DATA_REQUEST:
    case C.MACHINE_REPLACE_REQUEST:
    case C.MACHINE_CLIENT_DETAILS_REQUEST:
    case C.MACHINE_REPLACE_ARTICLES_REQUEST:
    case C.MACHINE_REMOVE_ROUTE_REQUEST:
      return {
        ...state,
        machineModel: undefined,
        machineBrand: undefined,
        loading: true,
      };
    case C.GET_MACHINE_BY_NUMBER_REQUEST:
      return {
        ...state,
        machineNumber: {},
      };
    case C.MACHINE_DETAIL_REQUEST:
      return {
        ...state,
        machine: {},
      };
    case C.MACHINE_DELETE_CLIENT_REQUEST:
      return {
        ...state,
      };
    case C.GET_MACHINE_SERVICES_REQUEST:
      return {
        ...state,
        loadingModal: true,
      };
    case C.MACHINE_LIST_REQUEST:
      return {
        ...state,
        machines: [],
      };
    case C.MACHINE_MODEL_FILTER_REQUEST:
    case C.MACHINE_TYPE_FILTER_REQUEST:
    case C.MACHINE_CLIENT_FILTER_REQUEST:
    case C.MACHINE_ARTICLES_REMOVE_REQUEST:
    case C.ARTICLES_LIST_REQUEST:
    case C.ARTICLES_ADD_REQUEST:
    case C.MACHINE_BRAND_NEW_REQUEST:
    case C.MACHINE_MODEL_NEW_REQUEST:
    case C.MACHINE_EDIT_SCHEDULE_REQUEST:
      return {
        ...state,
      };
    case C.MACHINE_EDIT_SCHEDULE_SUCCESS: {
      const newMachine = cloneDeep(state.machine);
      newMachine.schedule.visits = formatTemplateToVisits(
        payload.schedule.visits
      );
      return {
        ...state,
        machine: newMachine,
      };
    }
    case C.MACHINE_NUMBER_FILTER:
      return {
        ...state,
        number: payload.number,
      };
    case C.MACHINE_TYPE_FILTER:
      return {
        ...state,
        typeIdList: payload.typeId,
      };
    case C.MACHINE_CLIENT_FILTER:
      return {
        ...state,
        clientIdList: payload.clientId,
      };
    case C.MACHINE_MODEL_FILTER:
      return {
        ...state,
        modelIdList: payload.modelId,
      };
    case C.MACHINE_TYPE_FILTER_SUCCESS:
      return {
        ...state,
        typesList: payload,
        loading: false,
      };
    case C.ARTICLES_LIST_SUCCESS:
      const articlesList = payload.results.map((article) => ({
        ...article,
        text: article.name,
        value: article,
      }));
      articlesList.sort((a, b) => a.selectionNumber - b.selectionNumber);
      return {
        ...state,
        articlesList,
        loading: false,
      };
    case C.MACHINE_MODEL_FILTER_SUCCESS:
      return {
        ...state,
        modelList: payload,
        loading: false,
      };
    case C.ARTICLES_ADD_SUCCESS:
      const newArticles = cloneDeep(state.articles);
      const newArticlesList = [payload, ...newArticles];

      return {
        ...state,
        articles: newArticlesList,
        filterArticles: newArticlesList,
        loading: false,
      };
    case C.MACHINE_REMOVE_ALL_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: [],
        filterArticles: [],
      };
    case C.MACHINE_BRAND_EDIT_SUCCESS:
    case C.MACHINE_BRAND_DATA_SUCCESS:
      return {
        ...state,
        machineBrand: payload,
        loading: false,
      };
    case C.MACHINE_MODEL_EDIT_SUCCESS:
    case C.MACHINE_MODEL_DATA_SUCCESS:
      return {
        ...state,
        machineModel: payload,
        loading: false,
      };
    case C.MACHINE_BRANDS_LIST_SUCCESS:
      const brandList = payload.results.map((brand) => ({
        ...brand,
        text: brand.name,
        value: brand.id,
      }));
      return {
        ...state,
        pageNumber: payload.currentPage,
        totalItems: payload.totalItems,
        brands: brandList,
        loading: false,
      };
    case C.MACHINE_MODELS_LIST_SUCCESS:
      return {
        ...state,
        pageNumber: payload.currentPage,
        totalItems: payload.totalItems,
        models: payload.results,
        loading: false,
      };
    case C.MACHINE_BRAND_NEW_SUCCESS:
    case C.MACHINE_MODEL_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case C.MACHINE_REPLACE_ARTICLES_SUCCESS: {
      const filterArticles = state.filterArticles.map(
        ({ name, number, id, selectionNumber, retailPrice, prices }) => {
          if (selectionNumber === payload.selectionNumber) {
            return payload.newArticle;
          }
          return {
            name,
            number,
            id,
            selectionNumber,
            retailPrice,
            prices,
          };
        }
      );
      const articles = state.articles.map((article) => {
        if (article.selectionNumber === payload.selectionNumber) {
          return payload.newArticle;
        }
        return article;
      });
      filterArticles.sort((a, b) => a.selectionNumber - b.selectionNumber);
      articles.sort((a, b) => a.selectionNumber - b.selectionNumber);

      return {
        ...state,
        articles,
        filterArticles,
        loading: false,
      };
    }
    case C.MACHINE_ARTICLES_LIST_SUCCESS: {
      const filterArticles = payload.map(
        ({ name, number, id, selectionNumber, prices, retailPrice }) => ({
          name,
          number,
          id,
          selectionNumber,
          retailPrice,
          prices,
        })
      );

      filterArticles.sort((a, b) => a.selectionNumber - b.selectionNumber);
      const articles = payload;
      articles.sort((a, b) => a.selectionNumber - b.selectionNumber);
      return {
        ...state,
        articles,
        filterArticles,
        loading: false,
      };
    }
    case C.MACHINE_ARTICLES_FILTER:
      const filterArticles = state.articles.filter((e) =>
        Object.keys(e).some(
          (key) =>
            (key === 'name' || key === 'number') &&
            e[key].toString().toLowerCase().includes(payload.toLowerCase())
        )
      );
      return {
        ...state,
        filterArticles,
        loading: false,
      };
    case C.MACHINE_ARTICLES_REMOVE_SUCCESS:
      const noRemovedArticles = state.articles.filter(
        (article) => article.selectionNumber !== payload
      );

      noRemovedArticles.sort((a, b) => a.selectionNumber - b.selectionNumber);
      return {
        ...state,
        articles: noRemovedArticles,
        filterArticles: noRemovedArticles,
        loading: false,
      };
    case C.MACHINE_LIST_SUCCESS:
      return {
        ...state,
        pageNumber: payload.currentPage,
        totalItems: payload.totalItems,
        machines: payload.results.map((machine) => ({
          ...machine,
          text: machine.number.toString(),
          value: machine.id,
        })),
        loading: false,
      };
    case C.MACHINE_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        clientDetails: payload,
        loading: false,
      };
    case C.MACHINE_CLIENT_FILTER_SUCCESS:
      return {
        ...state,
        clientsList: payload.results.map((client) => ({
          ...client,
          text: client.name,
          value: client.id,
        })),
        loading: false,
      };
    case C.GET_MACHINE_BY_NUMBER_SUCCESS:
      return {
        ...state,
        machineNumber: { ...payload },
      };
    case C.MACHINE_DETAIL_SUCCESS: {
      const formatedVisit = getTemplateVisits(payload.schedule);
      return {
        ...state,
        clientIdList: undefined,
        typeIdList: undefined,
        number: undefined,
        machine: {
          ...payload,
          schedule: { ...payload.schedule, visits: formatedVisit },
        },
        loading: false,
        refresh: false,
      };
    }
    case C.MACHINE_EDIT_SUCCESS:
    case C.MACHINE_NEW_SUCCESS:
      return {
        ...state,
        clientIdList: undefined,
        typeIdList: undefined,
        number: undefined,
        machine: payload,
        loading: false,
      };
    case C.MACHINE_DELETE_CLIENT_SUCCESS:
      state.machines = state.machines.filter(
        (machine) => machine.id !== payload
      );

      return {
        ...state,
        refresh: true,
      };
    case C.MACHINE_REMOVE_ROUTE_SUCCESS: {
      const machine = { ...state.machine, schedule: {} };
      return {
        ...state,
        machine,
      };
    }
    case C.MACHINE_CHANGE_STATUS_SUCCESS:
      const machine = { ...state.machine, status: payload.status };
      return {
        ...state,
        machine,
      };

    case C.GET_MACHINE_SERVICES_SUCCESS:
      const returnObject = [];
      for (let indexMonth = 1; indexMonth <= 12; indexMonth += 1) {
        const allServicesOfMonth = payload.response.filter(
          (a) => a.month === indexMonth
        );
        let fila = { month: indexMonth };
        for (
          let indexYear = payload.from;
          indexYear <= payload.to;
          indexYear += 1
        ) {
          const actualMonth = allServicesOfMonth.filter(
            (a) => a.year === indexYear
          );
          if (actualMonth.length >= 1) {
            fila = { ...fila, [indexYear]: actualMonth[0].services };
          } else {
            fila = { ...fila, [indexYear]: 0 };
          }
        }
        returnObject.push(fila);
      }
      return {
        ...state,
        loadingModal: false,
        servicesArray: returnObject,
      };
    case C.GET_MACHINE_BY_NUMBER_FAILURE:
    case C.MACHINE_DELETE_CLIENT_FAILURE:
    case C.GET_MACHINE_SERVICES_FAILURE:
    case C.MACHINE_REPLACE_SUCCESS:
    case C.MACHINE_EDIT_FAILURE:
    case C.MACHINE_LIST_FAILURE:
    case C.MACHINE_NEW_FAILURE:
    case C.MACHINE_TYPE_FILTER_FAILURE:
    case C.MACHINE_DETAIL_FAILURE:
    case C.MACHINE_CLIENT_DETAILS_FAILURE:
    case C.MACHINE_MODEL_FILTER_FAILURE:
    case C.MACHINE_ARTICLES_LIST_FAILURE:
    case C.MACHINE_ARTICLES_REMOVE_FAILURE:
    case C.ARTICLES_LIST_FAILURE:
    case C.MACHINE_BRANDS_LIST_FAILURE:
    case C.MACHINE_BRAND_EDIT_FAILURE:
    case C.MACHINE_BRAND_NEW_FAILURE:
    case C.MACHINE_MODELS_LIST_FAILURE:
    case C.MACHINE_MODEL_EDIT_FAILURE:
    case C.MACHINE_MODEL_NEW_FAILURE:
    case C.MACHINE_BRAND_DATA_FAILURE:
    case C.MACHINE_MODEL_DATA_FAILURE:
    case C.MACHINE_REPLACE_FAILURE:
    case C.MACHINE_EDIT_SCHEDULE_FAILURE:
    case C.MACHINE_REMOVE_ALL_ARTICLES_FAILURE:
    case C.MACHINE_REPLACE_ARTICLES_FAILURE:
    case C.MACHINE_REMOVE_ROUTE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case C.MACHINE_CLIENT_FILTER_FAILURE:
      return {
        ...state,
        clientDetails: undefined,
        loading: false,
      };

    default:
      return state;
  }
};
