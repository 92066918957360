import cloneDeep from 'lodash/cloneDeep';

import Api from '../../../api';
import Utils from '../../../helpers/cleanEmptyData';
import useSaveFile from '../../../helpers/saveFile';

class RoutesRepository extends Api {
  constructor() {
    super();
    this.api.defaults.baseURL += 'routes';
  }

  createRoute = async (data) => this.api.post('/routes', data);

  createEmpty = async (data) => this.api.post('/filter', data);

  createTask = async (data) =>
    this.api.post(`/filter${this.cleanParams(data)}`);

  listRoutes = async (data) => this.api.get(`/filter${this.cleanParams(data)}`);

  getRoute = async ({ routeId }) => this.api.get(`/${routeId}`);

  getRouteEvents = async (data) =>
    this.api.get(`/filter${this.cleanParams(data)}`);

  startRoute = async (data) =>
    this.api.post(`/filter${this.cleanParams(data)}`);

  finishRoute = async (data) => this.api.post(`/${data}/finish`);

  filterTasks = async (data) =>
    this.api.post(`/filter${this.cleanParams(data)}`);

  getRoutesForToday = async (data) =>
    this.api.get(`/filter${this.cleanParams(data)}`);

  getAvailableVisitTypes = async () =>
    this.api.get('/schedule/availableVisitTypes');

  getTodayRoutes = async () => this.api.get('/today');

  scheduleCreate = async (data) => this.api.post('/schedule', data);

  scheduleDelete = async (data) => this.api.delete(`/schedule/${data.id}`);

  getSchedule = async (id) => this.api.get(`/schedule/${id}`);

  scheduleFilter = async ({
    number = undefined,
    pageLimit = 9999,
    pageNumber = 1,
    name = undefined,
  }) => {
    const params = {
      pageNumber,
      pageLimit,
      number,
      name,
    };
    return this.api.get(`/schedule/filter${this.cleanParams(params)}`);
  };

  scheduleAll = async () => {
    const pageLimit = 999;
    return this.api.get(`/schedule/filter${this.cleanParams({ pageLimit })}`);
  };

  scheduleEdit = async ({ scheduleId, data }) => {
    const cleanData = Utils.clean(cloneDeep(data));
    return this.api.put(`/schedule/${scheduleId}`, cleanData);
  };

  listTasks = (data) => this.api.get(`/tasks/filter${this.cleanParams(data)}`);

  getTask = async (id) => this.api.get(`/tasks/${id}`);

  selectableRoutes = async (payload) => this.api.get(`/selectable/${payload}`);

  getSchedulesTemplates = async () => this.api.get('/schedule/template/all');

  editSchedulesTemplates = async (payload) => {
    const deepPayload = cloneDeep(payload);
    const cleanData = Utils.clean(deepPayload);
    return this.api.put('/schedule/template/', cleanData);
  };

  createSchedulesTemplates = async (payload) =>
    this.api.post('/schedule/template', payload);

  removeSchedulesTemplates = async ({ id }) =>
    this.api.delete(`/schedule/template/${id}`);

  getSchedulePDF = async ({ date, schedules }) => {
    const schedulesCall = schedules.reduce((a, b) => {
      return `${a}&scheduleId=${b}`;
    });
    const pdfFile = await this.api.get(
      `/pdf?scheduleId=${schedulesCall}&day=${date}`,
      {
        responseType: 'blob',
      }
    );
    useSaveFile(pdfFile, date);
    return pdfFile;
  };
}
export default new RoutesRepository();
