import { lazy } from 'react';

const IncidentsLandingContainer = lazy(() =>
  import('../containers/IncidentsLandingContainer/IncidentsLandingContainer')
);
const IncidentsDetails = lazy(() =>
  import('../containers/IncidentsDetails/IncidentsDetails')
);
const IncidentsNewForm = lazy(() =>
  import('../containers/IncidentsNewForm/IncidentsNewForm')
);
const FaultsLanding = lazy(() =>
  import('../containers/FaultsLanding/FaultsLanding')
);
const FaultsNewForm = lazy(() =>
  import('../containers/FaultsNewForm/FaultsNewForm')
);
const FaultDetails = lazy(() =>
  import('../containers/FaultDetails/FaultDetails')
);
const SparePartsLanding = lazy(() =>
  import('../containers/SparePartsLanding/SparePartsLanding')
);
const SparePartsNewForm = lazy(() =>
  import('../containers/SparePartsNewForm/SparePartsNewForm')
);
const SparePartDetails = lazy(() =>
  import('../containers/SparePartDetails/SparePartDetails')
);

export default [
  {
    path: '/incidents',
    component: IncidentsLandingContainer,
  },
  {
    path: '/incidents/new',
    component: IncidentsNewForm,
  },
  {
    path: '/incidents/:incidentId',
    component: IncidentsDetails,
  },
  {
    path: '/faults',
    component: FaultsLanding,
  },
  {
    path: '/faults/new',
    component: FaultsNewForm,
  },
  {
    path: '/faults/:faultId',
    component: FaultDetails,
  },
  {
    path: '/spareParts',
    component: SparePartsLanding,
  },
  {
    path: '/spareParts/new',
    component: SparePartsNewForm,
  },
  {
    path: '/spareParts/:sparePartId',
    component: SparePartDetails,
  },
];
