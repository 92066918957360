import * as GlobalAction from '@redux/global/actions/global.action';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import LoginRepository from '../../../api/login';
import * as A from '../actions/auth.actions';
import * as C from '../constants/auth.constants';

function* loginEffect({ payload }) {
  try {
    if (window.location.origin.includes('localhost')) {
      const { data } = yield call(LoginRepository.login, payload);
      yield put(A.loginSuccess(data.token));
    } else {
      const [response] = yield all([
        call(LoginRepository.login, payload),
        call(LoginRepository.phpLogin, payload),
      ]);
      const { data } = response;
      yield put(A.loginSuccess(data.token));
    }
  } catch (e) {
    yield put(A.loginFailure(e.message));
    yield put(GlobalAction.createError('Usuari o contrasenya incorrecte'));
  }
}

export default function* authSagas() {
  yield takeLatest(C.LOGIN_REQUEST, loginEffect);
}
