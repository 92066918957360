import './styles/main.scss';
import './i18n';

import Snackbar from '@components/Snackbar/Snackbar';
import useJwt from '@hooks/useJwt';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ConnectedRouter } from 'connected-react-router';
import { Suspense, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Route, Router, Switch, useHistory, useLocation } from 'react-router';

import Footer from './components/footer';
import Loading from './components/loading';
import Menu from './components/menu';
import Nav from './components/nav';
import Login from './pages/login';
import * as AuthAction from './redux/auth/actions/auth.actions';
import * as GlobalAction from './redux/global/actions/global.action';
import * as NavbarAction from './redux/navbar/actions/navbar.actions';
import store, { history } from './redux/store';
import Routes from './routes';

const BASE_CLASS = 'vm-App';
ReactGA.initialize('UA-180978225-1');

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

const checkLocationChange = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const historyLocation = useHistory();
  const { message, redirect } = useSelector((state) => state.global);

  useEffect(() => {
    if (message !== '') {
      dispatch(GlobalAction.removeError());
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  useEffect(() => {
    if (redirect) {
      historyLocation.push(redirect);
      dispatch(GlobalAction.removeRedirect());
    }
  }, [redirect]);
};

const App = function App() {
  const [cookies] = useCookies();
  const location = useLocation();
  const { decodedToken, isExpired } = useJwt(cookies?.jwt);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(NavbarAction.isMobileRequest(isMobile));
  }, []);
  const { mobile } = useSelector((state) => state.navBar);
  const [menuOpen, setMenuOpen] = useState(!mobile);

  useEffect(() => {
    setMenuOpen(!mobile);
  }, [mobile]);

  const isLoginPage = window.location.pathname === '/login';

  const handleToggleMenu = () => {
    if (mobile) setMenuOpen(() => !menuOpen);
  };

  useEffect(() => {
    if (!isExpired && getCookie('jwt')) {
      // eslint-disable-next-line camelcase
      const { roles, username, user_id } = decodedToken;
      if (roles) {
        // eslint-disable-next-line camelcase
        dispatch(AuthAction.setUserData({ roles, username, user_id }));
      }
    } else if (!isLoginPage) {
      window.location.replace('/login');
    }
  }, [location]);

  checkLocationChange();

  return (
    <>
      <Helmet titleTemplate='%s | Vending Management'>
        <title>Vement</title>
      </Helmet>
      <Suspense fallback={null}>
        <Snackbar />
      </Suspense>
      <Suspense fallback={<Loading />}>
        {isLoginPage ? (
          <Login />
        ) : (
          <div className={BASE_CLASS}>
            <Nav handleToggle={handleToggleMenu} isOpen={menuOpen} />
            <div className='vm-MainContent'>
              <Menu isOpen={menuOpen} handleToggleMenu={handleToggleMenu} />
              <div className='vm-Container'>
                <div className='vm-Wrapper'>
                  <div className='vm-Wrapper-content'>
                    <Switch>
                      {Routes.map(({ path, component }) => (
                        <Route
                          key={path}
                          path={path}
                          exact
                          component={component}
                        />
                      ))}
                    </Switch>
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Suspense>
    </>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en'>
        <Router history={history}>
          <App />
        </Router>
      </LocalizationProvider>
    </ConnectedRouter>
  </Provider>
);
