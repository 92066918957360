/* eslint-disable class-methods-use-this */
import axios from 'axios';

class Login {
  login(data) {
    return axios.post(`${process.env.API_PHP}api/login_check`, data);
  }

  phpLogin(data) {
    const payload = {
      _username: data.username,
      _password: data.password,
      _submit: '',
      _scrf_token: '',
    };
    return axios.post(`${process.env.API_PHP}admin/login_check`, payload, {
      withCredentials: true,
    });
  }
}

export default new Login();
