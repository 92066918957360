import * as C from './modal.constants';

export const initialState = {
  tasksModal: [],
  incidentsModal: [],
  loadingModal: false,
  totalItems: 0,
  showModal: false,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case C.INCIDENTS_MODAL_REQUEST:
    case C.TASKS_MODAL_REQUEST:
      return {
        ...state,
        tasksModal: [],
        incidentsModal: [],
      };
    case C.TASKS_MODAL_SUCCESS:
      return {
        ...state,
        tasksModal: payload.results,
        totalItems: payload.totalItems,
      };
    case C.INCIDENTS_MODAL_FAILURE:
    case C.TASKS_MODAL_FAILURE:
      return {
        ...state,
      };
    case C.INCIDENTS_MODAL_SUCCESS:
      return {
        ...state,
        incidentsModal: payload.results,
        totalItems: payload.totalItems,
      };
    case C.TASKS_SHOW_MODAL:
      return {
        ...state,
        showModal: true,
        routeId: payload.routeId,
        machineId: payload.machineId,
        articleIds: payload.articleIds,
        clientId: payload.clientId,
      };
    case C.INCIDENTS_SHOW_MODAL:
      return {
        ...state,
        showModal: true,
        routeId: payload.routeId,
        clientId: payload.clientId,
        machineId: payload.machineId,
        faultId: payload.faultId,
        assignedTo: payload.assignedTo,
        origin: payload.origin,
        scheduleAssignedUserId: payload.scheduleAssignedUserId,
      };
    case C.CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
      };
    default:
      return state;
  }
};
